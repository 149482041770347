import { apiConfig } from "../config/apiConfig";
import { ScreenType } from "../location-search-map/models/ScreenType";
import { reportScreenSize } from "../location-search-map/reportScreenSize";
import { replaceLocalLogoLink } from "./replaceLocalLogoLink";
import { getBrandJSON } from "../util/locationBasedBrandJson";
import { apiCaching } from "../config/cachingConfig";
import { cachedAPIWrapper, getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { Footer } from "../footer/footer";
import { requestLogger } from "../api/apiTester";
import { FranchiseDetail } from "../location-search-map/models/FranchiseDetail";
import apiRequest from "../api/apiRequest";

interface Operation {
    dayOfWeek: number;
    startMinutes: number;
    endMinutes: number;
  }

export function shareOnFacebook(imageUrl:any, caption:any) {
    // Construct the Facebook sharing URL
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imageUrl)}&quote=${encodeURIComponent(caption)}`;

    // Open a new window or tab to share the URL on Facebook
    window.open(shareUrl, '_blank');
}

export function shareOnTwitter(text:any, url:any) {
    const tweetText = encodeURIComponent(text);
    const tweetURL = encodeURIComponent(url);
    const shareURL = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetURL}`;

    // Open the tweet URL in a new window
    window.open(shareURL, '_blank');
}


export function shareOnLinkedIn(imageURL:any) {
    // URL of the image you want to share


    // URL for sharing on LinkedIn
    const shareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(imageURL)}`;

    // Open a new window or tab to share the image on LinkedIn
    window.open(shareURL, '_blank');
}

export function shareOnPinterest(imageUrl: any, description:any) {
    // Base Pinterest share URL
    const pinterestShareUrl = 'https://www.pinterest.com/pin/create/button/';
  
    // Parameters
    const urlParams = new URLSearchParams();
    urlParams.append('url', imageUrl); // URL of the image to share
    urlParams.append('description', description);
  
    // Final Pinterest share URL
    const finalUrl = pinterestShareUrl + '?' + urlParams.toString();
  
    // Open the Pinterest share URL in a new window or tab
    window.open(finalUrl, '_blank');
  }
  export function insertAfterUrl(){
    let refresh:any
    if(window.location.pathname.substr(-1) != '/' ){
        refresh= window.location.protocol + "//" + window.location.host + window.location.pathname + '/#thank-you';
    }else {
        refresh= window.location.protocol + "//" + window.location.host + window.location.pathname + '#thank-you';
    }
        
    window.history.pushState({ path: refresh }, '', refresh);
}

export async function getFranchiseWebLocationIdDetails(zipCode:any): Promise<number | string> {
    try {

        const apiUrl = apiConfig.LocateLocationApiWithRoundRobinTrue
        .replace("sAddressParamValue", encodeURIComponent(zipCode))
        .replace(/([?&])RequireAddressOnPartialPostalCode=[^&]*(&|$)/, '$1');
       
        const request = {
            method: 'GET',
            url: apiUrl
        };

        const locateLocationRes: any[] = await apiRequest(request);

        if (Array.isArray(locateLocationRes) && locateLocationRes.length > 0) {
            const firstLocation = locateLocationRes[0];
            localStorage.setItem('doingBusinessAs', firstLocation.doingBusinessAs);
            localStorage.setItem('weblocationId', firstLocation.franchiseWebLocationId);
            return firstLocation.hasOwnProperty('franchiseWebLocationId') ? firstLocation.franchiseWebLocationId : "";
        }

        return "";
    } catch (error) {
        console.error('Error in fetching FranchiseLookUpByServiceAddress API data:', error);
        return "";
    }
}

export async function getBrandDetailsFromBrandJson(brandName:string):  Promise<any> {
    try {
        const brandJson = getBrandJSON();
        if(brandJson === 'Error validating Location'){
            console.error("Error Determining BrandJSON path for given location");
            return [];
        }
        else{
            const brandData = localStorage.getItem("brandDetails");
            if(brandData !== "undefined" && brandData !== null){
                let parsedBrandData = JSON.parse(brandData);
                if(parsedBrandData?.code === brandName){
                    if(parsedBrandData?.include_crownpeakURL || parsedBrandData?.include_statename){ 
                        await fetchCrownpeakUrlDetails();
                     } 
                    return parsedBrandData;
                }
            }
            
            const origin = window.location.origin;
            try {
                const url = `${origin}/${brandJson}`;
                const result = await getCachedAPIResponse(url, apiCaching.BrandJsonConfig, false);
                const brandDetails = result.find((item: any) => item.code === brandName);
                let crownpeakUrlDetails = null;
                if(brandDetails?.include_crownpeakURL || brandDetails?.include_statename){ 
                   await fetchCrownpeakUrlDetails();
                } 
                if (brandDetails) {
                  localStorage.setItem("brandDetails", JSON.stringify(brandDetails));
                }
            
                return brandDetails;
              } catch (error:any) {
                const errorMessage = `Error fetching brand details for ${brandName}: ${error.message}`;
                console.error(errorMessage);
                throw new Error(errorMessage);
              }
        }
        

    } catch (error) {
        console.error('Error in fetching getBrandDetailsFromBrandJson API data:', error);
        return [];
    }
}

export function getCountryCodefromAssetpath():string{
    let countryCode:any = document.getElementById('countryCode') as HTMLInputElement;
    countryCode = countryCode?.value.toLocaleLowerCase();
    let assetPath:any = document.getElementById('assetPath') as HTMLInputElement;
    assetPath = assetPath?.value.toLocaleLowerCase();
    const countryCodeFromAsset:string = assetPath?.includes('blogs') ? assetPath?.split('/')[4] : assetPath?.split('/')[5];
    if(countryCode === countryCodeFromAsset){
        return countryCode;
    }
    return countryCodeFromAsset;
}
export function getCampaignName(){
    let hrefUrl = window.location.href;
    let campaignName=''
        if (hrefUrl.indexOf('?') > -1)
        {
            let urlPath :any = new URL(hrefUrl);
            let urlAfterQueryStr = urlPath?.search?.replace('?', '');
            campaignName = urlAfterQueryStr;
            if(campaignName){
                sessionStorage.setItem('campaignName', campaignName);
            }
            else{
                campaignName = 'N/A';
            }

        } else
        {
            campaignName = 'N/A';
        }

        return campaignName;
    }

    export async function commonStates(countryCode:string):  Promise<any> {
        try {
            const stateCaUs = localStorage.getItem(countryCode);
            if(stateCaUs){
                return JSON.parse(stateCaUs);
            }
            const apiUrl = apiConfig.stateApi + countryCode;
            const result :any = await cachedAPIWrapper(apiUrl);
            localStorage.setItem(countryCode, JSON.stringify(result)); 
            return result;
    
        } catch (error) {
            console.error('Error in fetching state API data:', error);
            return [];
        }
    }

    export async function localSearch(){
        const dbaName = document.getElementById('full_dbaName') as HTMLInputElement;
        const country_code = getCountryCodefromAssetpath();
        const url = window.location.origin;   
        try{
            const apiUrl = `${url}/${country_code}/en-${country_code}/_assets/brand/${dbaName?.value}/js/data/dynamic_menu.json`;
            const cachedResponse = await getCachedAPIResponse(apiUrl, apiCaching.DynamicMenu, false);
            if (cachedResponse) {
                const data = cachedResponse.serviceMenu;
                const options = data["country"][0]?.brand[0]?.webLocation[0]?.option;
                const brandMenu = options;
                sessionStorage.setItem('localFilterService', JSON.stringify(brandMenu));
            }
        }
        catch(error){
            console.error('Error in fetching state API data:', error);
        }
    }
    
    export function getFooterLink(){
        try { 
        let data:any = [];
        let dynamicMenuURLResponse: any = {};
            dynamicMenuURLResponse = localStorage.getItem('dynamicMenuURLResponse');
            dynamicMenuURLResponse = JSON.parse(dynamicMenuURLResponse);
            const dynamicServicesMenu:any = dynamicMenuURLResponse?.serviceMenu?.country[0]?.brand[0]?.option;
            dynamicServicesMenu?.forEach((item: any) => {
                data.push({
                    name: item?.name,
                    link: window.location.origin + item?.link

                })
            })
            return data
        } catch(error){
            return [];
        }
    }

    export async function checkOSFlowPathAndReturnBoolean() {

        const conceptCodeElement = document.getElementById('conceptCode') as HTMLInputElement;
        
        if (conceptCodeElement) {

            const conceptCode = conceptCodeElement.value;
            const brandData = await getBrandDetailsFromBrandJson(conceptCode);
            const extractBrandOSFlowPath = brandData.request_button_link_type ? brandData.request_button_link_type.replace(/\//g, "") : "";
            const isApp = window.location.pathname.includes(extractBrandOSFlowPath);
    
            return isApp;
        }
    
        // Handle the case when conceptCodeElement is not found
        return false;
    }

    export async function updateHeaderContent(locationDoingBusinessAs: any) {
        const headerAddress = document.querySelector(".local-location .address") as HTMLElement;
        const headerDescription = document.querySelector(".local-location .description") as HTMLElement;
        const headerLogoLink = document.querySelector(".brand-header-local .brand-logo-wrapper") as HTMLElement;
    
        let trailingSlash = "";
        let hostMapWebLocation = '';

        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;

        if(conceptCode) {
            const brandData = await getBrandDetailsFromBrandJson(conceptCode);
            if(brandData?.includeTrailingSlash) {
                trailingSlash = "/";
            }

            try {
                let crownpeakUrlDetails = null;
                if(brandData?.include_crownpeakURL || brandData?.include_statename){ // If include_crownpeakURL is true then call Crownpeak URL to localization path
                    await fetchCrownpeakUrlDetails();
                }  
            } catch (error) {
                console.error('Error in fetching Crownpeak API data:', error);
            }
         
            if(brandData?.weblocation_path_group){
                hostMapWebLocation = brandData.weblocation_path_group;
            }
        
            let dba = localStorage.getItem('dbaName') ?? '';
            let dbaName = hostMapWebLocation ? `${hostMapWebLocation}/${dba}`: dba;
            if(brandData?.include_statename){
                const webLocation = window.crownpeakWeblocationsByIdDetail;
                const stateName = webLocation?.stateName;
                if(stateName){
                    dbaName = hostMapWebLocation ? `${hostMapWebLocation}/${stateName}/${dba}`: dba;
                }
            }
            
            updateHeaderElements(locationDoingBusinessAs, headerAddress, headerDescription, headerLogoLink, trailingSlash,dbaName,brandData);
            updateLocalLocationForDesktop();
            updateFooterBrandLogo();
            updateLocalLocationForDevice(trailingSlash,dbaName,brandData);
            
        }
    }
    
    function updateHeaderElements(
        locationDoingBusinessAs: string,
        headerAddress: HTMLElement,
        headerDescription: HTMLElement,
        headerLogoLink: HTMLElement,
        trailingSlash: string,
        dbaName:any,
        brandData: any
    ) {

        if(headerAddress) {
            headerAddress.innerText = locationDoingBusinessAs;
        }
    
        if(headerDescription && headerDescription.innerText === "") {
            headerDescription.innerText = "Locally Owned and Operated";
        }   

        const weblocationDetails = window.crownpeakWeblocationsByIdDetail ?? {};
    
        if(headerLogoLink && (brandData?.include_crownpeakURL && weblocationDetails?.weblocation_url) || (weblocationDetails?.weblocation_url && brandData?.include_statename)) {
            headerLogoLink.setAttribute("href", weblocationDetails?.weblocation_url);
        }else{
            headerLogoLink?.setAttribute("href", `${window.location.origin}/${dbaName}${trailingSlash}`);
        }
    }
    
    function updateLocalLocationForDesktop() {
        const firstBrandHeaderLocal = document.querySelector(".brand-header-local") as HTMLElement;
        if(firstBrandHeaderLocal) {
            firstBrandHeaderLocal.style.display = ""; // Removes the display attribute
        }
    
        const findLocalLink = document.querySelector(".brand-header-local .find-local") as HTMLElement;
        if(findLocalLink) {
            const textNode = findLocalLink.childNodes[1];
            if (textNode) {
                textNode.textContent = "Change Location";
            }
        }
    }
    
    function updateFooterBrandLogo() {
        const FooterBrandLogo = document.querySelector('.brand-footer .fb-des-link') as HTMLElement;
        if(FooterBrandLogo) {
            replaceLocalLogoLink(FooterBrandLogo);
        }
    }
    
    function updateLocalLocationForDevice(trailingSlash: string,dbaName:any,brandData:any) {
        const isDesktop = reportScreenSize() === ScreenType.Desktop;
        if(!isDesktop) {
            const headerMobileDeviceLogoLink = document.querySelector(".sidenav-brand-logo") as HTMLElement;
            if(headerMobileDeviceLogoLink) {
                headerMobileDeviceLogoLink.setAttribute("href", `${window.location.origin}/${dbaName}${trailingSlash}`);
            }
            const brandLogoMobileDevice = document.querySelector(".brand-logo-mobile") as HTMLElement;
            const weblocationDetails = window.crownpeakWeblocationsByIdDetail ?? {};

            if(brandLogoMobileDevice && brandData?.include_crownpeakURL && weblocationDetails?.weblocation_url) {
                brandLogoMobileDevice.setAttribute("href", weblocationDetails?.weblocation_url);
            }else{
                brandLogoMobileDevice?.setAttribute("href", `${window.location.origin}/${dbaName}${trailingSlash}`);
            }
        }
    }

    export function hmsProgressBarUpdate(progressWidth: string,processLength: number){
        const progressBarLine = document.querySelector(".progressbar #progress") as HTMLElement;
        const progresBarItems = document.querySelectorAll(".progressbar .progress-step");
        if(progresBarItems && progressBarLine){
            progressBarLine.style.width = progressWidth;
            progresBarItems.forEach((el, index)=>{
                el.classList.remove("prograssbar-length", "progress-step-confirmed");
                el.classList.remove("prograssbar-length", "progress-step-active");
            })
            
            progresBarItems.forEach((el, index)=>{
                if(index<processLength)
                    el.classList.add("prograssbar-length", "progress-step-confirmed");
                else if(index===(processLength))
                    el.classList.add("prograssbar-length", "progress-step-active");
            })
            
        }
    }

    export function returnToHomePage(){
        let indexUrl = window.location.href;
        const fullUrl = indexUrl.split("/").slice(0, 3).join("/");
        window.location.href = `${fullUrl}/`;
    }
    export function storeBookingApiPayload(payload:any,response:any){
        try{
        const formdata = JSON.stringify(payload);
        sessionStorage.setItem('rl-post-data',formdata);
        let apiData = '{ "Result": '+ JSON.stringify(response) +', "Form data": '+ formdata +', "Response message":"SUCCESS" }';
        sessionStorage.setItem('NEI-API data',apiData);
        } catch(error) {
            console.log("Error while storing the apipayload", error)
        }
    }

    export function removeAllEventListeners(selector: any) {
        const element = document.querySelector(selector);
        if (element) {
            const newElement = element.cloneNode(true);
            element.parentNode.replaceChild(newElement, element);
            return newElement;
        }
        return null;
    }

   export async function getWeblocationPathGroupWithSiteConfig() {
    const url = window.location.origin;
    const dbaName: string | null = localStorage.getItem('dbaName');
    const brandData =  localStorage?.brandDetails ? JSON.parse(localStorage.brandDetails) : null;
    const { weblocation_path_group: hostMapWebLocation,include_statename } = brandData || {};
    let pathSegments = ['config-files', '_site-config.json'];


    if (dbaName) {
        pathSegments.unshift(dbaName);

        if(include_statename){
            let webLocation = window.crownpeakWeblocationsByIdDetail;
            if(!webLocation){
                await fetchCrownpeakUrlDetails();
                webLocation = window.crownpeakWeblocationsByIdDetail;    
            }
            const stateName = webLocation?.stateName;
            if(stateName){
                pathSegments.unshift(stateName);
            }
        }
        if (hostMapWebLocation) {
            pathSegments.unshift(hostMapWebLocation);
        }
    }

    return `${url}/${pathSegments.join('/')}`;
}

export function getWeblocationPathGroupWithDynamicConfig() {
    const url = window.location.origin;
    const dbaName: string | null = localStorage.getItem('dbaName');
    const brandName = (document.getElementById('brandName') as HTMLInputElement)?.value;
    const country_code = getCountryCodefromAssetpath();

    let pathSegments = [];

    const localWebID = localStorage.getItem("franchiseWebLocationId");
    const webID = document.getElementById('weblocationId') as HTMLInputElement;
    const weblocationID = webID?.value ? webID?.value : localWebID as string;

    if (dbaName) {
        pathSegments.push('cesmenu', weblocationID, dbaName);
    } else {
        pathSegments.push(country_code, `en-${country_code}`, brandName, '_assets', 'js', 'data', 'dynamic_menu.json');
    }

    return `${url}/${pathSegments.join('/')}`;
}

export async function getWeblocationPathGroupWithFooterConfig() {
    const url = window.location.origin;
    const dbaName: string | null = localStorage.getItem('dbaName');
    const brandData = localStorage?.brandDetails ? JSON.parse(localStorage.brandDetails) : null; 
    const { weblocation_path_group: hostMapWebLocation,include_statename } = brandData || {};

    // Default URL setup
    let pathSegments = ['config-files', '_footer-config'];

    if (dbaName) {
        pathSegments.unshift(dbaName);
        if(include_statename){
            let webLocation = window.crownpeakWeblocationsByIdDetail;
            if(!webLocation){
                await fetchCrownpeakUrlDetails();
                webLocation = window.crownpeakWeblocationsByIdDetail;    
            }
            const stateName = webLocation?.stateName;
            if(stateName){
                pathSegments.unshift(stateName);
            }
        }
        if (hostMapWebLocation) {
            pathSegments.unshift(hostMapWebLocation);
        }
    } 

    return `${url}/${pathSegments.join('/')}`;
}

export async function getFranchiseWebLocationId(zipCode:any): Promise<number | string> {
    try {

        const apiUrl = apiConfig.LocateLocationApiWithRoundRobinTrue
        .replace("sAddressParamValue", encodeURIComponent(zipCode))
        .replace(/([?&])RequireAddressOnPartialPostalCode=[^&]*(&|$)/, '$1');
       
        const request = {
            method: 'GET',
            url: apiUrl
        };

        const locateLocationRes: any[] = await apiRequest(request);

        if (Array.isArray(locateLocationRes) && locateLocationRes.length > 0) {
            const firstLocation = locateLocationRes[0];
            return firstLocation
        }

        return "";
    } catch (error) {
        console.error('Error in fetching FranchiseLookUpByServiceAddress API data:', error);
        return "";
    }
}

   export function checkDomain() {
    const url = window.location.origin;
    //for local enviroments
    if(url.includes('localhost')){
        const assetPathElement = document.getElementById("assetPath")as HTMLInputElement;
        if(assetPathElement){
            const assetPathValue = assetPathElement.value;
            if(assetPathValue.includes("/US/"))
                return 'us';
            else if(assetPathValue.includes("/CA/"))
                return 'ca';
        }
        return "Error validating Location"
    }
    //Code for lower enviroments
    else if (url.includes('nblysbx') || url.includes('nblydev') || url.includes('nblytest') || url.includes('nblyprod')){
        try{
            if (url.includes('-ca-') || (url.includes('-ca') &&  url.includes('nblyprod'))){
                return 'ca';
            }
            else{
                return 'us';
            }
        }
        catch{
            return "Error validating Location"
        }
    }
    //Code for production enviroment
    else{
        try{
            const lastDotIndex = url.lastIndexOf('.'); 
            const domainExtension = url.substring(lastDotIndex + 1);
            if(domainExtension === "ca"){
                return 'ca';
            }
            else{
                return 'us';
            }
        }
        catch{
            return "Error validating Location"
        }
    }
    }

    export function capitalizeString(str:string) {
        if (!str) {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

      export function isOperationActiveNow(hoursofOperation: Operation[]): boolean {
        if (!hoursofOperation || hoursofOperation.length === 0) {
          return false;
        }
      
            const now = new Date();
            const currentDayOfWeek = now.getDay();
            const currentMinutes = now.getHours() * 60 + now.getMinutes();
      
        for (const operation of hoursofOperation) {
          if (operation.dayOfWeek == null || operation.startMinutes == null || operation.endMinutes == null) {
            continue;
          }
      
          const dayOfWeek = operation.dayOfWeek;
          const startMinutes = operation.startMinutes;
          const endMinutes = operation.endMinutes;
      
          if (dayOfWeek === currentDayOfWeek && currentMinutes >= startMinutes && currentMinutes <= endMinutes) {
            return true;
          }
        }
      
        return false;
      }

        export function removeElementEventListeners(element: any) {
        if (element) {
            const newElement = element.cloneNode(true);
            element.parentNode.replaceChild(newElement, element);
            return newElement;
        }
        return null;
    }

    export async function getVendorDetails(){
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode) as any;
        let vendorId='',vendorName='';
        if(brandData){
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }
        return {"conceptId": conceptId, "conceptCode": conceptCode, "vendorId": vendorId, "vendorName": vendorName};
    }

export function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: any;
    return function(this: any, ...args: any[]) {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

function updateInputValue(inputId:string, value:any) {
    try {
        const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;

        if (hiddenInput && value !== null && value !== undefined) {
            hiddenInput.value = value.toString();
        }
    } catch (error) {
        console.error(`Error in updateInputValue function: ${error}`);
    }
}

declare global {
    interface Window {
        localizationHelper?: any;
    }
}
window.localizationHelper = localizationHelper;

export function localizationHelper(result:any){
    const { franchiseWebLocationId, doingBusinessAs, franchiseId, phoneNumber,websiteTracking, licenseNumber } = result[0];
            
    const pNum = websiteTracking ? websiteTracking : phoneNumber;
    // Localization related code
    localStorage.setItem("franchiseWebLocationId", franchiseWebLocationId);
    sessionStorage.setItem('franchiseId', franchiseId);
    localStorage.setItem('doingBusinessAs', doingBusinessAs);
    localStorage.setItem('weblocationId', franchiseWebLocationId);
    localStorage.setItem('localPhoneNumber',pNum);
    sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
    sessionStorage.setItem("RL_DBAName", doingBusinessAs);

    const locationUrl = result[0].locationWebsiteUrl?.replace(/\/$/, '') ?? '';
    const splitUrlArr = locationUrl.toLowerCase().split("/");
    const pathname = splitUrlArr[splitUrlArr.length - 1];
    if (pathname) {
        localStorage.setItem('dbaName', pathname);
    }

    updateInputValue("local_weblocationId", franchiseWebLocationId);
    updateInputValue("weblocationId", franchiseWebLocationId);
    updateInputValue("full_dbaName", pathname);
    updateInputValue("dbaName", pathname);

    const address = document.querySelector('.header-wrapper .address')
    const desc = document.querySelector('.header-wrapper .description')
    const phone = document.querySelector('.header-wrapper .form-call-cta') as HTMLAnchorElement;
    const mobile = document.querySelector('.header-cta-mobile .mob-call-cta') as HTMLAnchorElement;

    if (pathname && doingBusinessAs) {
        updateHeaderContent(doingBusinessAs);
    }

    if (address && desc && phone) {
        address.innerHTML = doingBusinessAs
        desc.innerHTML = 'Locally Owned and Operated'
        phone.href = `tel:${pNum}`;
        phone.innerText = `(${pNum.slice(0, 3)}) ${pNum.slice(3, 6)}-${pNum.slice(6)}`;
    }

    if (mobile) {
        mobile.href = `tel:${pNum}`;
    }
    const ft_footer = new Footer();
}

declare global {
    interface Window {
        crownpeakWeblocationsDetails?: any;
        crownpeakWeblocationsByIdDetail?: any;
    }
}

export function closeReminderModal(){
    const closeModal = document.getElementById('close-reminder-local-modal');
    if (closeModal) {
        closeModal.click();
    }
}

//Code to handle Fake Submit (US:447017- Implement a Way to Test Full Booking Flow in Production)
export function handleFakeSubmit(request:any,isHmsFlow:boolean=false) {
    requestLogger(request.data, request.url);
    if(!isHmsFlow && sessionStorage.getItem("fakeBrowserCloseEvent")!=='true'){
        window.history.replaceState({}, '', '/confirmation');
        window.location.href = '/confirmation';
    }
}

export function logPayloadForFakeSubmit(){
    if(sessionStorage.getItem('mockRequestPayload')){
        console.log("Mock Request Payload : ",sessionStorage.getItem('mockRequestPayload'));
    }
}

export function appendCommaForText(TextData:string|null|undefined){
    if(TextData){
        return `${TextData}, `
    }
    return '';
}

export function weblocationDetailsById(){
    try {
        const franchiseWebLocationId = localStorage.getItem("franchiseWebLocationId") ?? localStorage.getItem('weblocationId') ;
        const weblocationDetails = window.crownpeakWeblocationsDetails ?? {};
        const weblocation = weblocationDetails.find((item: any) => item.weblocation_id == franchiseWebLocationId);
        return weblocation;

    } catch (error) {
        console.error('Error in fetching Crownpeak API data:', error);
        return {};
        
    }
}
  
  interface Franchise {
    franchiseDetails: FranchiseDetail[];
  }
  
  interface CrownpeakData {
    weblocation_id: string;
    weblocation_url: string;
    }  

export function updateFranchiseDataWithCrownpeakData(franchiseData: any[]): Franchise[] {
    // Create a map for fast lookup of crownpeak data by weblocation_id
    const crownpeakData:CrownpeakData[] = window.crownpeakWeblocationsDetails ?? [];
    console.log("Before updating-------->",crownpeakData)
    const crownpeakMap = new Map<string, string>();
    crownpeakData?.forEach(crown => {
        crownpeakMap.set(crown.weblocation_id, crown.weblocation_url);
    });
    console.log("After updating-------->",crownpeakData)

    // Iterate over franchise data
    franchiseData?.forEach(franchise => {
        // franchise?.forEach((detail: any) => {
            // Find matching weblocation_id from crownpeak map
            if(franchise.franchiseWebLocationId){
                const franchiseWebLocationIdStr = franchise.franchiseWebLocationId.toString();
                const matchedCrownpeakUrl = crownpeakMap.get(franchiseWebLocationIdStr);
                // If a match is found, update the weblocation_url
                if (matchedCrownpeakUrl) {
                    franchise.crownpeak_weblocation_url = matchedCrownpeakUrl;
                }
            }
        // });
    });
    console.log("After updating--------> Franchise",franchiseData)
    return franchiseData;
  }

  export async function fetchCrownpeakUrlDetails(): Promise<void> {
    const crownpeakURL = apiConfig.CROWNPEAK_WEBLOCATION_URL;
    const crownpeakUrlDetails:any = await getCachedAPIResponse(crownpeakURL, 60, false);
    window.crownpeakWeblocationsDetails = crownpeakUrlDetails;
    window.crownpeakWeblocationsByIdDetail = weblocationDetailsById();
}
export function modifyApplyLocalHref(htmlElement:string) {
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    let data: any = (document.getElementById('franchise-addr') as HTMLElement)?.textContent;
    if(!data)
        return;
    try {
        let jsonData = JSON.parse(data);
        const { address } = jsonData;
        const applyLocallyLink: any = (document.querySelector(htmlElement) as HTMLElement);
        const zipRegex = /[a-zA-Z]/;
        if (conceptCode.toLocaleUpperCase() === 'MRE' && applyLocallyLink) {
            if (zipRegex.test(address?.postalCode)) {
                applyLocallyLink.setAttribute("href", `https://canadajobs.mrelectric.com/jobs?page_size=10&page_number=1&location=3-${address?.postalCode}&radius=30&locationDescription=zipcode&locationName=${address.postalCode}&sort_by=distance&sort_order=ASC`)
            } else {
                applyLocallyLink.setAttribute("href", `https://jobs.mrelectric.com/jobs?page_size=10&page_number=1&location=3-${address?.postalCode}&radius=30&locationDescription=zipcode&locationName=${address.postalCode}&sort_by=distance&sort_order=ASC`)
            }
        }
    } catch (error) {
        console.log("Error while parsing json Data, " + error)
    }
}