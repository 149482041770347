import apiRequest from "../api/apiRequest";
import { cachedAPIWrapper, getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { contactUsPostCallWrapper } from "../apiCaching/POST-Requests/apiContactCallWrapper";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { ContactUsPayloadModel } from "../apiCaching/POST-Requests/interface/contact/contactInterface";
import { apiConfig } from "../config/apiConfig";
import { closeFunctionality } from "../util/createModal";
import { isTouchDevice } from "../util/device-detection";
import { handleMissingData } from "../util/discovery";
import { getSessionAndCorrelationID } from "../util/getSessionCorrelationId";
import { startLoader, stopLoader } from "../util/loader";
import { getBrandDetailsFromBrandJson, storeBookingApiPayload } from "../util/share";
import { requestEstimate } from "../util/requestEstimate";
import { isTestFcn } from "./booking-payload";
import { validEstimate } from "./confirmation";
import { throwBookingError } from "./molly-flow";
let moment = require('moment');

const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
export function configureNotesComments():boolean{
    let flag = false;
    return flag;
}

function priceNoteExists(){
    const priceNote = sessionStorage.getItem("PriceNote");
    if(priceNote && priceNote!== "" && priceNote!== "null" && priceNote!= undefined){
        return true;
    }
    return false;
}

function appendPriceNotes(bookingPayload:any){
    
    const otcEstimateTrue = !bookingPayload.jobFrequency.toLowerCase().includes("recurring") && validEstimate() && !priceNoteExists();
    if(bookingPayload.isLeadOnly && otcEstimateTrue){
        const estimateData = JSON.parse(sessionStorage.getItem("mollyEstimate") as string);
        const oneTimeClean = bookingPayload.jobFrequencyDetail.toLowerCase().includes("Not a Move In or Move Out Clean");
        let estimateText;
        if(oneTimeClean){
            estimateText = `$${Math.round(estimateData?.estimateOccasionalRangeLow)} - $${Math.round(estimateData?.estimateOccasionalRangeHigh)}`;
        }
        else{
            estimateText = `$${Math.round(estimateData?.estimateMoveRangeLow)} - $${Math.round(estimateData?.estimateMoveRangeHigh)}`;
        }
        sessionStorage.setItem("PriceNote",`Price: ${estimateText}`);

    }
}

export function payloadHelper(bookingPayload:BookingPayloadModel){
    bookingPayload.note = '';
    const outStr = sessionStorage.getItem("PriceNote");
    const outStr2 = sessionStorage.getItem("noZipNote");
    let noteText = '';
    if(outStr && !bookingPayload.jobFrequency?.toLowerCase().includes("recurring")){
        noteText = noteText.concat(outStr + ', ');
    }
    if(outStr2){
        noteText = noteText.concat(outStr2 + ', ');
    }
    noteText = noteText.concat(`Customer Type: ${bookingPayload.customerType}, MLY Frequency: ${bookingPayload.jobFrequency}`);
    if(bookingPayload?.jobFrequency?.toLowerCase().includes("one-time")){
        noteText = noteText.concat(`, ${bookingPayload.jobFrequencyDetail}`);
    }
    if(bookingPayload.estimateSquareFeet && bookingPayload.estimateBathrooms){
        if(bookingPayload.customerType?.toLowerCase().includes("residential")){
            noteText = noteText.concat(`, Square Feet: {${bookingPayload.estimateSquareFeet}}, Bedrooms: {${bookingPayload.estimateBedrooms}} , Bathrooms: {${bookingPayload.estimateBathrooms}}`); 
        }
        else{
            noteText = noteText.concat(`, Square Feet: {${bookingPayload.estimateSquareFeet}}, Offices: {${bookingPayload.estimateBedrooms}} , Bathrooms: {${bookingPayload.estimateBathrooms}}`);
        }
    }
    const preference = sessionStorage.getItem('PreferredCommunicationText') as string;
    if(preference){
        if(preference?.toLowerCase().includes("text")){
            noteText = noteText.concat(`, Text OptIn: Yes`);
        }
    }
    if(!bookingPayload.note.includes(noteText)){
        bookingPayload.note = bookingPayload.note.concat(noteText);
    }
    if(configureNotesComments()){
        bookingPayload.comments = bookingPayload.comments?.concat(noteText);
    }
    
    return bookingPayload;
}

export async function callBookingApi(bookingPayload: BookingPayloadModel, browerCloseCheck?: boolean){
    startLoader();
    appendPriceNotes(bookingPayload);
    bookingPayload = payloadHelper(bookingPayload);
    
    let sessionID: string | undefined;
    let correlationID: string | undefined;
    const sessionAndCorelation = getSessionAndCorrelationID();
    sessionID = sessionAndCorelation.sessionID;
    correlationID = sessionAndCorelation.correlationID;
    if(correlationID)
        bookingPayload.correlationId = correlationID;

    const out = await handleMissingData(bookingPayload.city, bookingPayload.state, bookingPayload.zipCode);
    bookingPayload.city = out?.city || bookingPayload.city;
    bookingPayload.state = out?.state || bookingPayload.state;

    let url = '';
    if(correlationID && sessionID){
        url = `${apiConfig.BOOKING_API_URL}&correlationId=${correlationID}&sessionId=${sessionID}`
    }
    else{
        url = apiConfig.BOOKING_API_URL;
    }
    
    if(browerCloseCheck){
        bookingPayload.note = bookingPayload?.note?.concat(" ID: 5ubm17 ");  // Append note for incomplete flow leads
    }

    const callFromCalendar = sessionStorage.getItem("callFromCalendar");
    if(callFromCalendar==="true"){
        // happy flow
        const isBookingDone = sessionStorage.getItem("isBookingDone");
        if(isBookingDone && isBookingDone==="true"){
            return;
        }
    }

    const submitType = bookingPayload.isLeadOnly ? 'submit_and_continue' : 'full_form_submit';
    let formDesc: any = '';

    const referralMethod = document.querySelector('#how-about-us') as HTMLInputElement | null;
    formDesc = referralMethod ? referralMethod.value.replace(/ /g, "_") : '';

    if (conceptCode == 'MLY') {
        const mlyTypeOfCleaningRadioElement = document.querySelector('input[name="mly-cleaning-radio"]:checked') as HTMLInputElement | null;
        const selectedTypeOfCleaning = mlyTypeOfCleaningRadioElement?.value;
        const frequencyCleaningRadioElement = document.querySelector('input[name="frequency-cleaning-radio"]:checked') as HTMLInputElement | null;
        const frequencyRadios = frequencyCleaningRadioElement?.value;
        formDesc = selectedTypeOfCleaning + '_' + frequencyRadios;
    }

    const serviceType = document.querySelector('input[name="service-type"]:checked');
    if (serviceType) {
        formDesc = serviceType.nextElementSibling?.textContent;
    }


    await bookingUsPostCallWrapper(url, bookingPayload)
    .then((response: any) => {
        requestEstimate(submitType, 'long-form', 'success', formDesc);
            if(response){
                if(callFromCalendar==="true"){
                    sessionStorage.setItem("isBookingDone","true");
                }
                storeBookingApiPayload(bookingPayload, response);
                window.history.replaceState({}, "" , window.location.href);
                if(browerCloseCheck) {
                    sessionStorage.setItem('IsLeadOnly','false');
                    sessionStorage.setItem("bookingLeadSent", "true");
                    stopLoader();
                    if(isTouchDevice()){
                        closeFunctionality();
                    }
                } 
                if(!browerCloseCheck) {
                    document.querySelectorAll("form").forEach((f)=>{
                        f.reset();
                    });
                    window.location.pathname = '/confirmation';
                }
            }
            else{
                const flag = isTouchDevice() && browerCloseCheck ? closeFunctionality() : throwBookingError();
            }
            
        })
        .catch((error:any) => {
            requestEstimate(submitType, 'long-form', 'fail', formDesc);
            const flag = isTouchDevice() && browerCloseCheck ? closeFunctionality() : throwBookingError();
        })
}

export async function getFranchiseDetails(addressParam:string) : Promise<any> {
    const url:any = apiConfig.LocateLocationApiWithRoundRobinFalse.replace('sAddressParamValue', encodeURIComponent(addressParam));
    const result:any = getCachedAPIResponse(url);
    return result;
}

export function determineFlowType(WebLocationId:number) : any {
    // Function to determine whether OS flow or Lead flow
    const url = `${apiConfig.GET_ATTRIBUTE_DATA}/${WebLocationId}`;
    return cachedAPIWrapper(url);
}

export async function checkTechAvailability(arg:string) : Promise<any> {
    const weblocationId = localStorage.getItem("franchiseWebLocationId");
    const zipValue = sessionStorage.getItem('zipcode');
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    const maxWeeks = brandData?.calendarMaxWeeksCap ? brandData?.calendarMaxWeeksCap: 8;
    const vendorId = brandData?.vendorId;
    let estFlag;
    if(arg.toLowerCase().includes('recurring')){
        estFlag = true;
    }else{
        estFlag = false;
    }
    const dynamic_url = `${apiConfig.AppointMentAPi}?PostalCode=${zipValue}&WebLocationId=${weblocationId}&NumberOfDays=${7*maxWeeks}&DateStart=${moment().format('MM/DD/YYYY')}&IsEstimate=${estFlag}&apikey=${process.env.JS_API_KEY}&VendorId=${vendorId}`;
    return getCachedAPIResponse(dynamic_url);
    
}

export async function configureMollyEstimate() : Promise<boolean>{
// This method will call an API to confirm whether the estimate needs to be shown in the calendar screen or not.
    let locationID = localStorage.getItem("franchiseWebLocationId") ? localStorage.getItem("franchiseWebLocationId") : localStorage.getItem('weblocationId');
    const val = await determineFlowType(Number(locationID));
    const returnVal = val?.options?.mollyOneTimeCleansDisableEstimatePricing;
    return !returnVal;
}

export async function confirmMlyLeadSourceAPI() : Promise<string>{
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    let endpoint:string = apiConfig.GenericLeadSourceEndpoint;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    if(brandData){
        if(brandData?.send_brand_leadsource_api){
            endpoint = apiConfig.MollyLeadSourceEndpoint;
        }
        else{
            endpoint = apiConfig.GenericLeadSourceEndpoint;
        }
    }
    return endpoint;
    
}

export async function setDefaultLeadSource(): Promise<any> {
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    const obj = {
        "LeadSourceID": brandData?.default_leadSourceID,
        "LeadSource": brandData?.default_leadSource
    }
    return obj;
}

export async function calcEstimate(bookingPayload:any){
    startLoader();
    sessionStorage.removeItem("noCalendarPricing");
    let payload ={
        "franchiseWebLocationId": bookingPayload.webLocationId,
        "LeadSourceId": bookingPayload.leadSourceId,
        "SecondaryLeadSourceId": 0,
        "Note": "",
        "EstimateTitle": "",
        "FirstName": bookingPayload.firstName,
        "LastName": bookingPayload.lastName,
        "Address": bookingPayload.address + bookingPayload.address2,
        "City": bookingPayload.city,
        "State": bookingPayload.state,
        "PostalCode": bookingPayload.postalCode.trim(),
        "Email": bookingPayload.email,
        "Phone": bookingPayload.phone,
        "PreferredCommunicationType": bookingPayload.preferredCommunicationType,
        "EstimateSquareFeet": Number(bookingPayload.estimateSquareFeet),
        "EstimateBedrooms": Number(bookingPayload.estimateBedrooms),
        "EstimateBathrooms": Number(bookingPayload.estimateBathrooms),
        "ReceiveEmailUpdates": true,
        "conceptId": conceptId,
        "vendorId": Number(bookingPayload.vendorId)
    };
    const request = {
        method: 'POST',
        url: apiConfig.calculateEstimate,
        data: payload
    };

   return apiRequest(request)
        .then((response: any) => {
            stopLoader();
            if(response){
                sessionStorage.setItem("mollyEstimate",JSON.stringify(response));
            }
            else{
                sessionStorage.setItem("noCalendarPricing","true");
            }
            
        })
        .catch((error:any) => {
            stopLoader();
            sessionStorage.setItem("noCalendarPricing","true");
        })
    
}

export function calcEstFail(){
    const varFlag = sessionStorage.getItem("noCalendarPricing");
    if(varFlag && varFlag=="true"){
        return true;
    }
    return false;
}

export async function contactUsEndpoint(bookingPayload:any){
    startLoader();
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    let noZipText= brandData.osflow_contactUs_comments;
    const requestBody: ContactUsPayloadModel = {
        "firstName": bookingPayload.firstName,
        "lastName": bookingPayload.lastName ?? '',
        "zipCode": bookingPayload.zipCode,
        "phone": bookingPayload.phone,
        "email": bookingPayload.email,
        "city": bookingPayload.city, 
        "state": bookingPayload.state,
        "country": bookingPayload.country ?? '',
        "address": bookingPayload.address ?? '',
        "address2": bookingPayload.address2 ?? '',
        "comments": noZipText ?? "",
        "signUpForUpdates": true,
        "isLocalized": false,
        "isNewCustomer": "", 
        "isTest": isTestFcn(),
        "conceptId": Number(conceptId),
        "conceptCode": conceptCode,
        "vendorId": Number(brandData.vendorId)
    };
   
    return contactUsPostCallWrapper(apiConfig.CONTACT_US_SEND, requestBody)
        .then((response)=>{
            stopLoader();
            if(response){
                storeBookingApiPayload(requestBody,response);
                window.history.replaceState({}, "" , window.location.href);
                document.querySelectorAll("form").forEach((f)=>{
                    f.reset();
                });
                window.location.pathname = '/confirmation';
            }
            else{
                throwBookingError();
            }
        })
        .catch((err)=>{
            stopLoader();
            throwBookingError();
        });
}