import { FranchiseDetail } from "./models/FranchiseDetail";
import { locationSearchMapConfig, debugConfig } from './config';
import { cachedAPIWrapper } from "../apiCaching/apiWrapper";

export async function getFranchiseWebLocations(): Promise<void | FranchiseDetail[]>{        
        return getDataFromApi();
}

const getDataFromApi =  () => {
    return cachedAPIWrapper(locationSearchMapConfig.franchiseWebLocationsApiCall)
        .then(response => {
            return response;
        })
        .then(response =>
        {
            return filterData(response);
        })
        .catch(error =>
        {
            //console.log("Error calling getFranchiseWebLocations:", error);
        });
}

const filterData = (data: any): FranchiseDetail[] => {
    let details = data.flatMap((f: { franchiseDetails: any; })=>f.franchiseDetails);
    // details = details.filter((c: { country: string; })=>c.country ==='CAN' || c.country ==='Canada');
    let result = [];  
    // remove duplicates
    const set = new Set();
    for(let detail of details) {
        if(!set.has(detail.franchiseWebLocationId)) {
            result.push(detail);
            set.add(detail.franchiseWebLocationId);
        }
    }
    /* Bug--337206 added a filter condtion to handle the entries which is been added in franchiseweblocation Api"*/
    result = result?.filter(location => 
     !(location?.doingBusinessAs && location?.doingBusinessAs?.toLocaleLowerCase().includes("Test Account".toLocaleLowerCase())))
    return result;
}
