import { REGEX } from "../constants/regex";
import { DomainIdentifier } from "./domainService";
import { LocalizationIdentifier } from "./localizationService";
import { payloadManipulator } from "./payloadManipulator";

export function DisclaimerServiceIdentifier(zipCode?: any): void {
    const domainDetail = DomainIdentifier();
    const localizationInfo = LocalizationIdentifier();

    let countryZip:any = null;

    if (zipCode === null || zipCode === undefined) {
        countryZip = null;
    } else if (!REGEX.sendCaPostalCode.test(zipCode.trim())) {
        countryZip = 'us';
    } else {
        countryZip = 'ca';
    }
    const disclaimerDomList = document.querySelectorAll('.service-reminder-main');
    const disclaimerList = Array.from(disclaimerDomList) as HTMLElement[];
   // commented for timebeing as we are chekcing it inside domain if(localizationInfo){
        disclaimerList?.forEach((disclaimer) => {
            disclaimer.classList.add("hidden");
        });
   // end }    
    if (disclaimerList.length === 0) return;
    disclaimerList.forEach((disclaimer) => {
        const disclaimerUs = disclaimer.querySelector("[id*='service-reminder-us']");
        const disclaimerCa = disclaimer.querySelector("[id*='service-reminder-ca']");
        handleDisclaimerVisibility(disclaimer, domainDetail, localizationInfo, countryZip, disclaimerUs, disclaimerCa);
    }); 
}

function handleDisclaimerVisibility(disclaimer: HTMLElement,
domainDetail: string,
localizationInfo: string,
countryZip: string | null,
disclaimerUs: Element | null,
disclaimerCa: Element | null
): void {
    const showDisclaimer = (disclaimerElement: Element | null) => {
        if (disclaimerElement) {
            disclaimer.classList.remove('hidden');
        }
    };
    if (domainDetail === 'us') {
        if (localizationInfo === 'ca' && countryZip === null) {
            showDisclaimer(disclaimerCa);
            return;
        }
        else if (countryZip === 'us' || !countryZip) {
            showDisclaimer(disclaimerUs);  // Show US disclaimer for US domain
            return;
        }
        else if (countryZip === 'ca' && disclaimerCa) {
            disclaimer.classList.remove('hidden'); // Show CA disclaimer
            return;
        }
    }
    // Logic for when the domain is CA
    else if (domainDetail === 'ca') {
        // Show CA disclaimer if countryZip is CA or not defined
        if (countryZip === 'ca' || !countryZip) {
            showDisclaimer(disclaimerCa);
            return;
        }
        // Show US disclaimer if countryZip is US
        else if (countryZip === 'us' && disclaimerUs) {
            disclaimer.classList.remove('hidden');
            return;
        }
    }
}

export function disclaimerHandleCheck(showModal:boolean,formElement:HTMLElement,formData:any,zipCode:any, checkboxValue?: string): void {
    const visibleServiceReminderDivs = checkboxValue ? formElement.querySelector(`#${checkboxValue}`) as HTMLInputElement : null;
    const serviceReminderModal = document.querySelector('.service-reminder-ca-modal') as HTMLElement;
    const domain = DomainIdentifier();
    const localization = LocalizationIdentifier();
    if(showModal && serviceReminderModal && !localization){
        serviceReminderModal.click();
    } 
    const countryZip = !REGEX.sendCaPostalCode.test(zipCode?.trim()) ? 'us' : 'ca';
    const formElementSelector = formElement;
    const serviceCheckElement = formElementSelector.querySelector(`#${visibleServiceReminderDivs?.id}`);
    let serviceCheck = serviceCheckElement instanceof HTMLInputElement ? serviceCheckElement.checked : false;
    const manipulatedPayload = payloadManipulator(formData,domain,localization,serviceCheck,countryZip);
    return manipulatedPayload;
}