import { getCacheSettings } from './opus2/util/purgeCache';

const displayCacheLog = process.env.SHOW_CACHE_LOG?.toLowerCase() === 'true';
const enableServiceWorker = process.env.ENABLE_SERVICE_CACHE?.toLowerCase() === 'true';
const enablePurgeCache = localStorage.getItem("cachingL2");
if(!enablePurgeCache){
    getCacheSettings();
}
else{
    if (enableServiceWorker && 'serviceWorker' in navigator && enablePurgeCache === 'true') {
        navigator.serviceWorker.getRegistration('/').then(registration => {
            if (registration) {
              if (displayCacheLog) {
                console.log('%c[SW]%c already registered:','background:yellow; color:#000; font-weight: bold; padding: 0 8px', '', registration.scope);
              }
            } else {
              navigator.serviceWorker.register('/brand/_assets/js/sw1.js', { scope: '/' })
                .then(registration => {
                  if (displayCacheLog) {
                    console.log('%c[SW]%c registered with scope:','background:green; color:#f3f3f3; font-weight: bold; padding: 0 8px', '', registration.scope);
                  }
                })
                .catch(error => {
                  if (displayCacheLog) {
                    console.error('%c[SW]%c registration failed:','background:red; color:#f3f3f3; font-weight: bold; padding: 0 8px', '', error);
                  }
                });
            }
          });
        getCacheSettings();
    }
}

//Build checker
const enviromentConsole = process.env.mod?.toLowerCase() === 'development';
if(enviromentConsole) console.log('%cNon - Prod Build has been loaded', 'background: #f3f3f3; color: red; font-size: 32px; padding: 8px 16px; margin: 8px 0; font-weight: bold;');

export * from './opus2';
export * from './global';
