/**
 * Returns a default image replacement
 * @param {string} [name='Unknown'] - Customer name
 * @returns {string} - HTML string for the image replacement
 */
export function getImageReplacemnt(name: string = 'Unknown'): string {
    // Check if name is null or undefined
    if (typeof name === 'undefined' || name === null) {
        name = 'Unknown';
    }

    const solidColors = [
        '#16a085', 
        '#f1c40f', 
        '#f39c12', 
        '#27ae60', 
        '#d35400', 
        '#2980b9', 
        '#c0392b', 
        '#8e44ad', 
        '#2c3e50'
    ];

    // Check if solidColors is null or undefined
    if (typeof solidColors === 'undefined' || solidColors === null) {
        throw new Error('solidColors is null or undefined');
    }

    const randomColor = solidColors[Math.floor(Math.random() * solidColors.length)];

    // Check if randomColor is null or undefined
    if (typeof randomColor === 'undefined' || randomColor === null) {
        throw new Error('randomColor is null or undefined');
    }

    const fName = name.split(' ')[0].charAt(0).toUpperCase();


    const style = `
    background: ${randomColor};
    display: grid;
    place-content: center;
    color: #ecf0f1;
    font-size: 2.2rem;
    `;

    return `<div class="testi-profile" style="${style}">${fName}</div>`
}
