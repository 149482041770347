import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { s3upload } from "../util/fileUploads3";
import { startLoader, stopLoader } from "../util/loader"
import { getFranchiseWebLocationIdDetails, commonStates, storeBookingApiPayload, getBrandDetailsFromBrandJson } from "../util/share";
import { getBrandJSON } from "../util/locationBasedBrandJson";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { DomainIdentifier } from "../util/domainService";
import { LocalizationIdentifier } from "../util/localizationService";
import { disclaimerHandleCheck, DisclaimerServiceIdentifier } from "../util/disclaimerService";
import { contactUsPostCallWrapper } from "../apiCaching/POST-Requests/apiContactCallWrapper";
import { ContactUsPayloadModel } from "../apiCaching/POST-Requests/interface/contact/contactInterface";
import { requestEstimate } from "../util/requestEstimate";

const FilesUrl = [null, null, null]
export class FormHandler {
    private form: HTMLFormElement | null = null;
    private mobileNumberInput: HTMLInputElement | null = null;
    private countryCode: any;
    private franchiseWebLocationId: any = '';
    private isLocalized: boolean = false;
    private readonly shortFormService: NodeListOf<HTMLElement>;
    private noteWithRequestBody: any;

    constructor() {
        this.init();
        this.clearFields()
        this.shortFormService = document.querySelectorAll('[id*="short-form-service-reminder"]');
        const radioElement = document.getElementById('inline-2-radio-6') as HTMLInputElement;
        if (radioElement)
            radioElement.checked = true;

        // const customerRadio = document.getElementById('inline-radio-1') as HTMLInputElement;
        const contPhoneMethod = document.getElementById('inline-radio-3') as HTMLInputElement;

        if (contPhoneMethod)
            contPhoneMethod.checked = false;

        const receiveUpdateRadioEle = document.getElementById('inline-2-radio-8') as HTMLInputElement;
        if (receiveUpdateRadioEle) {
            receiveUpdateRadioEle.checked = true;
        }

    }

    clearFields = () => {
        // Get references to the input elements
        this.handleFocusOut = this.handleFocusOut.bind(this);
        const firstNameInput = document.getElementById('send-name') as HTMLInputElement;
        const lastNameInput = document.getElementById('send-lname') as HTMLInputElement;
        const zipCodeInput = document.getElementById('send-zip') as HTMLInputElement;
        const phoneNumberInput = document.getElementById('send-phone') as HTMLInputElement;
        const emailInput = document.getElementById('send-email') as HTMLInputElement;
        const address1Input = document.getElementById('send-address1') as HTMLInputElement;
        const address2Input = document.getElementById('send-address2') as HTMLInputElement;
        const cityInput = document.getElementById('send-city') as HTMLInputElement;
        const stateSelect = document.getElementById('send-state') as HTMLSelectElement;
        const noteTextarea = document.getElementById('send-note') as HTMLTextAreaElement;

        const customerRadio = document.getElementById('inline-radio-1') as HTMLInputElement;
        const customerRadio2 = document.getElementById('inline-radio-2') as HTMLInputElement;

        const contPhoneMethod = document.getElementById('inline-radio-3') as HTMLInputElement;
        const contEmailMethod = document.getElementById('inline-radio-4') as HTMLInputElement;
        const contTextMethod = document.getElementById('inline-radio-5') as HTMLInputElement;
        const signupRadioButton = document.getElementById('inline-2-radio-3') as HTMLInputElement;
        const noThanksRadioButton = document.getElementById('inline-2-radio-4') as HTMLInputElement;

        // Add event listeners to the input elements
        firstNameInput?.addEventListener('input', () => {

            if (firstNameInput.value !== '' && !REGEX.sendName.test(firstNameInput.value)) {
                const fieldError = document.getElementById(`${firstNameInput?.id}-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden');
                    fieldError.innerHTML = 'Enter a valid first name.';
                } else {
                    this.createErrorElement(firstNameInput, 'Enter a valid first name.');
                }
            } else {
                this.hideError(`${firstNameInput?.id}`);
            }

        });
        lastNameInput?.addEventListener('input', () => {

            if (lastNameInput.value !== '' && !REGEX.sendName.test(lastNameInput.value)) {

                const fieldError: any = document.getElementById(`send-lname-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden')
                    fieldError.innerHTML = 'Invalid last name format.'

                }
                else {
                    const field: any = lastNameInput;
                    if (field && field.parentNode) {
                        const errorMessage = 'Invalid name format.';
                        const errorElement = document.createElement('span');
                        errorElement.className = 'error-msg';
                        errorElement.id = `${field.id}-error-msg`;
                        errorElement.textContent = errorMessage;
                        field.classList.add("invalid-field");
                        field.parentNode.appendChild(errorElement);
                    }
                }
            }
            else {
                this.hideError('send-lname')
            }


        });
        zipCodeInput?.addEventListener('input', () => {

            if (zipCodeInput.value !== '' && !REGEX.sendName.test(zipCodeInput.value)) {
                const fieldError = document.getElementById(`${zipCodeInput?.id}-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden');
                    fieldError.innerHTML = 'Enter a valid zip code.';
                } else {
                    this.createErrorElement(zipCodeInput, 'Enter a valid zip code.');
                }
            } else {
                this.hideError(`${zipCodeInput?.id}`);
            }

        });
        phoneNumberInput?.addEventListener('input', () => {

            if (phoneNumberInput.value !== '' && !REGEX.sendName.test(phoneNumberInput.value)) {
                const fieldError = document.getElementById(`${phoneNumberInput?.id}-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden');
                    fieldError.innerHTML = 'Enter a valid phone number.';
                } else {
                    this.createErrorElement(phoneNumberInput, 'Enter a valid phone number.');
                }
            } else {
                this.hideError(`${phoneNumberInput?.id}`);
            }

        });
        emailInput?.addEventListener('input', () => {

            if (emailInput.value !== '' && !REGEX.sendName.test(emailInput.value)) {
                const fieldError = document.getElementById(`${emailInput?.id}-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden');
                    fieldError.innerHTML = 'Enter a valid email address.';
                } else {
                    this.createErrorElement(emailInput, 'Enter a valid email address.');
                }
            } else {
                this.hideError(`${emailInput?.id}`);
            }

        });
        address1Input?.addEventListener('input', () => {

            if (address1Input.value !== '' && !REGEX.sendAddress1.test(address1Input.value)) {

                const fieldError: any = document.getElementById(`${address1Input?.id}-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden')
                    fieldError.innerHTML = 'Invalid address format.'

                }
                else {
                    const field: any = address1Input;
                    if (field && field.parentNode) {
                        const errorMessage = 'Invalid name format.';
                        const errorElement = document.createElement('span');
                        errorElement.className = 'error-msg';
                        errorElement.id = `${field.id}-error-msg`;
                        errorElement.textContent = errorMessage;
                        field.classList.add("invalid-field");
                        field.parentNode.appendChild(errorElement);
                    }
                }
            }
            else {
                this.hideError(address1Input?.id)
            }

        });
        address2Input?.addEventListener('input', () => {
            if (address2Input.value !== '' && !REGEX.sendAddress1.test(address2Input.value)) {

                const fieldError: any = document.getElementById(`${address2Input?.id}-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden')
                    fieldError.innerHTML = 'Invalid address format.'

                }
                else {
                    const field: any = address2Input;
                    if (field && field.parentNode) {
                        const errorMessage = 'Invalid name format.';
                        const errorElement = document.createElement('span');
                        errorElement.className = 'error-msg';
                        errorElement.id = `${field.id}-error-msg`;
                        errorElement.textContent = errorMessage;
                        field.classList.add("invalid-field");
                        field.parentNode.appendChild(errorElement);
                    }
                }
            }
            else {
                this.hideError(address2Input?.id)
            }

        });
        cityInput?.addEventListener('input', () => {

            if (cityInput.value !== '' && !REGEX.sendName.test(cityInput.value)) {

                const fieldError: any = document.getElementById(`${cityInput?.id}-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden')
                    fieldError.innerHTML = 'Invalid city format.'

                }
                else {
                    const field: any = cityInput
                    if (field && field.parentNode) {
                        const errorMessage = 'Invalid city format.';
                        const errorElement = document.createElement('span');
                        errorElement.className = 'error-msg';
                        errorElement.id = `${field.id}-error-msg`;
                        errorElement.textContent = errorMessage;
                        field.classList.add("invalid-field");
                        field.parentNode.appendChild(errorElement);
                    }
                }
            }
            else {
                this.hideError(cityInput?.id)
            }

        });
        stateSelect?.addEventListener('change', () => {

            if (stateSelect.value !== '' && !REGEX.sendName.test(stateSelect.value)) {
                const fieldError = document.getElementById(`${stateSelect?.id}-error-msg`);
                if (fieldError) {
                    fieldError.classList.remove('hidden');
                    fieldError.innerHTML = 'Enter a valid state.';
                } else {
                    this.createErrorElement(stateSelect, 'Enter a valid state.');
                }
            } else {
                this.hideError(`${stateSelect?.id}`);
            }

        });
        noteTextarea?.addEventListener('input', () => {

            noteTextarea.classList.remove('invalid-field')
            const errmsg = document.getElementById(`${noteTextarea?.id}-error-msg`)

            if (errmsg)
                errmsg?.classList.add('hidden')

        });

        customerRadio?.addEventListener('change', () => {
            customerRadio.classList.remove('invalid-field')
            const errmsg = document.getElementById(`${customerRadio?.id}-error-msg`)

            if (errmsg)
                errmsg?.remove()

            customerRadio2.classList.remove('invalid-field')
            const errmsg3 = document.getElementById(`${customerRadio2?.id}-error-msg`)

            if (errmsg3)
                errmsg3?.remove()

        });
        customerRadio2?.addEventListener('change', () => {
            customerRadio2.classList.remove('invalid-field')
            const errmsg = document.getElementById(`${customerRadio2?.id}-error-msg`)

            if (errmsg)
                errmsg?.remove()

            customerRadio.classList.remove('invalid-field')
            const errmsg3 = document.getElementById(`${customerRadio?.id}-error-msg`)

            if (errmsg3)
                errmsg3?.remove()
        });
        contEmailMethod?.addEventListener('change', () => {
            this.clearInvalidField(contEmailMethod, [contPhoneMethod, contTextMethod]);
        });
        
        contPhoneMethod?.addEventListener('change', () => {
            this.clearInvalidField(contPhoneMethod, [contEmailMethod, contTextMethod]);
        });
        
        contTextMethod?.addEventListener('change', () => {
            this.clearInvalidField(contTextMethod, [contEmailMethod, contPhoneMethod]);
        });
        
        signupRadioButton?.addEventListener('change', () => {
            this.clearInvalidField(signupRadioButton, [noThanksRadioButton]);
        });
        
        noThanksRadioButton?.addEventListener('change', () => {
            this.clearInvalidField(noThanksRadioButton, [signupRadioButton]);
        });
    }
     clearInvalidField(field: HTMLElement, relatedFields: HTMLElement[] = []) {
        field.classList.remove('invalid-field');
        const errorMessage = document.getElementById(`${field.id}-error-msg`);
        if (errorMessage) {
            errorMessage.remove();
        }
    
        relatedFields.forEach(relatedField => {
            relatedField.classList.remove('invalid-field');
            const relatedErrorMessage = document.getElementById(`${relatedField.id}-error-msg`);
            if (relatedErrorMessage) {
                relatedErrorMessage.remove();
            }
        });
    }
     handleFocusOut() {
        const zipCodeInput = document.getElementById('send-zip') as HTMLInputElement;
        const zipCode = zipCodeInput?.value.trim();
        DisclaimerServiceIdentifier(zipCode);
    }

    hideError(id: any) {
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field')



    }


    private createCommonState(finalResult: any) {
        const selectState = document.getElementById('send-state') as HTMLSelectElement;
        while (selectState?.firstChild) {
            selectState.removeChild(selectState.firstChild);
        }
        const option = document.createElement("option");
        option.text = 'State/Province';
        option.value = '';
        selectState.appendChild(option);
        finalResult?.forEach((item: any) => {
            const option = document.createElement("option");
            option.text = item.stateName;
            option.value = item.stateCode;
            selectState.appendChild(option);
        });

    }

    private async init() {
        this.countryCode =  sessionStorage.getItem('countryCode') ?? 'us';
        this.form = document.querySelector('.contact-info form button') as HTMLFormElement;
        this.mobileNumberInput = document.getElementById('send-phone') as HTMLInputElement;
        const stateUsCa = document.getElementById('stateusca') as HTMLInputElement;

        if (this.mobileNumberInput) {
            this.mobileNumberInput.addEventListener('input', this.handleMobileInput.bind(this));
        }

        document.addEventListener('DOMContentLoaded', () => {
            const containerImages = document.querySelector('.scheduler-main')
            const isApp = window.location.pathname.includes('schedule-appointment')
            if (!containerImages && !isApp) {
                const fileInputs = document.querySelectorAll('input[type="file"]');
                fileInputs.forEach((fileInput, index) => {
                    fileInput.addEventListener('change', () => {
                        this.showFilePreview(fileInput, index)
                    }
                    );
                });

                const closeIcons = document.querySelectorAll('.icon-close')

                closeIcons.forEach((close, index) => {
                    close.addEventListener('click', this.clearPreview.bind(this, index));
                });
            }
        });

        if (this.form) {
            if (document.getElementById('send-state')) {
                const domain = DomainIdentifier();
                if (stateUsCa?.value == "true" && domain == 'us') {
                    try {
                        const state_us = await commonStates('US');
                        const state_ca = await commonStates('CA');
                        let finalResult = [...state_us, ...state_ca];
                        finalResult.sort((a, b) => {
                            if (a.stateName !== b.stateName) {
                                return a.stateName < b.stateName ? -1 : 1;
                            } else {
                                return 0;
                            }
                        });
                        this.createCommonState(finalResult);
                    }
                    catch (error) {
                        console.error('Error in fetching state API data:', error);
                    }
                } else if (this.countryCode == 'ca' || domain == 'ca') {
                        const state_ca = await commonStates('CA');
                        this.createCommonState(state_ca);
                } else {
                        const state_us = await commonStates('US');
                        this.createCommonState(state_us);
                }
            }
            this.form.addEventListener('click', this.handleSubmit.bind(this));
        }

        this.shortFormService?.forEach((checkbox) => {
            checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
          });

        const zipCodeInput = document.getElementById('send-zip') as HTMLInputElement;
        zipCodeInput?.addEventListener('focusout', this.handleFocusOut.bind(this));


    }

    handleCheckboxChange() {
        const serviceReminderDivs = document.querySelectorAll('.contact-info .service-reminder-main');
        if (serviceReminderDivs?.length === 0 && document.querySelector('.contact-info .service-reminder-main')) {
            console.log('Servcie Disclaimer not authored in CP');
            return;
        }
        let errorID = null;

        serviceReminderDivs.forEach(div => {
            if (div && !div.classList.contains('hidden')) {
                const checkbox = div.querySelector('input[type="checkbox"]');
                const errorMsg = div.querySelector('.error-msg');
                if (checkbox && errorMsg && checkbox instanceof HTMLInputElement && checkbox.required) {
                    checkbox.addEventListener('change', () => {
                        if (checkbox.checked) {
                            errorMsg.classList.add('hidden');
                        } else {
                            errorMsg.classList.remove('hidden');
                            errorID = errorMsg?.id;
                        }
                    });

                    if (checkbox.required && !checkbox.checked) {
                        errorID = errorMsg?.id;
                        errorMsg.classList.remove('hidden');
                    }
                }
            }
        });
        return errorID;
    }

    private clearPreview(index: any): void {
        const previewContainer: any = document?.querySelectorAll('.file-upload img')[index];
        const uploadData: any = document?.querySelectorAll('.upload-data')[index];
        previewContainer.src = null
        const fileInputs: any = document.querySelectorAll('input[type="file"]')[index] as HTMLInputElement
        fileInputs.value = null
        previewContainer.removeAttribute('style')
        uploadData.classList.remove('active');
    }


    private async handleSubmit(event: Event): Promise<any> {
        event.preventDefault();
        const formFields = this.getFormFields();
        const conceptId: any = document.getElementById('conceptId');
        const url = window.location.origin;
        let vendorId;

        try {

            const brandJson = getBrandJSON();
            if(brandJson === 'Error validating Location'){
                console.error("Error Determining BrandJSON path for given location")
            }
            else{
                const apiResponse = await fetch(`${url}/${brandJson}`);
                const apiData = await apiResponse.json();
                const matchingVendor = apiData.find((item: any) => item.id === conceptId?.value);
                if (matchingVendor) {
                    vendorId = matchingVendor.vendorId;
                }
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        if (this.validateForm()) {
            const formData: any = new FormData();
            startLoader();
            var host = window.location.hostname.toLowerCase();
            let IsTest;
            if (host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) {
                IsTest = true;
            } else {
                IsTest = false;
            }
            this.franchiseWebLocationId = document.getElementById('weblocationId') as HTMLInputElement || document.getElementById('local_weblocationId') as HTMLInputElement
            this.isLocalized = (this.franchiseWebLocationId == undefined ? false : (this.franchiseWebLocationId?.value != '' ? true : false));
            let addressLine1 = formFields.address1 != "" ? `Address Line 1:${formFields.address1}` : "";
            let addressLine2 = formFields.address2 != "" ? `, Address line 2:${formFields.address2}` : "";
            if (addressLine1 === "" && addressLine2 === "") {
                addressLine1 = "no address collected"
            }
            let preferMethod = formFields.contactMethod ? `Prefrred Contact Method: ${formFields.contactMethod}, ` : ""
            const city = formFields.city != "" ? `, City: ${formFields.city}` : "";
            const state = formFields.state != "" ? `, State ${formFields.state}` : "";
            const note = formFields.note ? `${formFields.note}, ` : "";
            const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
            const brandData = await getBrandDetailsFromBrandJson(conceptCode);
            let noteText ='';
            if(brandData.note_text_contactus_api.length > 0) {
                noteText = brandData.note_text_contactus_api;
            }
            // Handle FranchiseWebLocation ID
            // If available, set isLocalized to true as per our requirement.
            const franchiseId = await getFranchiseWebLocationIdDetails(formFields.zipCode);
            if (franchiseId) {
                this.isLocalized = true;
            }

            let requestBody: ContactUsPayloadModel = {
                "firstName": formFields.firstName,
                "lastName": formFields.lastName,
                "zipCode": formFields.zipCode.trim(),
                "phone": formFields.phoneNumber,
                "email": formFields.email,
                "comments": `${note} ${preferMethod} ${addressLine1} ${addressLine2} ${city} ${state} ${noteText}`,
                "signUpForUpdates": formFields?.isSignup,
                "franchiseWebLocationId": franchiseId ? parseInt(franchiseId.toString()) : parseInt(this.franchiseWebLocationId?.value.toString()),
                "isLocalized": conceptId?.value ==="25" ? true : this.isLocalized,
                "isNewCustomer": formFields.customerType ?? '',
                "isTest": IsTest,
                "conceptId": parseInt(conceptId?.value),
                "vendorId": parseInt(vendorId),
            };

            try {  
                const domain = DomainIdentifier();
                const localisation = LocalizationIdentifier();
                // Default value to pass if checkbox is not found
                let checkboxId = '';
                
                if (((domain === "us" && !localisation) || (domain === "us" && localisation=="us"))) {
                    checkboxId = 'short-form-service-reminder-us';
                } else {
                    checkboxId = 'short-form-service-reminder-ca';
                }
                const contactUsForm:any = document.querySelector('.contact-info');
                this.noteWithRequestBody = disclaimerHandleCheck(false, contactUsForm, requestBody, requestBody.zipCode,checkboxId);
                requestBody = this.noteWithRequestBody;
    
            } catch (error) {
                console.error('An error occurred in disclaimerHandleCheck in ContactUS.ts file:', error);
            }

            const successFallbackMessage = document.querySelector('.contact-info .container .contact-block .width-60 .contact-thanku .contact-heading') as HTMLElement;

            const thankyouFallbackMessage = document.querySelector('.contact-info .container .contact-block .width-60 .contact-thanku .thanku-msg') as HTMLElement;

            //fallback message if nothing is authored from CP
            let successHeadingMessage = 'Thank you for contacting us!';
            let thankYouMessage = 'A representative from our office will get back to you shortly.';

            if(successFallbackMessage){
                if(successFallbackMessage.innerHTML)
                successHeadingMessage = successFallbackMessage.innerHTML;
            }
            if(thankyouFallbackMessage){
                if(thankyouFallbackMessage.innerHTML)
                    thankYouMessage = thankyouFallbackMessage.innerHTML;
            }

            const html = `<div class="width-60">
                <div class="contact-form-wrap contact-thanku">
                    <h2 class="contact-heading">${successHeadingMessage}</h2>
                    <p class="thanku-msg">${thankYouMessage}</p>
                </div>
            </div>`;

            const messageDiv = document.querySelector('.contact-info .container .contact-block') as HTMLFormElement;
            const formDiv = document.querySelector('.contact-info .container .contact-block .width-60') as HTMLFormElement;

            contactUsPostCallWrapper(apiConfig.CONTACT_US_SEND, requestBody)
                .then((response: any) => {
                    requestEstimate('contact_us','long-form','success','');
                    const submitObj = {
                        event: 'opus_form_submit_ev',
                        status: 'success'
                    }
                    gtmCustomEventLeadFlow(submitObj);
                    storeBookingApiPayload(requestBody,response);
                    stopLoader()
                    formDiv.remove()
                    messageDiv?.insertAdjacentHTML('afterbegin', html);
                    this.insertAfterUrl();
                    document.querySelectorAll('.contact-thanku')?.forEach((el) => el.scrollIntoView(false));
                })
                .catch((err) => {
                    requestEstimate('contact_us','long-form','failure','');
                    stopLoader();
                    const submitObj = {
                        event: 'opus_form_submit_ev',
                        status: 'failure'
                    }
                    gtmCustomEventLeadFlow(submitObj);
                });
        } else {
            stopLoader()
        }
    }
    private insertAfterUrl(): any {
        var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + '#thank-you';
        window.history.pushState({ path: refresh }, '', refresh);
    }
    
    private getFormFields(){
        const fields: any = {
            firstName: (document.getElementById('send-name') as HTMLInputElement)?.value ?? "",
            lastName: (document.getElementById('send-lname') as HTMLInputElement)?.value ?? "",
            zipCode: (document.getElementById('send-zip') as HTMLInputElement)?.value ?? "",
            phoneNumber: (document.getElementById('send-phone') as HTMLInputElement)?.value ?? "",
            email: (document.getElementById('send-email') as HTMLInputElement)?.value ?? "",
            customerType: this.getSelectedRatio(document.getElementsByName('inline-radio-cust')),
            contactMethod: this.getSelectedRatio(document.getElementsByName('inline-radio-method')),
            address1: (document.getElementById('send-address1') as HTMLInputElement)?.value ?? "",
            address2: (document.getElementById('send-address2') as HTMLInputElement)?.value ?? "",
            city: (document.getElementById('send-city') as HTMLInputElement)?.value ?? "",
            state: (document.getElementById('send-state') as HTMLSelectElement)?.value ?? "",
            note: (document.getElementById('send-note') as HTMLTextAreaElement)?.value ?? "",
        };
    
        if(document.querySelector('input[name="inline-radio-tips"]')) {
            fields['isSignup'] = (document.querySelector('input[name="inline-radio-tips"]') as HTMLInputElement)?.checked ?? "";
        }
    
        return fields;
    }
    

    private getSelectedRatio(node: any): string | null {
        const radioButtons = node;
        for (const radioButton of radioButtons) {
            if ((radioButton as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${radioButton.id}"]`) as HTMLElement;
                const label = labelElement.innerText;
                return label;
            }
        }
        return null;
    }

    private getSelectedFiles(): File[] {
        const fileInputs = document.querySelectorAll('input[type="file"]');
        const selectedFiles: File[] = [];
        fileInputs.forEach((input: any) => {
            if (input.files && input.files.length > 0) {
                selectedFiles.push(input.files[0]);
            }
        });
        return selectedFiles;
    }

    private handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = this.mobileNumberInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        if (x) {
            this.mobileNumberInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }
    }

    private showFilePreview(fileInput: any, index: number): void {
        const file = fileInput.files && fileInput.files[0];
        s3upload(file, (fs) => {
            FilesUrl[index] = fs

        })

        const fileName = file.name;
        const fileExt = fileName.split('.')[1]?.trim();
        const imgFiles = ["png", "svg", "jpg", "jpeg"].includes(fileExt);
        const pdfFiles = fileExt === "pdf";
        const excelFiles = fileExt === "xlsx";
        const docFiles = ["docx", "doc"].includes(fileExt);
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const previewContainer: any = document?.querySelectorAll('.file-upload img')[index];
                const uploadData: any = document?.querySelectorAll('.upload-data')[index];
                if (previewContainer) {
                    if (imgFiles) {
                        previewContainer.src = reader.result as string;
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (pdfFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/pdf-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (excelFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/excel-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    } else if (docFiles) {
                        previewContainer.src = "/brand/_assets/images/icons/doc-icon.svg";
                        previewContainer.style.display = "block";
                        uploadData.classList.add('active')
                    }

                }
            };
            reader.readAsDataURL(file);
        }
    }


    private validateForm(): boolean {
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
        const errors: any = {};
        const firstNameInput = document.getElementById('send-name') as HTMLInputElement;
        const lastNameInput = document.getElementById('send-lname') as HTMLInputElement;
        const zipCodeInput = document.getElementById('send-zip') as HTMLInputElement;
        const phoneNumberInput = document.getElementById('send-phone') as HTMLInputElement;
        const emailInput = document.getElementById('send-email') as HTMLInputElement;
        const address1Input = document.getElementById('send-address1') as HTMLInputElement;
        const address2Input = document.getElementById('send-address2') as HTMLInputElement;
        const cityInput = document.getElementById('send-city') as HTMLInputElement;
        const stateSelect = document.getElementById('send-state') as HTMLSelectElement;
        const noteTextarea = document.getElementById('send-note') as HTMLTextAreaElement;

        const customerRadio = document.getElementById('inline-radio-1') as HTMLInputElement;
        const customerRadio2 = document.getElementById('inline-radio-2') as HTMLInputElement;
        const contPhoneMethod = document.getElementById('inline-radio-3') as HTMLInputElement;
        const contEmailMethod = document.getElementById('inline-radio-4') as HTMLInputElement;
        const contTextMethod = document.getElementById('inline-radio-5') as HTMLInputElement;
        const signupRadioButton = document.getElementById('inline-2-radio-3') as HTMLInputElement;
        const noThanksRadioButton = document.getElementById('inline-2-radio-4') as HTMLInputElement;

        if(firstNameInput){
            if (firstNameInput.required && firstNameInput.value === '') {
                errors['send-name'] = '';
            } else if (firstNameInput.required && firstNameInput.value != '' && !REGEX.sendName.test(firstNameInput.value)) {
                errors['send-name'] = 'Invalid First name format.';
            }
        }

        if(lastNameInput){
            if (lastNameInput.required && lastNameInput.value === '') {
                errors['send-lname'] = '';
            } else if (lastNameInput.required && lastNameInput.value != '' && !REGEX.sendLname.test(lastNameInput.value)) {
                errors['send-lname'] = 'Invalid last name format.';
            }
        }

        if(zipCodeInput){
            if (zipCodeInput.required && zipCodeInput.value === '') {
                errors['send-zip'] = '';
            } else if (zipCodeInput.required && !REGEX.sendZip.test(zipCodeInput.value.trim())) {
                errors['send-zip'] = 'Invalid zip code format.';
            }
        }

        if(phoneNumberInput){
            if (phoneNumberInput.required && phoneNumberInput.value === '') {
                errors['send-phone'] = '';
            }
            else if (phoneNumberInput.required && phoneNumberInput.value != '' && phoneNumberInput.value?.length < 14) {
                errors['send-phone'] = 'Invalid phone format.'
            }
        }

        if(stateSelect){
            if (stateSelect.required && stateSelect.value === '') {
                errors['send-state'] = 'Enter a valid state.';
            }
        }
        
        if(address1Input){
            if (address1Input.required && address1Input.value === '') {
                errors['send-address1'] = '';
            }
            else if (address1Input.required && address1Input.value != '' && !REGEX.sendAddress1.test(address1Input.value)) {
                errors['send-address1'] = 'Invalid address format.'
            }
        }

        if(address2Input){
            if (address2Input.required && address2Input.value === '') {
                errors['send-address2'] = '';
            }
            else if (address2Input.required && address2Input.value != '' && !REGEX.sendAddress1.test(address2Input.value)) {
                errors['send-address2'] = 'Invalid address format.'
            }
        }


        if(noteTextarea){
            if (noteTextarea.required && noteTextarea.value === '') {
                errors['send-note'] = '';
            }
        }

        if(emailInput){
            if (emailInput.required && emailInput.value === '') {
                errors['send-email'] = '';
            } else if (emailInput.required && !REGEX.sendEmail.test(emailInput.value)) {
                errors['send-email'] = 'Invalid email format.';
            }
        }

        if(cityInput){
            if (cityInput.required && cityInput.value === '') {
                errors['send-city'] = '';
            }
            else if (cityInput.required && cityInput.value !== '' && !REGEX.sendCity.test(cityInput.value)) {
                errors['send-city'] = 'Invalid city format.';
            }
        }

        if(customerRadio && customerRadio2){
            if (customerRadio.required && customerRadio2.required && (!customerRadio2.checked && !customerRadio.checked)) {
                errors['inline-radio-1'] = '';
            }
        }
        if(contPhoneMethod && contEmailMethod && contTextMethod){
            if (contPhoneMethod.required && contEmailMethod.required && contTextMethod.required &&(!contPhoneMethod.checked && !contEmailMethod.checked && !contTextMethod.checked)) {
                errors['inline-radio-3'] = '';
            }
        }
        if(signupRadioButton && noThanksRadioButton){
            if (signupRadioButton.required && noThanksRadioButton.required && (!signupRadioButton.checked && !noThanksRadioButton.checked)) {
                errors['inline-2-radio-3'] = '';
            }
        }

        const errorIdChekboxSpan = this.handleCheckboxChange();

        if(errorIdChekboxSpan){
            errors[errorIdChekboxSpan] = '';
        }

        Object.keys(errors).forEach((fieldId, index) => {
            if (fieldId == 'inline-radio-1' || fieldId == 'inline-radio-2' || fieldId == 'inline-radio-3' || fieldId == 'inline-radio-4' || fieldId == 'inline-radio-5' || fieldId == 'inline-2-radio-3' || fieldId == 'inline-2-radio-4') {
                const field: any = document.getElementsByClassName('radio-btn-wrapper')[0];
                const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
                if (field && field.parentNode) {
                    const errorMessage = errors[fieldId];
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${fieldId}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    if (fieldId == 'inline-radio-1' || fieldId == 'inline-radio-2'){
                        customerRadio.classList.add("invalid-field");
                        customerRadio2.classList.add("invalid-field");
                    } else if (fieldId == 'inline-radio-3' || fieldId == 'inline-radio-4' || fieldId == 'inline-radio-5'){
                        contPhoneMethod.classList.add("invalid-field");
                        contEmailMethod.classList.add("invalid-field");
                        contTextMethod.classList.add("invalid-field");
                    } else{
                        signupRadioButton.classList.add("invalid-field");
                        noThanksRadioButton.classList.add("invalid-field");
                    }
                    if (fieldError && errorMessage) {
                        fieldError.innerHTML = errorMessage
                    }
                    if (fieldError?.classList.contains('hidden'))
                        fieldError.classList.remove('hidden')
                }
            }
            else {

                const field: any = document.getElementById(fieldId);
                let fieldError: any = document.getElementById(`${fieldId}-error-msg`);

                if (field && field.parentNode) {

                    const errorMessage = errors[fieldId];
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${fieldId}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");

                    if (!fieldError) {
                        field.parentNode.appendChild(errorElement);
                        fieldError = document.getElementById(`${fieldId}-error-msg`);
                    }

                    if (fieldError && errorMessage) {
                        fieldError.innerHTML = errorMessage
                    }
                    if (fieldError && fieldError.classList.contains('hidden'))
                        fieldError.classList.remove('hidden')
                }

                if (index == 0 && field) {
                    field.focus()
                }
            }
        });

        return Object.keys(errors).length === 0;
    }

    private createErrorElement(inputField: HTMLInputElement | HTMLSelectElement, errorMessage: string): void {
        const errorElement = document.createElement('span');
        errorElement.className = 'error-msg';
        errorElement.id = `${inputField.id}-error-msg`;
        errorElement.textContent = errorMessage;
        inputField.classList?.add('invalid-field');
        if (inputField?.parentNode) {
            inputField.parentNode.appendChild(errorElement);
        }
    }
}

if (!document.getElementById('ask-question-form'))
    new FormHandler();
