//Check to see if this is a test enviroment
import { REGEX } from "../../../constants/regex";
export function isTestEnvironment(): boolean {
    const host = window.location.hostname.toLowerCase();
    const testHosts = ['nblysbx', 'nblydev', 'nblytest', 'localhost'];
  
    return testHosts.some(testHost => host.includes(testHost));
}
// Fetching failed requests
export function getFailedResponses(): any[] {
    const failedResponsesJSON = sessionStorage.getItem('fR');
    return failedResponsesJSON ? JSON.parse(failedResponsesJSON) : [];
}
//Storing Failed requests after encoding it
export function storeFailedResponse(request: any, error: any) {
    try {
      const failedResponses = getFailedResponses();
      failedResponses.push(btoa(JSON.stringify({
        data: request,
        error,
        timestamp: new Date().getTime(),
      })));
      sessionStorage.setItem('fR', JSON.stringify(failedResponses));
    } catch (err) {
      console.error('Error while storing failed responses:', err);
    }
}
//Clearing Failed requests after encoding it
export function clearFailedResponse() {
    sessionStorage.removeItemItem('fR');
}
//check and return a trimmed zipcode
export function isValidZipCode(zipcode: any) {
    const trimmedZipcode = String(zipcode).trim();
    return REGEX.sendZip.test(trimmedZipcode) ? trimmedZipcode : false;
}
//flatten object
export function flattenObject(obj: any, prefix: string = ''): any {
  let result: any = {};
  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          const newKey = prefix ? `${prefix}.${key}` : key;
          if (typeof obj[key] === 'object' && obj[key] !== null) {
              Object.assign(result, flattenObject(obj[key], newKey));
          } else {
              result[newKey] = obj[key];
          }
      }
  }
  return result;
}

//remove null/empty values from payload
export function removeNullAndEmpty(obj: any): any {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.filter(item => !isNullOrEmpty(item));
  }

  if (typeof obj === 'object') {
    const newObj: { [key: string]: any } = {};
    for (const key in obj) {
      const value = obj[key];
      if (!isNullOrEmpty(value)) {
        newObj[key] = removeNullAndEmpty(value);
      }
    }
    return newObj;
  }

  return obj;
}

function isNullOrEmpty(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return Object.keys(value).length === 0;
    }
  }

  return false;
}

//convert payload key name to camelcase first alphabet
export function normalizeKeys(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(normalizeKeys);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const normalizedKey = key.charAt(0).toLowerCase() + key.slice(1);
      acc[normalizedKey] = normalizeKeys(obj[key]);
      return acc;
    }, {} as { [key: string]: any });
  }
  return obj;
}



