import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import { fetchCrownpeakUrlDetails, getBrandDetailsFromBrandJson, updateHeaderContent } from "../util/share";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { Footer } from "../footer/footer";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import { HMSStep4 } from "./step4";
import { FormHandlerLead } from "../lead-flow";
import { cachedAPIWrapper, getCachedAPIResponse } from "../apiCaching/apiWrapper";

export let hms_booking: any = {}
export let isLeadDataHms: boolean = false;

export class ScheduleService {
    private headerBtnZipcode: HTMLInputElement | any;
    private localSearchInput: HTMLInputElement | any;
    private cancelBtn: HTMLInputElement | any;
    private onlineScheduling: any;
    private isLead: boolean = false;

    private isLeadPage:boolean =  false;

    private POSObject:any;

    constructor() {

        const formNext = document.querySelectorAll('#form-section .contact-us-section');

        const containerDom = document.querySelector('.scheduler-main .container')as HTMLElement;
        if(containerDom)
            containerDom.style.display = "";
        const hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') as any);
        const zipcode = sessionStorage.getItem('sAddressParam');
        if(formNext.length > 0) {
            this.insideOnlinePage(zipcode, hmsBookingData)
        }
        //This is for non leadflow page
        else{
            this.reconstructRedirectPopup();
            this.nonLeadFlowPage(zipcode, hmsBookingData)
        }
    }

    reconstructRedirectPopup(){
        const htmlPopUp = `<div class="redirection-modal hidden modal justify-center items-center" id="redirection-modal" data-nbly="redirection-modal" tabindex="-1" aria-hidden="true" data-modal-backdrop="static">
            <div class="modal-loc-inner">
                <div class="modal-loc-div">
                    <div class="modal-header">
                        <button type="button" class="close-modal-btn" data-modal-hide="redirection-modal">
                            <img src="/brand/_assets/images/brand-img/close-circle.svg" alt="close modal" width="24" height="24">
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        
                        <div class="text-wrapper">
                            <h2 class="modal-heading">Connecting with your local HouseMaster</h2>
                            <img class="brand-logo" src="/brand/_assets/images/brand-img/brand-logo.svg" alt="Connecting with your local HouseMaster" width="230" height="48">
                            <p class="modal-text">HouseMaster is a Neighborly Company. You are being transferred to Neighborly.com to complete you scheduled request.</p>
                            <button type="button" data-modal-hide="redirection-modal" class="primary-btn close-modal-btn">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
        const modalTrigger = `<a href="#" data-modal-target="redirection-modal" data-modal-toggle="redirection-modal" id="open-redirection-modal" class="hidden">
                <div>Redirection Modal</div>
                </a>`;
        // Convert the HTML string to a DOM element
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlPopUp;
        const popupElement = tempDiv.firstChild;

        // Append the new elements to the body
        if(popupElement)
            document.body.appendChild(popupElement);

        const tempDiv2 = document.createElement('div');
        tempDiv2.innerHTML = modalTrigger;
        const triggerElement = tempDiv2.firstChild;

        if(triggerElement)
            document.body.appendChild(triggerElement);
    }

    insideOnlinePage(zipcode:any, hmsBookingData:any){
        this.isLeadPage = true;
        if(zipcode){
            //new POS Checker
            this.checkPOSSystemWithZip(zipcode)

            this.handleEventListeners();

            return
        }
        else{
            const weblocationIdElement = document.getElementById('local_weblocationId') as HTMLInputElement;
            const sweblocationId = weblocationIdElement?.value ?? '';
            if (sweblocationId) {
                this.checkPOSSystemWithWebLocation(sweblocationId);
            }
        }
        if (hmsBookingData?.zipcode) {
            //new POS Checker
            this.checkPOSSystemWithZip(hmsBookingData?.zipcode)

            hms_booking = hmsBookingData;
            this.nextStep2(null);
        }
        this.handleEventListeners();
    }

    nonLeadFlowPage(zipcode:any, hmsBookingData:any){
        this.isLeadPage = false;
        if(zipcode){
            this.addEventListnersToHMSCta(zipcode, "zip");
            return
        }
        else{
            const weblocationIdElement = document.getElementById('local_weblocationId') as HTMLInputElement;
            const sweblocationId = weblocationIdElement?.value ?? '';
            if (sweblocationId) {
                this.addEventListnersToHMSCta(sweblocationId, "weblocation");
            }
        }
        if (hmsBookingData?.zipcode) {
            this.addEventListnersToHMSCta(zipcode, "zip");
        }
    }

    //This is to attach the event listeners for the a tags
    async addEventListnersToHMSCta(value: any, type: string){
        const authoredCtaLink = await this.getAuthoredCtaLinkFromBrand();

        if(authoredCtaLink){
            const appointmentCtaLinks = document.querySelectorAll(`a[href="${authoredCtaLink}"]`);
            appointmentCtaLinks.forEach(link => {
                link.addEventListener('click', (event) => {
                    event.preventDefault(); // Prevent the default link behavior
                    this.reDirectComputeIfitsRedirect(value, type, authoredCtaLink)
                });
            });
        }
        

    }

    //to fetch the achor tag link for HMS. All Leadflow would go here
    async getAuthoredCtaLinkFromBrand(){
        const conceptId = (document.getElementById("conceptCode") as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptId);
        const authoredCtaLink = brandData?.request_button_link_type ?? null;
        return authoredCtaLink
    }
    
    async reDirectComputeIfitsRedirect(value: any, type: string, redirectionLink: string) {
        startLoader();
        try {
            if (type === "zip") {
                await this.reDirectHandleZipType(value, redirectionLink);
            } else if (type === "weblocation") {
                await this.reDirectHandleWebLocationType(value, redirectionLink);
            }
        } catch (error) {
            console.error(`Error processing ${type} type for value: ${value}`, error);
        } finally {
            stopLoader();
        }
    }

    async reDirectHandleZipType(value: any, redirectionLink: string) {
        const url = apiConfig.POSLookup + value;
        try {
            const result = await getCachedAPIResponse(url, apiCaching.POSSystemLookup);
            if (result) {
                const POSName = result[0].posName;
                this.POSObject = result[0];
                if(POSName === null && result[0].franchiseWebLocationId == null){
                    window.location.href = redirectionLink;
                }
                else if(POSName === null && result[0].franchiseWebLocationId !== null){
                    sessionStorage.setItem("hms_lead_ISN_zip", value);
                    await this.reDirectProcessISNZip(value, redirectionLink);
                }
                else if (POSName.toLowerCase() === "isn") {
                    sessionStorage.setItem("hms_lead_ISN_zip", value);
                    await this.reDirectProcessISNZip(value, redirectionLink);
                }else if(POSName.toLowerCase() === "onverity"){
                    window.location.href = redirectionLink;
                }
                
            }
        } catch (error) {
            console.error(`Error fetching POS systems for ${value}`, error);
        }
    }

    async reDirectProcessISNZip(value: any, redirectionLink: string) {
        const url = apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(value));
        try {
            const result = await getCachedAPIResponse(url, apiCaching.LocateLocationApiWithRoundRobinTrue);
            if (result.length > 0) {
                const { franchiseWebLocationId } = result[0];
                await this.reDirectFetchFullAttribute(franchiseWebLocationId, redirectionLink);
            }
        } catch (error) {
            console.error(`Error fetching LocateLocationApiWithRoundRobinTrue for ${value}`, error);
        }
    }

    async reDirectHandleWebLocationType(value: any, redirectionLink: string) {
        const url = apiConfig.FranchisePOSLookup + value;
        try {
            const result = await getCachedAPIResponse(url, apiCaching.FranchisePOSLookup);
            if (result) {
                const POSName = result[0].posName;
                this.POSObject = result[0];
                if(POSName === null && result[0].franchiseWebLocationId == null){
                    window.location.href = redirectionLink;
                }
                else if(POSName === null && result[0].franchiseWebLocationId !== null){
                    await this.reDirectFetchFullAttribute(value, redirectionLink);
                }
                else if (POSName.toLowerCase() === "isn") {
                    await this.reDirectFetchFullAttribute(value, redirectionLink);
                }
                else if (POSName.toLowerCase() === "onverity") {
                    window.location.href = redirectionLink;
                }
                 
            }
        } catch (error) {
            console.error(`Error fetching POS systems for weblocationID: ${value}`, error);
        }
    }

    async reDirectFetchFullAttribute(franchiseWebLocationId: string, redirectionLink: string) {
        const url = `${apiConfig.GET_ATTRIBUTE_DATA}/${franchiseWebLocationId}`;
        try {
            const resp = await cachedAPIWrapper(url, apiCaching.FullAttributeResponse);
            this.onlineScheduling = resp?.onlineScheduling;
            if (this.onlineScheduling) {
                this.redirectToExternalSite(this.POSObject.franchiseWebLocationMarketingId);
            } else {
                window.location.href = redirectionLink;
            }
        } catch (error) {
            console.error(`Error fetching Full Attribute for ${franchiseWebLocationId}`, error);
            throw new Error("Error fetching Full Attribute");
        }
    }


    public init() {

        this.onlineScheduling = false;
        const isApp = window.location.pathname.includes('schedule-appointment');
        const local_dba: any = (document.getElementById('dbaName') as HTMLInputElement)?.value;
        const local_weblocatioId: any = (document.getElementById('weblocationId') as HTMLInputElement)?.value;

        const containerDiv = document.querySelector(".scheduler-main .container")as HTMLElement
        if(containerDiv)
            containerDiv.style.display="";

        if (local_dba && local_weblocatioId) {
            sessionStorage.setItem("isLocal", "true");
            localStorage.setItem("franchiseWebLocationId", local_weblocatioId)
        }
        this.CloseErrorModal();
        this.initBackBtn();
        if (sessionStorage?.isLocal == "true" && isApp) {
            this.isLead = true;
            this.getDetails(sessionStorage.franchiseWebLocationId);
        } 
        
    }

    handleEventListeners(){
        this.localSearchInput = document.getElementById('location-search-input') as HTMLInputElement;
        this.headerBtnZipcode = document.querySelector('.zip-modal form .btn-container .primary-btn');
        this.cancelBtn = document.querySelector('#zip-modal .white-btn') as HTMLInputElement;
        if (this.headerBtnZipcode) {
            this.headerBtnZipcode.addEventListener("click", this.handleButtonSubmit.bind(this));
        }

        if (this.localSearchInput) {
            this.localSearchInput?.addEventListener('input', this.zipCodeError.bind(this))
            document.getElementById("step1-title")!.style.display = "block";
            document.getElementById("step1-subtitle")!.style.display = "none";
        };
        if (this.cancelBtn) {
            this.cancelBtn.addEventListener("click", this.handleCancelBtnClick.bind(this));
        }
    }


    async checkPOSSystemWithZip(zipcode:any){
        const url = apiConfig.POSLookup+zipcode;
        try{
            const result = await getCachedAPIResponse(url, apiCaching.POSSystemLookup)
            if(result){
                stopLoader();
                const POSName = result[0].posName;
                this.POSObject = result[0];
                if(POSName === null &&  result[0].franchiseWebLocationId !== null ){
                    sessionStorage.setItem('franchiseWebLocationMarketingId', result[0].franchiseWebLocationMarketingId);
                    this.isnFlow(zipcode);
                }
                else if(POSName.toLowerCase() === "onverity")
                {
                    sessionStorage.setItem('sAddressParam', zipcode);
                    sessionStorage.setItem('franchiseWebLocationMarketingId', result[0].franchiseWebLocationMarketingId);
                    this.onverityFlow(zipcode);
                }
                else if(POSName.toLowerCase() === "isn")
                {
                    sessionStorage.setItem('sAddressParam', zipcode);
                    sessionStorage.setItem('franchiseWebLocationMarketingId', result[0].franchiseWebLocationMarketingId);
                    this.isnFlow(zipcode)
                }
                else{
                    const errorModal = document.getElementById("modal-hms-appoinment-slot-error");
                    if(errorModal ){
                        const htmlErrorModalContent = `
                            <p class="title">
                            <svg class="close-icon"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#information-circle"></use></svg>Please call us to find a franchise near you.</p>
                            <p class="text-center">We apologize for the inconvenience. Please call us at (800) 526-3939 and we will help you book an inspection.</p>
                            <strong></strong>
                        `;
                        errorModal.innerHTML = htmlErrorModalContent;
                        errorModal.style.display = "";
                        document.getElementById("error-modal-HMS-trigger")?.click();
                        document.querySelector(".error-modal-HMS .modal-body button")?.addEventListener("click",()=>{
                            this.handleCancelBtnClick(); 
                        })
                    }
                }
            }
        }
        catch{
            stopLoader();
            const errorModal = document.getElementById("modal-hms-appoinment-slot-error");
            if(errorModal ){
                const htmlErrorModalContent = `
                    <p class="title">
                    <svg class="close-icon"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#information-circle"></use></svg>Please call us to find a franchise near you.</p>
                    <p class="text-center">We apologize for the inconvenience. Please call us at (800) 526-3939 and we will help you book an inspection.</p>
                    <strong></strong>
                `;
                errorModal.innerHTML = htmlErrorModalContent;
                errorModal.style.display = "";
                document.getElementById("error-modal-HMS-trigger")?.click();
                document.querySelector(".error-modal-HMS .modal-body button")?.addEventListener("click",()=>{
                    this.handleCancelBtnClick(); 
                })
            }
            console.error(`Error fetching POS systems for ${zipcode}`)
        }
    }

    async checkPOSSystemWithWebLocation(weblocation:any){
        const url = apiConfig.FranchisePOSLookup+weblocation;
        
        try{
            const result = await getCachedAPIResponse(url, apiCaching.FranchisePOSLookup)
            stopLoader();
            const POSName = result[0].posName;
            this.POSObject = result[0];
            if(POSName === null &&  result[0].franchiseWebLocationId !== null ){
                sessionStorage.setItem('franchiseWebLocationMarketingId', result[0].franchiseWebLocationMarketingId);
                this.redirectToISN();
            }
            else if(POSName === null &&  result[0].franchiseWebLocationId === null ){
                const errorModal = document.getElementById("modal-hms-appoinment-slot-error");
                if(errorModal ){
                    const htmlErrorModalContent = `
                        <p class="title">
                        <svg class="close-icon"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#information-circle"></use></svg>Please call us to find a franchise near you.</p>
                        <p class="text-center">We apologize for the inconvenience. Please call us at (800) 526-3939 and we will help you book an inspection.</p>
                        <strong></strong>
                    `;
                    errorModal.innerHTML = htmlErrorModalContent;
                    errorModal.style.display = "";
                    document.getElementById("error-modal-HMS-trigger")?.click();
                    document.querySelector(".error-modal-HMS .modal-body button")?.addEventListener("click",()=>{
                        this.handleCancelBtnClick(); 
                    })
                }
            }
            else if(POSName.toLowerCase() === "isn")
            {
                sessionStorage.setItem('franchiseWebLocationMarketingId', result[0].franchiseWebLocationMarketingId);
                this.getOnlineSchedulingWithWeblocation(weblocation)
            } 
            
        }
        catch{
            const errorModal = document.getElementById("modal-hms-appoinment-slot-error");
            if(errorModal ){
                const htmlErrorModalContent = `
                    <p class="title">
                    <svg class="close-icon"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#information-circle"></use></svg>Please call us to find a franchise near you.</p>
                    <p class="text-center">We apologize for the inconvenience. Please call us at (800) 526-3939 and we will help you book an inspection.</p>
                    <strong></strong>
                `;
                errorModal.innerHTML = htmlErrorModalContent;
                errorModal.style.display = "";
                document.getElementById("error-modal-HMS-trigger")?.click();
                document.querySelector(".error-modal-HMS .modal-body button")?.addEventListener("click",()=>{
                    this.handleCancelBtnClick(); 
                })
            }
            console.error(`Error fetching POS systems for weblocationID: ${weblocation}`)
        }
        stopLoader();
    }

    onverityFlow(zipcode:any){
        const isnDOM = document.getElementById("isn-form")as HTMLElement;
        if(isnDOM) {
            if(isnDOM.parentNode)
                isnDOM.parentNode.removeChild(isnDOM);
        }
        this.init();
        this.franchiseLookupAddressApi(zipcode);
    }

    isnFlow(zipcode:any){
        this.getOnlineScheduleFlowStatus(zipcode);
        stopLoader();
    }

    redirectToISN(){
        const onverityDOM = document.getElementById("onverity-form")as HTMLElement;
        if(onverityDOM ) {
            if(onverityDOM.parentNode)
                onverityDOM.parentNode.removeChild(onverityDOM);
        }
        
        const isnDOM = document.getElementById("isn-form")as HTMLElement;
        if(isnDOM)
            isnDOM.classList.remove("hidden");

        const isnForm = document.querySelector("#isn-form #form-section")as HTMLElement;
        if(isnForm) 
            isnForm.style.display = "block";
        
        const zipModalDiv = document.querySelector('.zip-modal') as HTMLInputElement;
        if (zipModalDiv) {
            zipModalDiv.style.display = "none";
        }
        stopLoader();
        sessionStorage.setItem("hms_lead_ISN","true");
        new FormHandlerLead();
    }

    openRedirectionModal(redirectLink:string){
        const modalTrigger = document.getElementById('open-redirection-modal') as HTMLElement;
        if(modalTrigger){
            modalTrigger.click();
            const redirectionModalContent = document.querySelector('#redirection-modal .modal-body button') as HTMLButtonElement;
            const closeButton = document.querySelector('#redirection-modal .modal-header button') as HTMLButtonElement;
            if(redirectionModalContent){
                redirectionModalContent.addEventListener("click",()=>{
                    const localHomePage =  sessionStorage.getItem('redirectionURL');
                    const newTab = window.open(redirectLink, '_blank');
                    if (newTab) {
                        newTab.focus();
                        setTimeout(() => {
                            if (localHomePage) {
                                window.location.href = localHomePage;
                                sessionStorage.removeItem('redirectionURL');
                            }
                            closeButton?.click();
                        }, 100); // Adjust the timeout as needed
                    }
                })
                
            }
        }
    }
    async redirectToExternalSite(franchiseNumberToCheck: any) {
        startLoader();
        const URL = this.getRedirectConfigUrl();
        
        try {
            const result = await getCachedAPIResponse(URL, apiCaching.IsnRedirectConfig, false);
            stopLoader();
            this.handleFranchiseRedirect(result, franchiseNumberToCheck);
        } catch {
            stopLoader();
            console.error(`Error fetching ISN redirect config from CP`);
            this.redirectToISN();
        }
    }
    
    getRedirectConfigUrl() {
        const currentUrl = window.location.href;
        const filePath = `/brand/_assets/data/isn_redirect_config.json`;
        let URL = `${currentUrl.split("/")[0]}/${currentUrl.split("/")[1]}/${currentUrl.split("/")[2]}${filePath}`;
        if (URL.includes("localhost")) {
            URL = 'https://dig-www-nei-hms2-dev.nblysbx.com/brand/_assets/data/isn_redirect_config.json';
        }
        return URL;
    }
    
    handleFranchiseRedirect(result: any, franchiseNumberToCheck: any) {
        const franchiseObj = result.find((obj: any) => obj.franchisee_number === franchiseNumberToCheck);
        if (franchiseObj) {
            if (franchiseObj.isnUrl === "\\N") {
                this.redirectToISN();
            } else {
                this.triggerGTMEvent(franchiseObj.isnUrl);
                this.handlePopupWindow(franchiseObj.isnUrl);
            }
        } else {
            this.redirectToISN();
        }
    }
    
    triggerGTMEvent(isnUrl: string) {
        try {
            const leadObj = {
                event: 'custEv_ISNRedirectDataLayer',
                ISNRediectLink: isnUrl,
                isRedirectedToExternalSite: true
            };
            gtmCustomEventLeadFlow(leadObj);
        } catch (error) {
            console.log("An error occurred in gtmCustomEventLeadFlow: ", error);
        }
    }
    
    handlePopupWindow(isnUrl: string) {
        const popupWindow = window.open(isnUrl, '_blank');
        if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
            console.log("Popup blocker detected in the browser");
            this.openRedirectionModal(isnUrl);
        } else {
            const localHomePage = sessionStorage.getItem('redirectionURL');
            if (localHomePage) {
                window.location.href = localHomePage;
                sessionStorage.removeItem('redirectionURL');
            }
        }
    }
    
    async getOnlineSchedulingWithWeblocation(franchiseWebLocationId:any){
        const url = `${apiConfig.GET_ATTRIBUTE_DATA}/${franchiseWebLocationId}`;
        
        try{
            const resp = await cachedAPIWrapper(url, apiCaching.FullAttributeResponse);
            this.onlineScheduling = resp?.onlineScheduling;
            //This is to localize the data
            this.setLocalData(resp, "");
            if(this.onlineScheduling){
                this.redirectToExternalSite(this.POSObject.franchiseWebLocationMarketingId)
            }
            else{
                this.redirectToISN();
            }
        }
        catch{
            console.error(`Error fetching POS systems for weblocationID: ${franchiseWebLocationId}`)
        }
    }

    async getOnlineScheduleFlowStatus(zipCode:any){
        sessionStorage.setItem("hms_lead_ISN_zip", zipCode);
        startLoader();
        const url = apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode));
        
        try{
            const result = await getCachedAPIResponse(url, apiCaching.LocateLocationApiWithRoundRobinTrue);
            if (result.length > 0) {
                const { franchiseWebLocationId} = result[0];
                const url = `${apiConfig.GET_ATTRIBUTE_DATA}/${franchiseWebLocationId}`;
                try{
                    const resp = await cachedAPIWrapper(url, apiCaching.FullAttributeResponse);
                    this.onlineScheduling = resp?.onlineScheduling;
                    //This is to localize the data
                    this.setLocalData(resp, zipCode);
                    if(this.onlineScheduling){
                        this.redirectToExternalSite(this.POSObject.franchiseWebLocationMarketingId)
                    }
                    else{
                        this.redirectToISN();
                    }
                }
                catch{
                    console.error(`Error fetching Full Attribute`);
                    throw(new Error("Error fetching Full Attribute"))
                }
            }
            else {
                document.getElementById("step1-title")!.style.display = "none";
                document.getElementById("step1-subtitle")!.style.display = "block";
                document.getElementById("step1-subtitle")!.classList.remove('hidden');
            }
        }
        catch{
            console.error(`Error fetching LocateLocationApiWithRoundRobinTrue ${zipCode}`)
        }
    }

    public handleButtonSubmit(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.validateForm()) {
            startLoader()
            const zipCode: any = this.localSearchInput.value;
            hms_booking.zipcode = zipCode;
            localStorage.removeItem('hms_redirect_local');
            //new POS Checker
            this.checkPOSSystemWithZip(zipCode);
                
        }
    }
    public async handleCancelBtnClick() {
        const conceptId = (document.getElementById("conceptCode") as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptId);
        let redirectUrl = localStorage.getItem("dbaName") ? localStorage.getItem("dbaName") : '';
        let weblocationInfo = window?.crownpeakWeblocationsByIdDetail;
        if(!weblocationInfo && brandData?.include_statename){
            await fetchCrownpeakUrlDetails();
            weblocationInfo = window?.crownpeakWeblocationsByIdDetail;
        }

        if(brandData?.weblocation_path_group && weblocationInfo?.stateName){
            redirectUrl = `/${brandData.weblocation_path_group}/${weblocationInfo?.stateName}/${redirectUrl}`;
        }else if(brandData?.weblocation_path_group){
            redirectUrl = `/${brandData.weblocation_path_group}/${redirectUrl}`;
        }else {
            redirectUrl = `/${redirectUrl}`;
        }

        if(redirectUrl){
            window.location.href = window.location.origin + redirectUrl;
            return 
        }

        if (document.referrer) {
            window.history.back();
        } else {
           window.location.href = window.location.origin;
        }

    }
    public zipCodeError(this: any) {
        if (!(document.getElementById('step1-subtitle') as HTMLInputElement).classList.contains("hidden")) {
            document.getElementById("step1-title")!.style.display = "block";
            document.getElementById("step1-subtitle")!.style.display = "none";
            document.getElementById("step1-subtitle")!.classList.add('hidden');
        }
        if (this.localSearchInput.value != '' && !REGEX.sendZip.test(this.localSearchInput.value.trim())) {
            const getErrMsg = (document.getElementById(`location-search-input-modal-error-msg`) as HTMLElement).getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`location-search-input-modal-error-msg`);


            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid zip code format.'
            }
            else {
                const field: any = this.localSearchInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid zip code format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {

            this.hideError('location-search-input-modal')
        }

    }
    hideError(id: any) {
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field')



    }
    public validateForm(): boolean {
        const zipCodeInput = document.getElementById(`location-search-input`) as HTMLInputElement;
        const zipCode = zipCodeInput.value;
        const formFields = { zipCode };
        const errors: any = {};


        if (zipCodeInput.required && formFields.zipCode === '') {
            errors['location-search-input'] = '';
        } else if (zipCodeInput.required && !REGEX.sendZip.test(formFields.zipCode.trim())) {
            errors['location-search-input'] = 'Invalid zip code format.';
        }


        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));


        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            const fieldError: any = document.getElementById(`${fieldId}-modal-error-msg`);
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.textContent = errorMessage
                field.classList.add("invalid-field");
                if (fieldError && errorMessage) {
                    fieldError.innerHTML = errorMessage
                }
                if (fieldError && fieldError.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (index == 0 && field) {
                field.focus()
            }
        });

        return Object.keys(errors).length === 0;
    }

    private async setLocalData(data:any, zipCode:any){
       
        if(data && this.POSObject?.franchiseId){
            const franchiseId = this.POSObject?.franchiseId
            const { franchiseWebLocationId, locationDoingBusinessAs, locationWebsiteUrl, mainPhoneNumber, callTrackings} = data;
            localStorage.setItem("franchiseWebLocationId", franchiseWebLocationId);
            sessionStorage.setItem('franchiseId', franchiseId);
            localStorage.setItem('doingBusinessAs', locationDoingBusinessAs);
            sessionStorage.setItem('redirectionURL', locationWebsiteUrl);
            localStorage.setItem('weblocationId', franchiseWebLocationId);
            const locationUrl = locationWebsiteUrl?.replace(/\/$/, '') ?? '';
            const splitUrlArr = locationUrl.toLowerCase().split("/");
            const pathname = splitUrlArr[splitUrlArr.length - 1];

            let callTrackNumber: any = mainPhoneNumber;

            callTrackings.forEach((type: any) => {
                if (type.callTrackingTypeDescription == "WebsiteTracking") {
                    callTrackNumber = type.callTrackingNumber;
                    localStorage.setItem('localPhoneNumber', callTrackNumber);
                }
            });
            const phoneNO: any = document.querySelector('.header-wrapper .form-call-cta')

            if (phoneNO) {
                phoneNO.href = `tel:${callTrackNumber}`
                const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
                const phoneFormat: any = String(callTrackNumber)?.replace(/\D/g, '').match(reg);
                phoneNO.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
            }

            const mobile: any = document.querySelector('.header-cta-mobile .primary-btn')

            if (mobile) {
                mobile.href = `tel:${callTrackNumber}`

            }

            if (pathname) {
                localStorage.setItem('dbaName', pathname);
            }
            this.updateInputValue("local_weblocationId", franchiseWebLocationId);
            this.updateInputValue("weblocationId", franchiseWebLocationId);
            this.updateInputValue("full_dbaName", pathname);
            this.updateInputValue("dbaName", pathname);

            const address = document.querySelector('.header-wrapper .address')
            const desc = document.querySelector('.header-wrapper .description')

            if (locationDoingBusinessAs) {
                updateHeaderContent(locationDoingBusinessAs);
            }

            if (address && desc) {
                address.innerHTML = locationDoingBusinessAs
                desc.innerHTML = 'Locally Owned and Operated'
            }
            const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement)?.value;
            sessionStorage.setItem("manualEnteredAddress", addressEntered + ", " + zipCode);
            sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
            sessionStorage.setItem("RL_DBAName", locationDoingBusinessAs);
            new Footer();
        }
    }
    private async franchiseLookupAddressApi(zipCode: any) {
        const url = apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode));
        try{
            const result = await getCachedAPIResponse(url, apiCaching.LocateLocationApiWithRoundRobinTrue);
            if (result.length > 0) {
                const { franchiseWebLocationId, doingBusinessAs, licenseNumber, franchiseId } = result[0];
                localStorage.setItem("franchiseWebLocationId", franchiseWebLocationId);
                sessionStorage.setItem('franchiseId', result[0].franchiseId);
                localStorage.setItem('doingBusinessAs', doingBusinessAs);
                localStorage.setItem('weblocationId', franchiseWebLocationId);
                hms_booking.franchiseWebLocationId = franchiseWebLocationId;
                hms_booking.doingBusinessAs = doingBusinessAs;
                hms_booking.licenseNumber = licenseNumber;
                hms_booking.franchiseId = franchiseId;
                sessionStorage.setItem("hms_booking", JSON.stringify(hms_booking));
                (async () => {
                    await this.getDetails(franchiseWebLocationId);
                    const locationUrl = result[0].locationWebsiteUrl?.replace(/\/$/, '') ?? '';
                    const splitUrlArr = locationUrl.toLowerCase().split("/");
                    const pathname = splitUrlArr[splitUrlArr.length - 1];
                    if (pathname) {
                        hms_booking.pathname = pathname;
                        localStorage.setItem('dbaName', pathname);
                        sessionStorage.setItem("hms_hms_booking", JSON.stringify(hms_booking));
                    }

                    this.updateInputValue("local_weblocationId", franchiseWebLocationId);
                    this.updateInputValue("weblocationId", franchiseWebLocationId);
                    this.updateInputValue("full_dbaName", pathname);
                    this.updateInputValue("dbaName", pathname);

                    const address = document.querySelector('.header-wrapper .address')
                    const desc = document.querySelector('.header-wrapper .description')

                    if (doingBusinessAs) {
                        updateHeaderContent(doingBusinessAs);
                    }

                    if (address && desc) {
                        address.innerHTML = doingBusinessAs
                        desc.innerHTML = 'Locally Owned and Operated'
                    }
                    const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement)?.value;
                    sessionStorage.setItem("manualEnteredAddress", addressEntered + ", " + zipCode);
                    sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
                    sessionStorage.setItem("RL_DBAName", doingBusinessAs);


                    /********* Triggering GTM event ********/

                    try {

                        const leadObj = {
                            event: 'custEv_LocalDataLayer',
                            locationID: franchiseWebLocationId,
                            locationDBA: doingBusinessAs,
                            locationZipcode: zipCode,
                            licenseNumber: licenseNumber
                        }
                        gtmCustomEventLeadFlow(leadObj);

                    } catch (error) {
                        console.log("An error occurred in gtmCustomEventLeadFlow: ", error);
                    }



                    try {

                        new Step2();
                        const ft_footer = new Footer();
                    } catch (error) {

                    }

                })();
                setTimeout(() => {
                    stopLoader();
                }, 0)
            }
            else {
                stopLoader()
                document.getElementById("step1-title")!.style.display = "none";
                document.getElementById("step1-subtitle")!.style.display = "block";
                document.getElementById("step1-subtitle")!.classList.remove('hidden');
            }
        }catch(error:any){
            if(error?.message){
                // Check if the error message is 'Full address is needed to narrow down results'
                if (error.message === 'Full address is needed to narrow down results'){
                    const zipCode: any = (document.getElementById('zip-code') as HTMLInputElement).value;
                    const address: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
                    const completeAddress = `${address}, ${zipCode}`;
                    this.franchiseLookupAddressApi(completeAddress);
                }
                else{
                    stopLoader();
                    this.OpenErrorModal();
                }
            }
            else {
                stopLoader();
                this.OpenErrorModal();
            }
        }
    }
    
    public nextStep2(resp: any) {
        const zipModalDiv = document.querySelector('.zip-modal') as HTMLInputElement;
        if (zipModalDiv) {
            zipModalDiv.style.display = "none";
        }
        const formSectionDiv = document.getElementById('form-section') as HTMLElement;
        if (formSectionDiv) {
            formSectionDiv.style.display = "block";
        }

        const dbaDetails = document.querySelector('.brand-license .serving') as HTMLInputElement;
        if (dbaDetails) {
            dbaDetails.innerText = `${hms_booking?.doingBusinessAs ? hms_booking?.doingBusinessAs : resp?.locationDoingBusinessAs} `;
            const licenseNumber: any = document.querySelector('.brand-license .license') as HTMLElement;
            licenseNumber.innerText = hms_booking?.licenseNumber ? hms_booking?.licenseNumber : resp?.locationDoingBusinessAs;
        }
        const formNext = document.querySelectorAll('#form-section .contact-us-section')
        formNext?.forEach((item) => {
            item.classList.remove('form-step-active')
        })
        const progress = document.querySelectorAll('.progressbar .progress-step')
        const progressBar: any = document.getElementById('progress')
        const form1 = formNext[0]
        //const form2 = formNext[1]
        const progress1 = progress[0]
        //const progress2 = progress[1]
        progressBar.style.width = "0%"
        form1.classList.add('form-step-active');
        //form2.classList.add('form-step-active')
        progress1.classList.add('progress-step-active')
        progress1.classList.add('progress-step-confirmed')
        //progress2.classList.add('progress-step-active')
        isLeadDataHms = false
    }
    private async getDetails(franchiseWeblocationId: any) {
        const url = `${apiConfig.GET_ATTRIBUTE_DATA}/${franchiseWeblocationId}`;
        try{
            const resp = await cachedAPIWrapper(url, apiCaching.FullAttributeResponse);
            let callTrackNumber: any = resp?.mainPhoneNumber;
            this.onlineScheduling = resp?.onlineScheduling;

            resp?.callTrackings.forEach((type: any) => {
                if (type.callTrackingTypeDescription == "WebsiteTracking") {
                    callTrackNumber = type.callTrackingNumber;
                    hms_booking.localPhoneNumber = callTrackNumber;
                    localStorage.setItem('localPhoneNumber', callTrackNumber);
                    sessionStorage.setItem("hms_booking", JSON.stringify(hms_booking));
                }
            });
            if (this.isLead) {
                const { franchiseWebLocationId, locationDoingBusinessAs, franchiseWebLocationMarketingId } = resp;
                localStorage.setItem("franchiseWebLocationId", franchiseWebLocationId);
                sessionStorage.setItem('franchiseId', '');
                localStorage.setItem('doingBusinessAs', locationDoingBusinessAs);
                localStorage.setItem('weblocationId', franchiseWebLocationId);
                hms_booking.franchiseWebLocationId = franchiseWebLocationId;
                hms_booking.doingBusinessAs = locationDoingBusinessAs;
                hms_booking.licenseNumber = franchiseWebLocationMarketingId;
                hms_booking.franchiseId = '';
                sessionStorage.setItem("hms_booking", JSON.stringify(hms_booking));
            }
            const phoneNO: any = document.querySelector('.header-wrapper .form-call-cta')

            if (phoneNO) {
                phoneNO.href = `tel:${callTrackNumber}`
                const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
                const phoneFormat: any = String(callTrackNumber)?.replace(/\D/g, '').match(reg);
                phoneNO.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
            }

            const mobile: any = document.querySelector('.header-cta-mobile .primary-btn')

            if (mobile) {
                mobile.href = `tel:${callTrackNumber}`

            }

            if (!resp?.onlineScheduling) {
                const progressContainer = document.querySelector('.progressbar')
                const progress = document.querySelectorAll('.progressbar .progress-step')
                const lastChild: any = progress[2]
                if (lastChild)
                    progressContainer?.removeChild(lastChild)
                const progressBar: any = document.getElementById('progress')
                progressBar.style.width = "100%"
                const formNext = document.querySelectorAll('#form-section .contact-us-section')
                const btnNext = document.querySelectorAll('.contact-us-section .btn-next')[1]
                const form1 = formNext[0]
                const form2 = formNext[1]
                const progress1 = progress[0]
                const progress2 = progress[1]
                form1.classList.remove('form-step-active')
                form2.classList.add('form-step-active')
                if (btnNext)
                    btnNext.innerHTML = 'Submit'
                progress1.classList.remove('progress-step-active')
                progress1.classList.add('progress-step-confirmed')
                progress2.classList.add('progress-step-active')
                isLeadDataHms = true
            }
            else {
                this.nextStep2(resp);
            }
            return resp;
        }
        catch{
            console.log("Error in GET_ATTRIBUTE_DATA in HMS step1")
        }
       
    }
    updateInputValue(inputId: string, value: string | number | null | undefined) {
        try {
            const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;

            if (hiddenInput && value !== null && value !== undefined) {
                hiddenInput.value = value.toString();
            }
        } catch (error) {
            console.error(`Error in updateInputValue function: ${error}`);
        }
    }

    CloseErrorModal() {
        const closeBtn = document.querySelector('.error-local-modal.error-modal-HMS .error-btn-close');
        const modalOpen = document.getElementById('error-modal-HMS') as HTMLElement;
        closeBtn?.addEventListener('click', () => {
            document.getElementById("modal-hms-system-error")!.style.display = "none";
            modalOpen?.classList.add('hidden');
        })
    }
    OpenErrorModal() {
        const errorModalHms = document.querySelector(".error-local-modal.error-modal-HMS");
        if (errorModalHms) {
            errorModalHms?.classList.remove('hidden');
            document.getElementById("modal-hms-system-error")!.style.display = "block";
        }
    }
    initBackBtn() {
        const btnBack = document.querySelector("#schedule-step .cancel-verification") as HTMLElement;
        btnBack?.addEventListener('click', () => {
            const formNext = document.querySelectorAll('#form-section .contact-us-section');
            formNext?.forEach((item) => {
                item.classList.remove('form-step-active');
            })
            if (!this.isLead) {
                const formSectionDiv = document.getElementById('form-section') as HTMLInputElement;
                formSectionDiv.style.display = "none";
                const zipModalDiv = document.querySelector('.zip-modal') as HTMLInputElement;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                zipModalDiv.style.display = "";
            } else {
                this.handleCancelBtnClick();
            }
        })
    }

}

document.addEventListener("DOMContentLoaded", () => {
    const conceptId: any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    if (conceptId === "25") {
       
        new ScheduleService();
        new Step3();
        new HMSStep4();
    }
});


