export const timeBlockInfoKeys: { [key: string]: string } = {
  conceptId: 'number',
  googleCalendarEventId: 'string',
  googleCalendarEmail: 'string',
  timeSlotTypeId: 'number',
  serviceSkuId: 'number',
  isEstimate: 'boolean',
  jobPromisedDate: 'string',
  isAvailable: 'boolean',
  isFranchiseInPOS: 'boolean',
  displayText: 'string',
  technicianExternalId: 'string',
  opalScheduleId: 'string',
  fsmSlotCode: 'string',
  franchiseId: 'number',
  jobPromisedEndDate: 'string',
  startMinutes: 'number',
  endMinutes: 'number',
  franchiseWebLocationCalendarId: 'number',
  technicianName: 'string',
  businessAssetId: 'number',
  duration: 'string',
  jobPromisedStartDateUTC: 'string',
  jobPromisedEndDateUTC: 'string',
  startDate: 'string',
};

export const regionalTaxKeys: { [key: string]: string } = {
  id: 'string',
  regionalTaxCode: 'string',
  taxRegionName: 'string',
  exemptFromWo: 'boolean',
  sequence: 'number',
  type: 'number',
  value: 'number',
  displayOnInvoice: 'boolean',
  taxAmount: 'number',
  taxRate: 'object',
  taxAuthority: 'object',
};

export const taxRatesKeys: { [key: string]: string } = {
  id: 'string',
  name: 'string',
  rateValue: 'number',
};

export const taxAuthoritiesKeys: { [key: string]: string } = {
  roundingCode: 'number',
  roundingDigitsQuantity: 'number',
};

export const hmsRoleInfosKeys: { [key: string]: string } = {
  hmsRoleType: 'string',
  hmsRoleKey: 'string',
  hmsRoleDetail: 'object',
  hmsPropertyDetail: 'object',
  hmsSelectedServiceDetail: 'object',
  hmsTaxDetail: 'object',
};

export const hmsRoleDetailKeys: { [key: string]: string } = {
  willOwnerOrAdultAttend: 'boolean',
  presentForInspection: 'object',
  buyerAgentFirstName: 'string',
  buyerAgentLastName: 'string',
  buyerAgentPhone: 'string',
  buyerAgentEmail: 'string',
  buyerNameOfAgency: 'string',
  buyerAgentReceiveTextMessages: 'boolean',
  sellerAgentFirstName: 'string',
  sellerAgentLastName: 'string',
  sellerAgentPhone: 'string',
  sellerAgentEmail: 'string',
  sellerNameOfAgency: 'string',
  sellerAgentReceiveTextMessages: 'boolean',
  buyerOrSellerAgent: 'string',
  clientFirstName: 'string',
  clientLastName: 'string',
  clientPhone: 'string',
  clientEmail: 'string',
  clientReceiveTextMessages: 'boolean',
  agentNameOfAgency: 'string',
};

export const propertyDetailsKeys: { [key: string]: string } = {
  builtYear: 'number',
  squareFeet: 'number',
  typeOfFoundation: 'string',
  numberOfBedrooms: 'number',
  numberOfBathrooms: 'number',
  numberOfKitchens: 'number',
  isPoolAvailable: 'boolean',
  utilitiesOn: 'boolean',
  propertyAccess: 'string',
  hearAboutHouseMaster: 'string',
  explainOther: 'string',
  isPropertyOccupied: 'boolean',
};

export const packagePayloadKeys: { [key: string]: string } = {
  hmsBundleServiceId: 'string',
  hmsBundleServiceName: 'string',
  hmsBundleServiceDescription: 'string',
  hmsBundleServiceProductNumber: 'string',
  hmsBundleServiceCreatedBy: 'string',
  hmsBundleServicePrice: 'number',
  hmsBundleItemTax: 'number',
  isTaxable: 'boolean',
};

export const servicePayloadKeys: { [key: string]: string } = {
  hmsServiceId: 'string',
  hmsServiceName: 'string',
  hmsServiceDescription: 'string',
  hmsServiceProductNumber: 'string',
  hmsServiceCreatedBy: 'string',
  hmsPrice: 'number',
  hmsItemTax: 'number',
  isTaxable: 'boolean',
};

export const hmsSelectedServiceDetailsKeys: { [key: string]: string } = {
  hmsServiceBundles: 'object',
  hmsAvailableServices: 'object',
  hmsServiceSubTotal: 'number',
  hmsServiceTotal: 'number',
};

export const hmsTaxDetailsKeys: { [key: string]: string } = {
  regionalTaxes: 'object',
};

export const bookingPayloadModelKeys: { [key: string]: string } = {
  franchiseId: 'number',
  hasSameDayScheduling: 'boolean',
  isBetaTester: 'boolean',
  comments: 'string',
  isLocalized: 'boolean',
  conceptId: 'number',
  useFranchiseProfileOptInForPOSScheduling: 'boolean',
  isFranchiseOptedIntoPOSScheduling: 'boolean',
  c4ChannelTypeId: 'number',
  conceptCalledId: 'number',
  c4CallQueueId: 'number',
  conceptName: 'string',
  conceptCode: 'string',
  c4LeadsEnabled: 'boolean',
  canceledJobInformation: 'string',
  vendorId: 'number',
  vendorLeadId: 'number',
  nationalLeadId: 'string',
  isTest: 'boolean',
  vendorName: 'string',
  webLocationId: 'number',
  correlationId: 'string',
  c4CallCenterId: 'number',
  dayTimePhoneNumber: 'string',
  zipCode: 'string',
  billingAddress1: 'string',
  billingAddress2: 'string',
  billingCity: 'string',
  billingState: 'string',
  billingZip: 'string',
  fee: 'number',
  floor: 'string',
  isPreapptCallReqd: 'boolean',
  isNewCaNCall: 'boolean',
  note: 'string',
  firstName: 'string',
  lastName: 'string',
  email: 'string',
  phone: 'string',
  phone2: 'string',
  phone3: 'string',
  phoneType: 'string',
  phoneType2: 'string',
  phoneType3: 'string',
  postalCode: 'string',
  city: 'string',
  state: 'string',
  country: 'string',
  address: 'string',
  address2: 'string',
  leadSource: 'string',
  licenseNumber: 'string',
  webLocationAccountCode: 'string',
  leadOrigin: 'string',
  campaignName: 'string',
  referringURL: 'string',
  disposition: 'string',
  agentEmail: 'string',
  callType: 'string',
  preferredCommunicationType: 'string',
  emailOptOut: 'boolean',
  tagName: 'string',
  signUpForUpdates: 'boolean',
  latitude: 'number',
  longitude: 'number',
  locationType: 'string',
  isGoogleAddress: 'boolean',
  googleCalendarEventId: 'string',
  googleCalendarEmail: 'string',
  serviceText: 'string',
  serviceID: 'number',
  isCoreWebhook: 'boolean',
  warranty: 'boolean',
  timeSlotTypeId: 'number',
  makeId: 'number',
  leadSourceId: 'number',
  secondaryLeadSourceId: 'number',
  serviceTypeProblem: 'string',
  overrideJobPromisedDate: 'string',
  serviceSkuId: 'number',
  serviceSkuIds: 'object',
  isEstimate: 'boolean',
  estimateFrequency: 'string',
  estimateHouseStyle: 'string',
  estimateSquareFeet: 'string',
  estimateBedrooms: 'string',
  estimateBathrooms: 'string',
  estimatePets: 'string',
  ccFirstName: 'string',
  ccLastName: 'string',
  ccType: 'string',
  ccSuffix: 'string',
  ccToken: 'string',
  ccExpirationDate: 'string',
  ccComment: 'string',
  isLeadOnly: 'boolean',
  scheduleID: 'string',
  timeBlock: 'object',
  overrideCSRName: 'string',
  overrideComment: 'string',
  syncTenantName: 'string',
  isEmergencyCall: 'boolean',
  callId: 'string',
  isFranchiseInPOS: 'boolean',
  testOfflinePOS: 'string',
  ranLoginTypeID: 'number',
  ranUserID: 'string',
  referralFirstName: 'string',
  referralLastName: 'string',
  referralZipCode: 'string',
  referralPhone: 'string',
  referralEmail: 'string',
  referralReason: 'string',
  currentPage: 'string',
  emailDocumentName: 'string',
  emailDocumentUrl: 'string',
  optIn: 'boolean',
  isForm: 'boolean',
  customerId: 'string',
  isFsmUpdatedCustomer: 'boolean',
  referralTypeId: 'string',
  referralType: 'string',
  referralSourceId: 'string',
  referralSource: 'string',
  leadId: 'string',
  customerType: 'string',
  neighborlyAccountId: 'number',
  neighborlyLeadSourceId: 'number',
  fileUrls: 'object',
  jobFrequency: 'string',
  jobFrequencyDetail: 'string',
  year: 'number',
  make: 'string',
  model: 'string',
  bodyStyle: 'string',
  vin: 'string',
  licensePlateNumber: 'string',
  windshieldRepair: 'string',
  headlightRestoration: 'string',
  serviceRequested: 'object',
  leadAdditionalNotes: 'object',
  formOfPayment: 'string',
  insuranceCompany: 'string',
  deductibleAmount: 'number',
  policyNumber: 'string',
  imageUrls: 'object',
  howDidYouHearAboutUs: 'string',
  bookingId: 'number',
  otherText: 'string',
  advantagePlanMember: 'boolean',
  firstTimeCustomer: 'boolean',
  businessName: 'string',
  serviceTypeID: 'number',
  priority: 'string',
  jobPromiseEndTime: 'object',
  legacyLeadTableID: 'number',
  legacyLeadTableID2: 'number',
  scheduleDate: 'string',
  packageType: 'string',
  sendToSyncSourceTypeId: 'number',
  timeZoneId: 'number',
  timeZoneInfoId: 'string',
  bypassTenantCheckOnAvailabilityAndBooking: 'boolean',
  opalScheduleId: 'string',
  vendorRouting: 'number',
  webhookLeadEventId: 'number',
  adjustedPOSSource: 'string',
  preferredContactMethod: 'string',
  message: 'string',
  insuranceCompanyPhoneNumber: 'string',
  insuranceDateOfLoss: 'string',
  claimNetworkReferralNumber: 'string',
  insuranceCauseOfLoss: 'string',
  agencyAgentName: 'string',
  agencyDirectPhoneNumber: 'string',
  agencyEmailAddress: 'string',
  agencyName: 'string',
  agencyAddress: 'string',
  agencyCity: 'string',
  agencyState: 'string',
  agencyZipCode: 'string',
  hmsAppointmentType: 'number',
  hmsParticularInspector: 'string',
  hmsRoleInfo: 'object',
  scheduledTime: 'string',
  callCenterBrandEmail: 'string',
  calendarTypeId: 'number',
  syncCampaignId: 'number',
  serviceType: 'string',
  estimateNote: 'string',
  isOverriding: 'boolean'
};
export const leadAdditionalNotesKeys: { [key: string]: string } = {
  leadAdditionalNoteLabel: 'string',
  leadAdditionalNoteText: 'string',
};

export const imageUrlsKeys: { [key: string]: string } = {
  imageUrl: 'string',
};


export const hmsServiceBundleKeys: { [key: string]: string } = {
  hmsBundleServiceId: 'string',
  hmsBundleServiceName: 'string',
  hmsBundleServiceDescription: 'string',
  hmsBundleServiceProductNumber: 'string',
  hmsBundleServiceCreatedBy: 'string',
  hmsBundleServicePrice: 'number',
  hmsBundleItemTax: 'number',
  isTaxable: 'boolean',
};

export const hmsAvailableServiceKeys: { [key: string]: string } = {
  hmsServiceId: 'string',
  hmsServiceName: 'string',
  hmsServiceDescription: 'string',
  hmsServiceProductNumber: 'string',
  hmsServiceCreatedBy: 'string',
  hmsPrice: 'number',
  hmsItemTax: 'number',
  isTaxable: 'boolean',
};

export const serviceRequestedKeys: { [key: string]: string } = {
  // Define the fields within serviceRequested if applicable
};

export const jobPromiseEndTimeKeys: { [key: string]: string } = {
  ticks: 'number',
  days: 'number',
  hours: 'number',
  milliseconds: 'number',
  minutes: 'number',
  seconds: 'number',
  totalDays: 'number',
  totalHours: 'number',
  totalMilliseconds: 'number',
  totalMinutes: 'number',
  totalSeconds: 'number',
};

