import { handleInput, handleCheckboxInput, validateForm, validateDropdown, validateSqrft } from "./form-validation";
import { bookingPayload, isTestFcn } from "./booking-payload";
import { callBookingApi, confirmMlyLeadSourceAPI, determineFlowType, getFranchiseDetails, setDefaultLeadSource, calcEstimate, contactUsEndpoint, payloadHelper } from "./callBookingApi";
import { startLoader, stopLoader } from "../util/loader";
import { calendarFlowMLY } from "./mly-calendar-flow";
import { debounce, getBrandDetailsFromBrandJson, getCampaignName, getCountryCodefromAssetpath, getVendorDetails, updateHeaderContent } from "../util/share";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { NearbyLocations } from "../util/nearbyLocations";
import { discoverSuggestions, isValidZip, discoveryAPI, getAddressDetails } from "../util/discovery";
import { createModal } from "../util/createModal";
import { isTouchDevice } from "../util/device-detection";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";

type ExceptType = HTMLButtonElement | HTMLDivElement | null; // Add other types as needed
export class CleaningType {
    public cleaningType: NodeListOf<Element>;
    public cleaningFreq: NodeListOf<Element>;
    constructor() {
        this.cleaningType = document.getElementsByName('mly-cleaning-radio') as NodeListOf<Element>;
        this.cleaningFreq = document.querySelectorAll('input[name*="frequency-cleaning"]');
    }
    getCleaningType(): string {
        const radioButtons = this.cleaningType;
        if(radioButtons){
            for (const radioButton of radioButtons) {
                if ((radioButton as HTMLInputElement).checked) {
                    const labelElement = document.querySelector(`label[for="${radioButton.id}"]`) as HTMLElement;
                    const label = labelElement ? labelElement.innerHTML.trim() : '' ;
                    return label;
                }
            }
        }
        return '';
    }
    getCleaningFreq(): string {
        const selectedType = this.getCleaningType().toLowerCase();
        if(selectedType){
            const val: any = { "residential": "frequency-cleaning", "light commercial": "commercial-frequency-cleaning" };
            const radioButtons = this.cleaningFreq;
            if(radioButtons){
                for (const radioButton of radioButtons) {
                    if (radioButton.id.includes(val[selectedType] as string) && (radioButton as HTMLInputElement).checked) {
                        const labelElement = document.querySelector(`label[for="${radioButton.id}"]`) as HTMLElement;
                        const label = labelElement ? labelElement.innerHTML.trim() : '';
                        return label;
                    }
                }
            }
        }
        return '';

    }
    toggleCleaningDiv() {
        const selectedType = this.getCleaningType().toLowerCase();
        const selectedFrequency = this.getCleaningFreq().toLowerCase();
        const residentialDiv = document.getElementById('cleaningResidential') as HTMLElement;
        const commercialDiv = document.getElementById('cleaningCommercial') as HTMLElement;
        const oneTimeNextBtn = document.getElementById('one-next-btn') as HTMLButtonElement;
        const recurringNextBtn = document.getElementById("recurring-next-btn") as HTMLButtonElement;
        const mobOTBtn = document.querySelector('.btn-container-mobile #one-next-btn') as HTMLButtonElement;
        const mobRecurBtn = document.querySelector(".btn-container-mobile #recurring-next-btn") as HTMLButtonElement;
        const oneTimeDiv = document.querySelector('.one-time-frequency-only') as HTMLElement;
        const commercialRecurringDiv = document.getElementById('commercialRecurring') as HTMLElement;
        const commercialOTCDiv = document.getElementById('commercialOneTime') as HTMLElement;
        const contractDisclaimer = document.querySelector('p.contract-cancel-text') as HTMLElement;

        // Contract disclaimers // 
        const resiRecurring = document.getElementById('resi-recurring') as HTMLElement;
        const commRecurring = document.getElementById('comm-recurring') as HTMLElement;
        const resiOTMove = document.getElementById('resi-one-time-move') as HTMLElement;
        const resiOTone = document.getElementById('resi-one-time-one') as HTMLElement;
        const commOTMoveY = document.getElementById('comm-one-time-move-yes') as HTMLElement;
        const commOTMoveN = document.getElementById('comm-one-time-move-no') as HTMLElement;
        const arrayBanners = [resiRecurring, commRecurring, resiOTMove, resiOTone, commOTMoveY, commOTMoveN];
        const OTelements = document.querySelectorAll('input[name*="one-time-radio"]');
        const OTcomms = document.querySelectorAll('input[name*="move-in-out-radio"]');
        let OTRText, OTComm = '';
        for (const elem of OTelements) {
            if ((elem as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${elem.id}"]`) as HTMLElement;
                OTRText = labelElement.innerText;
            }
        }
        for (const elem of OTcomms) {
            if ((elem as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${elem.id}"]`) as HTMLElement;
                OTComm = labelElement.innerText;
            }
        }
        // Content disclaimers // 

        if (selectedType.includes('residential')) {
            residentialDiv.style.display = 'flex';
            commercialDiv.style.display = 'none';
            if (selectedFrequency.includes('recurring')) {
                recurringNextBtn.style.display = "flex";
                oneTimeNextBtn.style.display = "none";
                mobOTBtn.style.display = 'none';
                mobRecurBtn.style.display = 'flex';
                oneTimeDiv.style.display = "none";
                contractDisclaimer.style.display = 'flex';
                this.toggleCleaningBanners(arrayBanners, 0);
            }
            else {
                recurringNextBtn.style.display = "none";
                oneTimeNextBtn.style.display = "flex";
                mobOTBtn.style.display = 'flex';
                mobRecurBtn.style.display = 'none';
                oneTimeDiv.style.display = "flex";
                contractDisclaimer.style.display = 'none';
                if (OTRText?.includes('Move In')) {
                    this.toggleCleaningBanners(arrayBanners, 2);
                }
                else {
                    this.toggleCleaningBanners(arrayBanners, 3);
                }
            }
        } else {
            residentialDiv.style.display = 'none';
            commercialDiv.style.display = 'flex';
            if (selectedFrequency.includes('recurring')) {
                recurringNextBtn.style.display = "flex";
                oneTimeNextBtn.style.display = "none";
                mobOTBtn.style.display = 'none';
                mobRecurBtn.style.display = 'flex';
                commercialRecurringDiv.style.display = "flex";
                commercialOTCDiv.style.display = "none";
                contractDisclaimer.style.display = 'flex';
                this.toggleCleaningBanners(arrayBanners, 1);
            }
            else {
                recurringNextBtn.style.display = "none";
                oneTimeNextBtn.style.display = "flex";
                mobOTBtn.style.display = 'flex';
                mobRecurBtn.style.display = 'none';
                commercialRecurringDiv.style.display = "none";
                commercialOTCDiv.style.display = "flex";
                contractDisclaimer.style.display = 'none';
                if (OTComm.includes('Yes')) {
                    this.toggleCleaningBanners(arrayBanners, 4);
                }
                else {
                    this.toggleCleaningBanners(arrayBanners, 5);
                }
            }
        }
    }
    toggleCleaningBanners(arrayBanners: any[], idx: number) {
        arrayBanners?.forEach((arr) => {
            arr.style.display = 'none';
        });
        arrayBanners[idx].style.display = 'block';
    }

}

function helperSelectFcn(selectBtn: any) {
    if (selectBtn) {
        selectBtn.style.backgroundColor = "var(--primary-500)";
        selectBtn.style.color = "var(--white)";
        selectBtn.style.whiteSpace = "nowrap";
        selectBtn.style.overflow = "hidden";
        selectBtn.style.borderRight = "40px solid var(--primary-500)";
        
    }

}

export class MollyFlow {
    private firstName: HTMLInputElement;
    private lastName: HTMLInputElement;
    private email: HTMLInputElement;
    private phoneNumber: HTMLInputElement;
    private address: HTMLInputElement;
    private address2: HTMLInputElement;
    private zipCode: HTMLInputElement;
    private preferMethods: NodeListOf<Element>;
    public cleaningType: CleaningType;
    private officeSqrft: HTMLInputElement;
    private homeSqrft: HTMLInputElement;
    private weeklyFreq: NodeListOf<Element>;
    private bathrooms: HTMLSelectElement;
    private offices: HTMLSelectElement;
    private bedrooms: HTMLSelectElement;
    private resBathrooms: HTMLSelectElement;
    private leadSourceBtn: HTMLButtonElement;
    public bookingPayload: BookingPayloadModel;
    private counter: any;
    constructor() {
        if(isTouchDevice())
            createModal('.scheduler-main');
        sessionStorage.removeItem('PriceNote');
        this.firstName = document.getElementById('mly-user-fname') as HTMLInputElement;
        this.lastName = document.getElementById('mly-user-lname') as HTMLInputElement;
        this.email = document.getElementById('mly-user-email') as HTMLInputElement;
        this.phoneNumber = document.getElementById('mly-user-number') as HTMLInputElement;
        this.phoneNumber.maxLength = 14;
        this.address = document.getElementById('mly-user-address') as HTMLInputElement;
        this.address2 = document.getElementById('mly-user-address-2') as HTMLInputElement;
        this.zipCode = document.getElementById('mly-user-zip-code') as HTMLInputElement;

        this.preferMethods = document.querySelectorAll('input[name*="mly-contact"]');
        this.leadSourceBtn = document.getElementById('mly-how-about-us')?.parentElement?.querySelector('button') as HTMLButtonElement;
        this.initLeadSources();
        this.cleaningType = new CleaningType();
        this.officeSqrft = document.getElementById('office-detail-sqrft') as HTMLInputElement;
        this.officeSqrft.maxLength = 5;
        this.homeSqrft = document.getElementById('home-detail-sqrft') as HTMLInputElement;
        this.homeSqrft.maxLength = 5;
        this.weeklyFreq = document.getElementsByName('frequency-week-radio') as NodeListOf<Element>;
        this.bathrooms = document.getElementById('office-detail-bathroom') as HTMLSelectElement;
        this.offices = document.getElementById('office-detail-offices') as HTMLSelectElement;
        this.resBathrooms = document.getElementById('home-detail-bathroom') as HTMLSelectElement;
        this.bedrooms = document.getElementById('home-detail-bedroom') as HTMLSelectElement;
        this.populateDropdowns(this.offices.id);
        this.populateDropdowns(this.bathrooms.id);
        this.populateDropdowns(this.resBathrooms.id);
        this.populateDropdowns(this.bedrooms.id);
        this.initZipCode();
        this.initListeners();
        this.loadHereMapScripts();
        this.bookingPayload = bookingPayload;
        this.bookingPayload.isTest = isTestFcn();
        getVendorDetails().then((obj:any)=>{
            this.bookingPayload.vendorId = Number(obj.vendorId);
            this.bookingPayload.vendorName = obj.vendorName;
        }).catch((err:any)=>{
            // Setting default vendor ID and name
        });
        this.bookingPayload.country = getCountryCodefromAssetpath();
        this.counter = 0;
        const cn = sessionStorage.getItem('campaignName');
        sessionStorage.setItem("bookingLeadSent","false");
        if(cn){
            this.bookingPayload.campaignName = cn;
        }
        else{
            this.bookingPayload.campaignName = getCampaignName();
        }
        
        setDefaultLeadSource()
        .then((obj:any)=>{
            this.bookingPayload.leadSource = obj.LeadSource;
            this.bookingPayload.leadSourceId = Number(obj.LeadSourceID);
        })
        .catch((err:any)=>{
            console.error("Error setting default Lead Source");
        });
        
    }

    initZipCode(){
        const zipcode = sessionStorage.getItem('sAddressParam') as string;
        if(isValidZip(zipcode)){
            this.zipCode.value = zipcode;
        }
        sessionStorage.removeItem('zipcode');
        sessionStorage.removeItem('noZipNote');
    }
    loadHereMapScripts(){
        const fcn = (url:any, id:any) => {
            const elem = document.createElement("script");
            elem.async = false;
            elem.src = url;
            elem.id = id;
            document.head.appendChild(elem);
        }
        fcn('https://js.api.here.com/v3/3.1/mapsjs-core.js', 'mapsjs-core-js');
        fcn('https://js.api.here.com/v3/3.1/mapsjs-ui.js', 'mapsjs-ui-js');
    }

    initListeners(): any {
        this.firstName.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.firstName, false) });
        this.lastName.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.lastName, false) });
        this.email.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.email, false) });
        this.phoneNumber.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.phoneNumber, false) });
        this.address.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.address, false) });
        this.address.addEventListener('input', this.setAutoSuggested.bind(this));
        this.address2.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.address2, false) });
        this.zipCode.addEventListener('input', (e) => { e.preventDefault(); this.counter = 0; handleInput(this.zipCode, false) });
        this.preferMethods.forEach((chkElem) => {
            chkElem.addEventListener('input', (e) => { e.preventDefault(); handleCheckboxInput(this.preferMethods) });
        });
        if (this.homeSqrft) {
            this.homeSqrft.addEventListener('input', (e) => { e.preventDefault(); validateSqrft(); });
        }
        if (this.officeSqrft) {
            this.officeSqrft.addEventListener('input', (e) => { e.preventDefault(); validateSqrft(); });
        }
        
    }

    private readonly debouncedSearch = debounce((zipCode: string, address: string) => {
        discoverSuggestions(zipCode, address); 
    }, 500);

    private setAutoSuggested = async (e: any) => {

        e.preventDefault()
        sessionStorage.setItem("suggestion", "false");
        this.address.removeAttribute("data-suggestion");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        this.debouncedSearch(this.zipCode, this.address);
    }

    preferredCommunicationMethod(): any {
        const preferMethods = this.preferMethods;
        let selectedPreferences: any = [];
        if (preferMethods) {
            preferMethods.forEach((el: any) => {
                if (el.type == "checkbox" && el.checked) {
                    let out:string = '';
                    if(el.id.toLowerCase().includes('text')){
                        out = 'Text';
                    }
                    else if(el.id.toLowerCase().includes('phone')){
                        out = 'Phone';
                    }
                    else if(el.id.toLowerCase().includes('mail')){
                        out = 'Email';
                    }
                    selectedPreferences.push(out);
                }
            })
        }
        sessionStorage.setItem('PreferredCommunicationText', selectedPreferences.join(', '));
        return selectedPreferences.join(', ');
    }

    initLeadSources(): any {
        const refId = 'mly-how-about-us';
        const referenceDetails = document.getElementById(refId) as HTMLSelectElement;
        if (referenceDetails) {
            const optionsDiv = referenceDetails.parentElement?.querySelector('div.select-items');
            const selectBtn = referenceDetails.parentElement?.querySelector('button') as HTMLButtonElement;
            
            confirmMlyLeadSourceAPI()
                .then((endpoint) => {
                    let self = this;
                    getCachedAPIResponse(endpoint)
                        .then((data: any) => {
                            data = data?.filter((item: any) => item.leadSourceName !== "Undefined" && item.isActive);
                            data.forEach((element: any) => {
                                const d = document.createElement('button');
                                const val = element.leadSourceId ? element.leadSourceId : element.syncGenericLeadSourceId ? element.syncGenericLeadSourceId : "";
                                d.setAttribute('data-type-lead-source-id', val);
                                d.setAttribute('data-type-lead-source-name', element.leadSourceName);
                                d.textContent = element.leadSourceName;
                                d.addEventListener("click", (e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    helperSelectFcn(selectBtn);
                                    optionsDiv?.classList.add("select-hide");
                                    if (selectBtn) {
                                        selectBtn.textContent = d.getAttribute('data-type-lead-source-name');
                                        selectBtn.value = d.getAttribute('data-type-lead-source-id') as string;
                                        selectBtn.classList.remove("select-arrow-active");
                                    }
                                    validateDropdown(refId);
                                });
                                optionsDiv?.appendChild(d);
                            });
                            selectBtn?.addEventListener("click", function (e: MouseEvent) {
                                e.stopPropagation();
                                self.closeAllSelect(this);
                                optionsDiv?.classList.toggle('select-hide');
                                selectBtn.classList.toggle('select-arrow-active');
                                if (!this.classList.contains("select-arrow-active") && selectBtn?.nextElementSibling) {
                                    (selectBtn.nextElementSibling as HTMLDivElement)?.classList.add("select-hide");
                                }
                                document.addEventListener('keydown', (event) => {
                                    if (event.key === 'Escape') {
                                        optionsDiv?.classList.add("select-hide");
                                        selectBtn.classList.remove("select-arrow-active");
                                    }
                                });
                                document.addEventListener('click', (event) => {
                                    optionsDiv?.classList.add("select-hide");
                                    selectBtn.classList.remove("select-arrow-active");
                                });

                                helperSelectFcn(selectBtn);
                                
                            });

                        })
                        .catch((error: any) => {
                            console.log('Could not get lead sources in CLEO', error);
                        });
                })
                .catch((err: any) => {
                    throw err;
                });

        }

    }
    getRadioBtnVal(id: string): string {
        const radioButtons = document.getElementsByName(id) as NodeListOf<Element>;
        for (const radioButton of radioButtons) {
            if ((radioButton as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${radioButton.id}"]`) as HTMLElement;
                const label = labelElement.innerText;
                return label;
            }
        }
        return '';
    }

    closeAllSelect(except: ExceptType) {
        const allSelectItems: NodeListOf<HTMLDivElement> = document.querySelectorAll(".mly-lead-started-form .select-items.slim-scroll");
        const allSelectButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".mly-lead-started-form .select-selected");
        allSelectItems.forEach((item, index) => {
            if (except !== allSelectButtons[index]) {
                item.classList.add("select-hide");
                allSelectButtons[index]?.classList?.remove("select-arrow-active");
                if (allSelectButtons[index]?.nextElementSibling) {
                    allSelectButtons[index]?.nextElementSibling?.classList.add("select-hide");
                }
            }

        });


    }
    populateDropdowns(id: string) {
        const selectTag = document.getElementById(id) as HTMLSelectElement;
        if (selectTag) {
            const optionsDiv = selectTag.parentElement?.querySelector('div.select-items');
            const selectBtn = selectTag.parentElement?.querySelector('button') as HTMLButtonElement;
            if (optionsDiv) {
                optionsDiv.innerHTML = '';
            }
            const optionsArray = selectTag?.querySelectorAll("option") as any;
            let self = this;
            optionsArray.forEach((opt: any) => {
                if(!opt.innerText.toLowerCase().includes("select")){
                    const d = document.createElement('button');
                    d.textContent = opt?.value;
                    d.value = opt?.value;
                    d.addEventListener("click", (e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (selectBtn) {
                            selectBtn.textContent = d.textContent;
                            selectBtn.value = d.value;
                            selectTag.setAttribute('selected-value', d.value);
                            helperSelectFcn(selectBtn);
                            optionsDiv?.classList.add("select-hide");
                            selectBtn.classList.remove("select-arrow-active");
                            validateDropdown(id);
                        }
                    });
                optionsDiv?.appendChild(d);
                }
                
            });
            selectBtn?.addEventListener("click", function (e: MouseEvent) {
                e.preventDefault();
                e.stopPropagation();
                self.closeAllSelect(this);
                optionsDiv?.classList.toggle('select-hide');
                this.classList.toggle("select-arrow-active");

                if (!this.classList.contains("select-arrow-active")) {
                    if (selectBtn?.nextElementSibling) {
                        (selectBtn.nextElementSibling as HTMLDivElement)?.classList.add("select-hide");
                    }
                }

                document.addEventListener('keydown', (event) => {
                    if (event.key === 'Escape') {
                        optionsDiv?.classList.add("select-hide");
                        selectBtn.classList.remove("select-arrow-active");
                    }
                });
                document.addEventListener('click', (event) => {
                    optionsDiv?.classList.add("select-hide");
                    selectBtn.classList.remove("select-arrow-active");
                });

                helperSelectFcn(selectBtn);
            });
        }

    }

    readForm() {
        const suggestAddr = sessionStorage.getItem('suggestedAddress') as string;
        const suggestFlag = sessionStorage.getItem("suggestion");
        this.bookingPayload.address = this.address.value;
        if(suggestAddr && suggestFlag){
            this.bookingPayload.city = JSON.parse(suggestAddr).city;
            this.bookingPayload.state = JSON.parse(suggestAddr).stateCode;
            this.bookingPayload.address = JSON.parse(suggestAddr).addr1;
        }
        this.bookingPayload.firstName = this.firstName.value;
        this.bookingPayload.lastName = this.lastName.value;
        this.bookingPayload.email = this.email.value;
        this.bookingPayload.address2 = this.address2.value ? this.address2.value : '';
        this.bookingPayload.phone = this.phoneNumber.value;
        this.bookingPayload.zipCode = this.zipCode.value;
        this.bookingPayload.postalCode = this.bookingPayload.zipCode;
        
        this.bookingPayload.preferredCommunicationType = this.preferredCommunicationMethod();
        if (!this.leadSourceBtn?.textContent?.toLowerCase().includes("select") && this.leadSourceBtn) {
            this.bookingPayload.leadSource = this.leadSourceBtn.textContent as string;
            this.bookingPayload.leadSourceId = Number(this.leadSourceBtn.value);
        }
        const cleanType = this.cleaningType.getCleaningType().toLowerCase();
        const cleanFreq = this.cleaningType.getCleaningFreq().toLowerCase();
        sessionStorage.setItem('CustomerType',cleanType);
        sessionStorage.setItem('JobFrequency',cleanFreq);
        this.flushPayloadprops();
    }
    readResidentialForm() {
        this.bookingPayload.customerType = "Residential";
        if (this.cleaningType.getCleaningFreq().toLowerCase().includes('one-time')) {
            this.bookingPayload.jobFrequency = 'One-time';
            if(this.getRadioBtnVal('one-time-radio').toLowerCase().includes("move in/move out")){
                this.bookingPayload.jobFrequencyDetail = 'Move In or Move Out Clean';
            }
            else{
                this.bookingPayload.jobFrequencyDetail = 'Not a Move In or Move Out Clean';
            }
            this.bookingPayload.estimateBathrooms = this.resBathrooms.getAttribute('selected-value') as string;
            this.bookingPayload.estimateBedrooms = this.bedrooms.getAttribute('selected-value') as string;
            this.bookingPayload.estimateSquareFeet = this.homeSqrft.value;
        }
        else {
            this.bookingPayload.jobFrequency = 'Recurring';
        }
        sessionStorage.setItem('JobFrequency',this.bookingPayload.jobFrequency);

    }
    flushPayloadprops(){
        this.bookingPayload.estimateBathrooms = '';
        this.bookingPayload.estimateSquareFeet = '';
        this.bookingPayload.estimateBedrooms = '';
        this.bookingPayload.jobFrequencyDetail = '';
    }
    readCommercialForm() {
        this.bookingPayload.customerType = "Commercial";
        this.bookingPayload.isLeadOnly = true;
        this.bookingPayload.estimateBathrooms = this.bathrooms.getAttribute('selected-value') as string;
        this.bookingPayload.estimateBedrooms = this.offices.getAttribute('selected-value') as string;
        this.bookingPayload.estimateSquareFeet = this.officeSqrft.value;
        if (this.cleaningType.getCleaningFreq().toLowerCase().includes('recurring')) {
            this.bookingPayload.jobFrequency = 'Recurring';
        }
        else {
            this.bookingPayload.jobFrequency = 'One-time';
            if(this.getRadioBtnVal('move-in-out-radio').toLowerCase().includes("yes")){
                this.bookingPayload.jobFrequencyDetail = 'Move In or Move Out Clean';
            }
            else{
                this.bookingPayload.jobFrequencyDetail = 'Not a Move In or Move Out Clean';
            }
        }
        sessionStorage.setItem('JobFrequency',this.bookingPayload.jobFrequency);

    }
    setHeaderFooter(resultData: any) {
        localStorage.setItem("franchiseWebLocationId", resultData.franchiseWebLocationId);
        sessionStorage.setItem('franchiseId', resultData.franchiseId);
        localStorage.setItem('doingBusinessAs', resultData.doingBusinessAs);
        localStorage.setItem('weblocationId', resultData.franchiseWebLocationId);
    }
    openPropertyValidationModal(addressParam: string, suggestStr: any) {
        stopLoader();
        const callPopupModal = document.getElementById('address-modal')
        callPopupModal?.click()
        const enteredAddr = document.querySelector('label[for="entered-address-radio"]');
        const suggestAddr = document.querySelector('label[for="suggested-address-radio"]');
        const suggestJSON = JSON.parse(suggestStr);
        if (enteredAddr && suggestAddr) {
            enteredAddr.textContent = addressParam;
            suggestAddr.textContent = suggestJSON.address.label;
        }
        document.querySelector('.cta-wrapper .primary-btn')?.addEventListener("click", () => {
            let output: any;
            const suggestAddrInp = document.getElementById('suggested-address-radio') as HTMLInputElement;
            if (suggestAddrInp?.checked) {
                output = suggestAddr?.textContent;
                this.address.value = output;
                sessionStorage.setItem("suggestion", "true");
                const suggestedAddress = getAddressDetails(suggestJSON.address);
                sessionStorage.setItem('suggestedAddress',JSON.stringify(suggestedAddress));
            }
            else {
                output = enteredAddr?.textContent;
            }
            sessionStorage.setItem('sAddressParam', output);
            this.handleSubmit();
        });

    }


    openNearByLocationsModal(resultData: any) {
        stopLoader();
        const modal = document.getElementById("user-zip-code");
        if (modal) {
            if (!(document.getElementsByClassName('modalgrey').length)) {
                let bodycomponent = document.querySelector('body') as HTMLElement;
                bodycomponent.insertAdjacentHTML('beforeend', `<div modal-backdrop="" class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 modalgrey"></div>`)
            }
            const totalResults = resultData?.length;
            const zipCode = this.zipCode?.value;
            const resultsTextElement = document.querySelector('.results-text');
            if (resultsTextElement) {
                resultsTextElement.textContent = ` ${totalResults} Results for ${zipCode}`;
            }
            const ul = document.querySelector('.user-zip-result-list') as HTMLElement;
            ul.innerHTML = '';
            const sortedResultData = resultData?.slice().sort((a: any, b: any) => a.distance - b.distance);
            sortedResultData?.forEach((item: any) => {
                const liElement = document.createElement('li');
                liElement.className = 'user-zip-result-item';
                const distanceRounded = Math.round(item.distance) + ' mi.';
                const pNum = item.websiteTrackingPhone ? item.websiteTrackingPhone[0] : item.phoneNumber;
                liElement.innerHTML = `
                <div class="locaion-info">
                <div class="locaion-time">
                  <svg class="icon">
                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary"></use>
                  </svg>
                  <span class="location-text">${distanceRounded}</span>
                </div>
                <div class="locaion-name">
                  <p class="location-main">${item.doingBusinessAs}</p>
                  <a class="location-call" href="tel:${pNum}">(${pNum.slice(0, 3)}) ${pNum.slice(3, 6)}-${pNum.slice(6)}</a>
                </div>
              </div>
              <div class="locaion-btn">
                <a href="#" class="btn primary-btn">
                  REQUEST ESTIMATE
                  <svg><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>
                </a>
              </div>
                `;
                ul?.appendChild(liElement);

            });
            modal.style.display = 'flex'
            modal.classList.remove('hidden');
            const closeLocalModal = document.querySelector('.user-zip-close-modal');
            closeLocalModal?.addEventListener('click', function handleClick(event) {
                modal?.classList.add("hidden");
                modal.style.display = 'none';
                (document.querySelector(".modalgrey") as HTMLElement)?.remove();
            })
            const primaryButton = document.querySelectorAll<HTMLAnchorElement>('.locaion-btn .btn.primary-btn');
            let self = this;
            primaryButton.forEach(button => {
                const bookingData = self.bookingPayload;
                let matchingData :any;
                button.addEventListener('click', function (event: MouseEvent) {
                    event.preventDefault();
                    const anchor = this;
                    const listItem = anchor.closest('li.user-zip-result-item');
                    // Check if listItem exists and is valid
                    if (listItem) {
                        const locationMain = listItem.querySelector<HTMLParagraphElement>('.location-main')?.textContent;
                        matchingData = resultData.find((data: any) => data.doingBusinessAs === locationMain);
                        const phoneNum = (listItem?.querySelector('.location-call') as HTMLAnchorElement)?.href.replace(/tel:/,'');
                        localStorage.setItem('localPhoneNumber',phoneNum);
                        localStorage.setItem('doingBusinessAs', matchingData?.doingBusinessAs);
                        localStorage.setItem('weblocationId', matchingData?.franchiseWebLocationId);
                        bookingData.city = matchingData?.city;
                        bookingData.state = matchingData?.state;
                        bookingData.webLocationId = Number(matchingData?.franchiseWebLocationId);
                        self.formSubmissionLeadFlow(bookingData);
                    }
                });

            });

        }
    }
    async handleSubmit() {
        const form = document.querySelector('#form-section .mly-lead-started-form .contact-us-section');
        form?.addEventListener('change', (event) => {
            sessionStorage.setItem("bookingLeadSent","false");
        });
        if (validateForm(form)) {
            startLoader();
            this.readForm();
            const addrFlag = sessionStorage.getItem("suggestion");
            if((addrFlag === "false") || !this.bookingPayload.city || !this.bookingPayload.state){
                // call discovery API to get city and state info
                discoveryAPI(this.zipCode.value)
                .then((result: any) => {
                    if (result) {
                        const outputData = result.items[0];
                        this.bookingPayload.city = outputData.address.city;
                        this.bookingPayload.state = outputData.address.stateCode;
                        this.callLookupAPI();
                    }
                    else {
                        throw Error('Heremap API does not return');
                    }
                })
                .catch((err: any) => {
                    throw err;
                });
            }
            else{
                // call lookup API
                this.callLookupAPI();
            }
            
        }
    }
    callLookupAPI(){
        let addressParam: string;
        this.counter++;
        if (this.counter > 1) {
            addressParam = this.address.value.concat(" ",this.address2.value, " ", this.bookingPayload.city , " ", this.bookingPayload.state, " ", this.bookingPayload.postalCode);
        }
        else {
            addressParam = this.bookingPayload.postalCode;
        }
        const addrFlag = sessionStorage.getItem("suggestion");
        getFranchiseDetails(addressParam).then((result) => {
            if (result?.length > 0) {
                let self = this;
                localizationHelper(result, self);
                // Location found - > Check whether address entered manual or suggested
                // If manual, open modal and wait for response
                payloadHelper(this.bookingPayload);
                if(addrFlag === "false"){
                    // If manual address
                    if(this.counter<=1){
                        // First instance
                        const enteredAddr = this.address.value.concat(" ",this.address2.value, " ",this.bookingPayload.postalCode);
                        const suggestAddr = this.address?.getAttribute("data-suggestion") as string;
                        this.openPropertyValidationModal(enteredAddr,suggestAddr);
                    }
                    else{
                        this.decideFlow();
                    }
                    
                }
                else{
                    // if suggested address
                    this.decideFlow();
                }
                
            }
            else {
                this.handleNearbyLocationsFlow();
            }
        })
        .catch(async (err: any) => {
                if (err instanceof Error && err.message.includes('Full address is needed to narrow down results')) {
                    stopLoader();
                    if (this.counter > 1) {
                        this.handleNearbyLocationsFlow();
                    }
                    else {
                        let enteredAddr;
                        if(addrFlag === "false"){
                            enteredAddr = this.address.value.concat(" ",this.address2.value, " ",this.bookingPayload.postalCode);
                        }
                        else{
                            enteredAddr = this.address?.getAttribute("data-suggestion") as string
                        }
                        enteredAddr = JSON.parse(enteredAddr)?.address.label;
                        const suggestAddr = this.address?.getAttribute("data-suggestion") as string;
                        this.openPropertyValidationModal(enteredAddr, suggestAddr);
                    }

                }
                else{
                    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
                    getBrandDetailsFromBrandJson(conceptCode)
                    .then((result)=>{
                        if(result){
                            this.handleErrorScenario(result.lead_contactapi);
                        }
                        else{
                            this.handleErrorScenario(false);
                        }
                    })
                    .catch((err)=>{
                        this.handleErrorScenario(false);
                    });
                    
                }
                
        });
    }
    decideFlow(){
        const cleanType = this.cleaningType.getCleaningType().toLowerCase();
        const cleanFreq = this.cleaningType.getCleaningFreq().toLowerCase();
        this.counter = 0;
        if (cleanType.includes('commercial')) {
            // Commercial is always Lead flow, regardless of OTC or recurring
            this.readCommercialForm();
            callBookingApi(this.bookingPayload);
        }
        else if (cleanType.includes('residential')) {
            this.readResidentialForm();
            determineFlowType(this.bookingPayload.webLocationId)
                .then(async (resp: any) => {
                    sessionStorage.removeItem("mollyEstimate");
                    sessionStorage.removeItem("noCalendarPricing");
                    if (cleanFreq.includes('recurring')) {
                        this.bookingPayload.isLeadOnly = !resp?.options?.optInForPOSScheduling;
                        this.bookingPayload.isEstimate = resp?.options?.optInForPOSScheduling;
                    }
                    else{
                        this.bookingPayload.isLeadOnly = !resp?.options?.optInForPOSScheduling2;
                        this.bookingPayload.isEstimate = false;
                        await calcEstimate(this.bookingPayload);
                    }
                    sessionStorage.setItem('IsLeadOnly',this.bookingPayload.isLeadOnly.toString());
                    if (this.bookingPayload.isLeadOnly) {
                        callBookingApi(this.bookingPayload);
                    } else {
                        calendarFlowMLY(this.bookingPayload);
                    }
                })
                .catch((error: any) => {
                    throwBookingError();
                });

        }
    }
    handleNearbyLocationsFlow() {
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        let nearbyLocations;
        getBrandDetailsFromBrandJson(conceptCode)
            .then((brandData) => {
                if (brandData?.enable_50mile_area_OSflow) {
                    nearbyLocations = new NearbyLocations();
                    this.searchLocationsFlow(nearbyLocations);
                }
                else {
                    this.handleErrorScenario(brandData.lead_contactapi);
                }
            })
            .catch((err) => {
                this.handleErrorScenario(false);
            });
    }

    searchLocationsFlow(nearbyLocations: any, scenarioFlag = false) {
        stopLoader();
        nearbyLocations.searchNearbyLocations(this.zipCode.value)
            .then((data: any) => {
                // Handle the resolved data here
                const callPopupModal = document.getElementById("user-zip-code");
                if (callPopupModal && data?.length > 1) {
                    this.openNearByLocationsModal(data);
                }
                else if (data?.length == 1) {
                    this.setHeaderFooter(data[0]);
                    this.bookingPayload.city = data[0]?.city;
                    this.bookingPayload.state = data[0]?.state;
                    this.bookingPayload.webLocationId = Number(data[0]?.franchiseWebLocationId);
                    this.formSubmissionLeadFlow(this.bookingPayload);
                }
                else if (data == null || data.length == 0) {
                    this.handleErrorScenario(scenarioFlag);
                }
            }).catch((error: any) => {
                // Handle any errors here
                console.error('Error searching nearby locations:', error);
                this.handleErrorScenario(scenarioFlag);
            });
    }

    handleErrorScenario(scenarioFlag:boolean){
        if(scenarioFlag){
            try{
                if(this.cleaningType.getCleaningFreq().toLowerCase().includes('one-time')){
                    sessionStorage.setItem('JobFrequency','One-Time');
                }
                else{
                    sessionStorage.setItem('JobFrequency','Recurring');
                }
                contactUsEndpoint(this.bookingPayload);
            }
            catch(err){
                this.throwErrorModal();
            }
            
        }
        else{
            this.throwErrorModal();
        }
    }

    throwErrorModal(){
        stopLoader();
        const errorMessage = `We're sorry. We don't currently provide service to ${this.zipCode.value}.`;
        document.getElementById('error-modal-id')?.click();
        document.getElementById('error-modal')?.classList.remove('hidden');
        const errorElement = document.getElementById('modal-zipcode-error') as HTMLInputElement | null;
        if (errorElement && errorElement.textContent==='') {
            errorElement.textContent = errorMessage;
        }
        document.getElementById('modal-zipcode-error')?.classList.remove('hidden');
    }
    updateInputValue(inputId: string, value: string | number | null | undefined) {
        try {
            const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;

            if (hiddenInput && value !== null && value !== undefined) {
                hiddenInput.value = value.toString();
            }
        } catch (error) {
            console.error(`Error in updateInputValue function: ${error}`);
        }
    }

    async formSubmissionLeadFlow(bookingData:any) : Promise<any>{
        const cleanType = this.cleaningType.getCleaningType().toLowerCase();
        if (cleanType.includes('commercial')) {
            this.readCommercialForm();
        }
        else{
            this.readResidentialForm();
        }
        bookingData.isLeadOnly = true;
        sessionStorage.setItem('IsLeadOnly',bookingData.isLeadOnly);
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
        sessionStorage.setItem("noZipNote",brandData?.os_flow_50Mile_note);
        callBookingApi(bookingData);
    }

}

// Initialize the form handler when the DOM is ready for MLY
document.addEventListener("DOMContentLoaded", () => {
    const conceptCode: any = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const divTag: any = document.getElementsByClassName('mly-lead-started-form');
    if (conceptCode === "MLY" && divTag?.length > 0) {
        const flow = new MollyFlow();
        stopLoader();
        togglePreferredContactInConfirmationPage();
        let progressBar = document.querySelector("#progress") as HTMLElement;
        progressBar.style.width = "0%";
        let bars = document.querySelectorAll('.progress-step');
        if (bars[1].className.includes("prograssbar-length progress-step-active")) {
            bars[1].classList.remove("prograssbar-length");
            bars[1].classList.remove("progress-step-active");
        }
        flow.cleaningType.toggleCleaningDiv();
        flow.cleaningType.cleaningType.forEach((element) => {
            element?.addEventListener("click", () => { flow.cleaningType.toggleCleaningDiv(); });
        });
        flow.cleaningType.cleaningFreq.forEach((element) => {
            element?.addEventListener("click", () => { flow.cleaningType.toggleCleaningDiv(); });
        });
        const resOTelems = document.querySelectorAll('input[name*="one-time-radio"]');
        const commOTelems = document.querySelectorAll('input[name*="move-in-out-radio"]');
        resOTelems.forEach((element) => {
            element?.addEventListener("click", () => { flow.cleaningType.toggleCleaningDiv(); });
        });
        commOTelems.forEach((element) => {
            element?.addEventListener("click", () => { flow.cleaningType.toggleCleaningDiv(); });
        });
        const nextBtns = document.querySelectorAll('#form-section .mly-lead-started-form .btn-next');
        nextBtns.forEach((btn) => {
            btn?.addEventListener("click", (e: any) => {
                flow.handleSubmit();
            });
        });
    }
});

function localizationHelper(result:any, self:MollyFlow){
    const { franchiseWebLocationId, doingBusinessAs, franchiseId, phoneNumber,websiteTracking, licenseNumber } = result[0];
            
    const pNum = websiteTracking ? websiteTracking : phoneNumber;
    // Localization related code
    localStorage.setItem("franchiseWebLocationId", franchiseWebLocationId);
    sessionStorage.setItem('franchiseId', franchiseId);
    localStorage.setItem('doingBusinessAs', doingBusinessAs);
    localStorage.setItem('weblocationId', franchiseWebLocationId);
    localStorage.setItem('localPhoneNumber',pNum);

    const locationUrl = result[0].locationWebsiteUrl?.replace(/\/$/, '') ?? '';
    const splitUrlArr = locationUrl.toLowerCase().split("/");
    const pathname = splitUrlArr[splitUrlArr.length - 1];
    if (pathname) {
        localStorage.setItem('dbaName', pathname);
    }

    self.updateInputValue("local_weblocationId", franchiseWebLocationId);
    self.updateInputValue("weblocationId", franchiseWebLocationId);
    self.updateInputValue("full_dbaName", pathname);
    self.updateInputValue("dbaName", pathname);

    const address = document.querySelector('.header-wrapper .address')
    const desc = document.querySelector('.header-wrapper .description')
    const phone = document.querySelector('.header-wrapper .form-call-cta') as HTMLAnchorElement;
    const mobile: any = document.querySelector('.header-cta-mobile .mob-call-cta') as HTMLAnchorElement;

    if (pathname && doingBusinessAs) {
        updateHeaderContent(doingBusinessAs);
    }

    if (address && desc && phone) {
        address.innerHTML = doingBusinessAs
        desc.innerHTML = 'Locally Owned and Operated'
        phone.href = `tel:${pNum}`;
        phone.innerText = `(${pNum.slice(0, 3)}) ${pNum.slice(3, 6)}-${pNum.slice(6)}`;
    }

    if (mobile) {
        mobile.href = `tel:${pNum}`;
    }

    sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
    sessionStorage.setItem("RL_DBAName", doingBusinessAs);
    self.bookingPayload.webLocationId = Number(franchiseWebLocationId);
    self.bookingPayload.franchiseId = franchiseId;
    self.bookingPayload.isLocalized = self.bookingPayload.webLocationId ? true : false;
    self.bookingPayload.licenseNumber = licenseNumber;
}

function togglePreferredContactInConfirmationPage() {
    const tag = document.getElementById("mly_hide_preferred_contact");
    if (tag) {
        sessionStorage.setItem("hidePreferMethod", "true");
    } else {
        sessionStorage.setItem("hidePreferMethod", "false");
    }
}

export function throwBookingError(){
    stopLoader();
    const closeLocalModal = document.querySelector('.user-zip-close-modal') as HTMLButtonElement;
    closeLocalModal?.click();
    document.getElementById('error-modal-id')?.click();
    const errorModal = document.getElementById('error-modal') as HTMLElement;
    errorModal?.classList.remove('hidden');
    document.getElementById('modal-system-error')?.classList.remove('hidden');
    const closeErrorModal = document.querySelector(".error-text-wrapper .error-btn-close") as HTMLButtonElement;
    closeErrorModal.addEventListener("click", function handleClick(event) {
        errorModal.classList.add("hidden");
    })
}





