import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";

interface CacheSettings {
    timestamp: string;
    enabled: boolean;
    preCache: string[];
}

const removeLocalAndSession:boolean = false;

export function clearCacheIfOnline(){
    const displayCacheLog = process.env.SHOW_CACHE_LOG
        ? JSON.parse(process.env.SHOW_CACHE_LOG.toLowerCase())
        : false;
    if (!navigator.onLine) {
        console.log('%c[SW]%c User is offline','background:red; color:#f3f3f3; font-weight: bold; padding: 0 8px', '');
        navigator.serviceWorker?.controller?.postMessage({ origin: location.origin, action: 'offline' });
    } else {
        navigator.serviceWorker?.controller?.postMessage({ origin: location.origin, action: 'online' });
        if (displayCacheLog) console.error("%c[SW]%c Error in fetching service worker config",'background:red; color:#f3f3f3; font-weight: bold; padding: 0 8px', '');
        purge();;
    }       
}

export async function getCacheSettings() {
    const displayCacheLog = process.env.SHOW_CACHE_LOG
        ? JSON.parse(process.env.SHOW_CACHE_LOG.toLowerCase())
        : false;
    const URL = `${apiConfig.CROWNPEAK_CACHING_SETTINGS}?${Date.now()}`;

    try {
        const cachedResponse = await getCachedAPIResponse(URL, 10, false, false);
        if (cachedResponse) {
            if (displayCacheLog) console.log('%c[SW]%c Cache settings fetched successfully','background:green; color:#f3f3f3; font-weight: bold; padding: 0 8px', '', cachedResponse);
            if (Object.keys(cachedResponse).length === 0) {
                if (displayCacheLog) console.log('%c[SW]%c CP has not set up the Config setting for Caching','background:red; color:#f3f3f3; font-weight: bold; padding: 0 8px', '');
                clearCacheIfOnline();
                return;
            }
            checkCacheSettings(cachedResponse);
        }
        else{
            clearCacheIfOnline();
        }
    } catch {
        
        clearCacheIfOnline();
    }
}

function checkCacheSettings(response: CacheSettings) {
    const displayCacheLog = process.env.SHOW_CACHE_LOG
        ? JSON.parse(process.env.SHOW_CACHE_LOG.toLowerCase())
        : false;
    const { timestamp, enabled, preCache } = response;

    if (!timestamp || !preCache) {
        if (displayCacheLog) console.error("Invalid cache settings response:", response);
        clearCacheIfOnline();
        return;
    }

    const clearCacheTime = new Date(timestamp).getTime();
    const lastCacheClear = localStorage.getItem("lastCacheClear");

    if (lastCacheClear) {
        try {
            if (isNaN(clearCacheTime) || parseInt(lastCacheClear, 10) < clearCacheTime) {
                purge();
            }
        } catch {
            if (displayCacheLog) console.log("Invalid timestamp in local storage", lastCacheClear);
        }
    } else {
        localStorage.setItem("lastCacheClear", Date.now().toString());
        purge();
    }

    if (enabled) {
        localStorage.setItem("cachingL2", "true");
    } else {
        //derigister service worker
        navigator.serviceWorker.getRegistration('/').then(registration => {
            if (registration) {
              registration.unregister().then(() => {
                console.log('%c[SW]%c deregistered','background:green; color:#f3f3f3; font-weight: bold; padding: 0 8px', '');
              });
            }
        });
        localStorage.removeItem("cachingL2");
        purge();
        return;
    }

    updateCache( preCache);
}

async function updateCache(preCache: string[]) {
    const CACHE_NAME = "NBLY-nahsor";
    const cache = await caches.open(CACHE_NAME);
    await addCacheEntry(cache, "offlineCaching", "true");
    if (preCache.length > 0) {
        await addCacheEntry(cache, "preCache", JSON.stringify(preCache));
    } else {
        await removeCacheEntries(cache, "preCache");
    }
}

async function removeCacheEntries(cache: Cache, suffix: string) {
    const normalizedSuffix = `/${suffix}`;  // Ensure normalized key for deletion
    const requests = await cache.keys();
    for (const request of requests) {
        if (new URL(request.url).pathname.endsWith(normalizedSuffix)) {
            await cache.delete(request);
        }
    }
}


async function addCacheEntry(cache: Cache, key: string, value: string) {
    const normalizedKey = `/${key}`;  // Ensure consistent root-based path
    const request = new Request(normalizedKey, { method: 'GET' });  // Always a GET method for consistency
    const response = new Response(value, { headers: { "Content-Type": "text/plain" } });
    await cache.put(request, response);
}


function purge() {
    const requiredItems = extractRequiredItems();

    if(removeLocalAndSession){
        // Clear localStorage
        localStorage.clear();

        // Clear sessionStorage
        sessionStorage.clear();
    }
    

    // Clear IndexedDB
    const dbRequest = window.indexedDB.deleteDatabase("resource-store");
    dbRequest.onerror = () => console.error("Error deleting IndexedDB database");
    dbRequest.onsuccess = () => console.log("IndexedDB database deleted successfully");

    // Restore required items
    writeToStorage(requiredItems);

    // Notify Service Worker to clear caches (if applicable)
    navigator.serviceWorker?.controller?.postMessage({ origin: location.origin,action: 'clearCache' });

    // Set a current timestamp to clear the cache
    localStorage.setItem("lastCacheClear", Date.now().toString());
}

function extractRequiredItems() {
    const requiredLocalItems = [
        "dbaName", "weblocationId", "doingBusinessAs", "franchiseWebLocationId",
        "localPhoneNumber", "sessionIDFr", "cachingL2", "lastCacheClear", "localHeaderUrl",
        "CA", "US", "brandDetails", "dynamicMenuURLResponse", "siteConfigDisplayClickToCallCta"
    ];
    const requiredSessionItems = [
        "doingBusinessAs", "franchiseWebLocationId", "localPhoneNumber",
        "rl-post-data", "NEI-API data", "RL_DBAName", "RL_Weblocation_ID",
        "fR", "sAddressParam", "franchiseId", "fakeSubmitData", "correlationIDFr"
    ];

    const localStorageItems = requiredLocalItems.reduce((acc, key) => {
        if (localStorage.getItem(key)) acc[key] = localStorage.getItem(key);
        return acc;
    }, {} as Record<string, string | null>);

    const sessionStorageItems = requiredSessionItems.reduce((acc, key) => {
        if (sessionStorage.getItem(key)) acc[key] = sessionStorage.getItem(key);
        return acc;
    }, {} as Record<string, string | null>);

    return { local: localStorageItems, session: sessionStorageItems };
}

function writeToStorage(requiredItems: { local: Record<string, string | null>; session: Record<string, string | null> }) {
    const writeStorage = (storage: Storage, items: Record<string, string | null>) => {
        Object.entries(items).forEach(([key, value]) => {
            if (value !== null) {
                try {
                    const currentValue = storage.getItem(key);
                    if (currentValue !== value) {
                        storage.setItem(key, value);
                    }
                } catch (error) {
                    console.error(`Failed to write key "${key}" to storage`, error);
                }
            }
        });
    };

    if(removeLocalAndSession){
        writeStorage(localStorage, requiredItems.local);
        writeStorage(sessionStorage, requiredItems.session);
    }
}

