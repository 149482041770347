export function updateHeaderLogo(headerUrl: string | null| undefined, altText?: string): void {

    //Null check on headerUrl
    if (!headerUrl) {
        console.log("Header URL not found");
        return;
    }

    //Check if the filetype is an image
    const validExtensions = new Set(['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp']);
    const fileExtension = headerUrl.split('.').pop()?.toLowerCase();
    if (!fileExtension) {
        console.log(`No file extension found in URL: ${headerUrl}`);
        return;
    }
    if (!validExtensions.has(fileExtension)) {
        console.log(`Invalid file extension for header URL: ${headerUrl}`);
        return;
    }


    //Retrieve all existing logo DOM elements
    const existingHeaderLogo = document.querySelectorAll<HTMLImageElement>('.brand-logo');
    const existingMobileHeaderLogo = document.querySelectorAll<HTMLImageElement>('.brand-logo-mobile img');
    if (existingHeaderLogo.length === 0 || existingMobileHeaderLogo.length === 0) {
        console.log("No brand logo elements found");
        return;
    }

    try {
        changeLogoPath(existingHeaderLogo, headerUrl, altText);
        changeLogoPath(existingMobileHeaderLogo, headerUrl, altText);
        localStorage.setItem('localHeaderUrl', headerUrl);
        if(altText){
            localStorage.setItem('localHeaderAlt', altText);
        }
    } catch (error) {
        console.error("Error while updating header logo or saving to localStorage", error);
    }
}

//Actual function to change the logo
function changeLogoPath(existingLogo: NodeListOf<HTMLImageElement>, headerUrl: string, altText?:string): void {
    existingLogo.forEach((logo: HTMLImageElement) => {
        try {
            logo.src = headerUrl;
            if(altText){
                logo.alt = altText;
            }
        } catch (error) {
            console.error("Error while updating logo src", error);
        }
    });
}
