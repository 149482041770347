
import { cachedAPIWrapper, getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import { FormHandlerStep2 } from "./step2"
import { AppointmentForm } from "./step3"
import { Footer } from "../../opus2/footer/footer";
import { DateSlider } from "./scheduler"
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { getBrandDetailsFromBrandJson, updateHeaderContent,fetchCrownpeakUrlDetails, debounce} from "../util/share";
import { discoverSuggestions,getAddressDetails, handleMissingData, suggestedAddress } from "../util/discovery";
import { DisclaimerServiceIdentifier, disclaimerHandleCheck } from "../util/disclaimerService";
import { DomainIdentifier } from "../util/domainService";
import { LocalizationIdentifier } from "../util/localizationService";
import { ContactUsPayloadModel } from "../apiCaching/POST-Requests/interface/contact/contactInterface";
import { contactUsPostCallWrapper } from "../apiCaching/POST-Requests/apiContactCallWrapper";
import { getSessionAndCorrelationID } from "../util/getSessionCorrelationId";
import { bookingUsPostCallWrapper } from "../apiCaching/POST-Requests/apibookingCallWrapper";
import { BookingPayloadModel } from "../apiCaching/POST-Requests/interface/booking/bookingInterface";
import { requestEstimate } from "../util/requestEstimate";

export let allAddress: any = []

export let eventListner: any = null
export let fullData: any = null
export let isLeadData: any = false
export let address1Details:any = null;
let flagShowPopup = false;
export let changeZip = false;
sessionStorage.removeItem("currentZip");

export function setchangeZip(value:any) {
    changeZip = value;
}
export function beforeUnloadHandler(e: any) {
    if(!e.target.activeElement.classList.contains("fb-tel") && !e.target.activeElement.classList.contains("form-call-cta")){
    // Define the message to display in the confirmation prompt
    var confirmationMessage = "Are you sure you want to leave this page?";

    // You can conditionally display the confirmation message based on the specific page
    // For example, let's say you only want to display it on a page with a specific URL path:
    var currentURL = window.location.pathname;
    if (currentURL === "/schedule-appointment") {
        // Display the confirmation message
        e.returnValue = confirmationMessage;
        return confirmationMessage;
    }
    }
}

declare global {
    interface Window {
        offersClosetAppointments?: boolean|null,
        calendarTypeId:any
    }
}

export class FormHandlerLead {
    private form: any;
    private nameInput: HTMLInputElement;
    private lastNameInput: HTMLInputElement;
    private phoneNumberInput: HTMLInputElement;
    private emailInput: HTMLInputElement;
    private serviceAddressInput: HTMLInputElement;
    private serviceAddress2Input: HTMLInputElement;
    private zipCodeInput: HTMLInputElement;
    private readonly leadFlowService: NodeListOf<HTMLElement>;
    private showButton: Boolean;
    private onlineScheduling: any;
    private counter:number;
    private HMSLeadFlow:boolean = false;

    constructor() {
        this.handleHMSLeadflow();
        this.onlineScheduling = false;
        this.form = document.querySelectorAll('.contact-us-section .btn-next')
        this.showButton = false
        this.nameInput = document.getElementById('user-full-name') as HTMLInputElement;
        this.phoneNumberInput = document.getElementById('user-phone-number') as HTMLInputElement;
        this.emailInput = document.getElementById('user-email') as HTMLInputElement;
        this.serviceAddressInput = document.getElementById('user-service-address') as HTMLInputElement;
        this.serviceAddress2Input = document.getElementById('user-address-2') as HTMLInputElement;
        this.zipCodeInput = document.getElementById('zip-code') as HTMLInputElement;
        this.lastNameInput = document.getElementById('user-last-name') as HTMLInputElement;
        this.leadFlowService = document.querySelectorAll('[id*="lead-flow-service-reminder"]');
        this.counter = 0;
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        if(!document.querySelector('.confirmation-section')){
            sessionStorage.removeItem("booking");
            sessionStorage.removeItem("os_note");
        }
        this.handleFocusOut = this.handleFocusOut.bind(this);
        this.nameInput?.addEventListener('input', this.errorOnName.bind(this))
        this.emailInput?.addEventListener('input', this.emailError.bind(this))
        this.serviceAddressInput?.addEventListener('input', this.addreddErrorRemove2.bind(this))
        this.zipCodeInput?.addEventListener('input', this.zipCodeError.bind(this));
        this.zipCodeInput?.addEventListener('focusout', this.handleFocusOut.bind(this));
        this.lastNameInput?.addEventListener('input', this.errorOnLastName.bind(this));
        this.serviceAddress2Input?.addEventListener('keyup', this.address2Error.bind(this));
        this.leadFlowService?.forEach((checkbox) => {
            checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
          });
        if (this.form && this.form[0]) {
            this.form[0].addEventListener('click', this.handleSubmit.bind(this))
            const cancleBtn = document.querySelectorAll('.contact-us-section .cancel-verification')
            if (cancleBtn) {
                cancleBtn[0].addEventListener('click', () => {
                    sessionStorage.setItem("suggestion","false");
                    this.handleCancelBtnClick();
                })
            }
        }

        if (this.phoneNumberInput) {
            this.phoneNumberInput.addEventListener('input', this.handleMobileInput.bind(this));
        }

        if (this.serviceAddressInput){
            this.serviceAddressInput.addEventListener('input', this.setAutoSuggested.bind(this));
        } 

        if (localStorage.getItem('doingBusinessAs')) {
            this.setHeaderCheck()
        }

        const zzip = sessionStorage.getItem('sAddressParam')

        if (zzip && this.zipCodeInput) {
            this.zipCodeInput!.value = zzip;
        }
    }

    handleCheckboxChange() {
        const serviceReminderDivs = document.querySelectorAll('.service-reminder-main');

    // Check if any divs with the class 'service-reminder-main' are found
    if (serviceReminderDivs.length === 0) {
        console.log('Servcie Disclaimer not authored in CP');
        return; // Exit the function early if no divs are found
    }
        let errorID = null;
        // Loop through each div
        serviceReminderDivs.forEach(div => {
            // Check if the div is visible (does not have the 'hidden' class)
            if (div && !div.classList.contains('hidden')) {
                // Find the checkbox input inside this div
                const checkbox = div.querySelector('input[type="checkbox"]');
                // Find the corresponding error message element
                const errorMsg = div.querySelector('.error-msg');

                // Check if the checkbox and errorMsg exist and if the checkbox is required
                if (checkbox && errorMsg && checkbox instanceof HTMLInputElement && checkbox.required) {
                    // Add event listener to handle checkbox state change
                    checkbox.addEventListener('change', () => {
                        // If the checkbox is checked, hide the error message
                        if (checkbox.checked) {
                            errorMsg.classList.add('hidden');
                        } else {
                            // If the checkbox is unchecked, show the error message
                            errorMsg.classList.remove('hidden');
                            errorID = errorMsg?.id;
                        }
                    });

                    // Initial check: if the checkbox is required and unchecked when the page loads
                    if (checkbox.required && !checkbox.checked) {
                        errorMsg.classList.remove('hidden');
                        errorID = errorMsg?.id;
                    }
                }
            }
        });
        return errorID;
      }
    public async handleCancelBtnClick() {
        const conceptId = (document.getElementById("conceptCode") as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptId);
        let redirectUrl = localStorage.getItem("dbaName") ? localStorage.getItem("dbaName") : '';

        let weblocationInfo = window?.crownpeakWeblocationsByIdDetail;
        if(!weblocationInfo && brandData?.include_statename){
            await fetchCrownpeakUrlDetails();
            weblocationInfo = window?.crownpeakWeblocationsByIdDetail;
        }

        if(brandData?.weblocation_path_group && weblocationInfo?.stateName){
            redirectUrl = `/${brandData.weblocation_path_group}/${weblocationInfo?.stateName}/${redirectUrl}`;
        }else if(brandData?.weblocation_path_group){
            redirectUrl = `/${brandData.weblocation_path_group}/${redirectUrl}`;
        }else {
            redirectUrl = `/${redirectUrl}`;
        }
        // To check if the referrer contains the brandData.url and redirect
        if (document.referrer && document.referrer.includes(brandData?.url)) {
            window.history.back();
        } else if (redirectUrl) {
            window.location.href = window.location.origin + redirectUrl;
            return;
        } else {
            window.location.href = window.location.origin;
        }

    }

    setHeaderCheck() {
        const address: any = document.querySelector('.header-wrapper .address')
        const desc = document.querySelector('.header-wrapper .description')

        if (address && desc) {
            address.innerHTML = localStorage.getItem('doingBusinessAs')
            desc.innerHTML = 'Locally Owned and Operated'
        }
    }

    errorOnName() {
        if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {

            const fieldError: any = document.getElementById(`user-full-name-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid name format.'
            this.showButton = false
        }
        else {
            this.showButton = true
            this.hideError('user-full-name')
        }

    }

    errorOnLastName() {
        if (this.lastNameInput?.value !== '' && !REGEX.sendName.test(this.lastNameInput?.value)) {
            const fieldError: any = document.getElementById(`user-last-name-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid last name format.'
            this.showButton = false
        }
        else {
            this.showButton = true
            this.hideError('user-last-name')
        }

    }

    emailError() {
        if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {
            this.showButton = false
        }
        else {
            this.showButton = true
            this.hideError('user-email')
        }

    }

    zipCodeError() {
        if (this.zipCodeInput.value.trim() != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {

            const fieldError: any = document.getElementById(`zip-code-error-msg`);
            fieldError.innerHTML = 'Invalid zip code format.'
            this.showButton = false

        }
        else {
            this.showButton = true
            this.hideError('zip-code');
            if(this.zipCodeInput.value.trim() != sessionStorage.getItem('currentZip')){
                changeZip = true;
            }else{
                changeZip = false;
            }
        }
    }

    handleFocusOut() {
        const zipCode = this.zipCodeInput?.value.trim();
        DisclaimerServiceIdentifier(zipCode);
    }
    

    async addreddErrorRemove2() {
        try {
            await this.debouncedSearch(this.zipCodeInput, this.serviceAddressInput);
            // Check if the serviceAddressInput value matches the suggested address
            if (this.serviceAddressInput.value === suggestedAddress?.address?.label) {
                flagShowPopup = false;
            } else {
                if (suggestedAddress?.address?.label) {
                    flagShowPopup = true;
                }
            }
            if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {

                const fieldError: any = document.getElementById(`user-service-address-error-msg`);
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = 'Invalid address format.'
                this.showButton = false
            }
            else {
                this.showButton = true
                this.hideError('user-service-address')
                this.hideError('zip-code')
            }
        }
        catch (error) {
            console.error('Error during discoverSuggestions:', error);
        };
    }


    addreddErrorRemove() {

        if (this.serviceAddressInput.value == suggestedAddress?.address?.label) {
            flagShowPopup = false
        }
       
        if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {

            const fieldError: any = document.getElementById(`user-service-address-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid address format.'
            this.showButton = false
        }
        else {
            this.showButton = true
            this.hideError('user-service-address')
            this.hideError('zip-code')
        }

    }


    hideError(id: any) {
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError.classList.add('hidden')
        field.classList.remove('invalid-field')
    }


    private handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = this.phoneNumberInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        if (this.phoneNumberInput.value != '' && this.phoneNumberInput?.value.length < 14) {
            this.showButton = false
        }
        else {
            this.showButton = true
            this.hideError.call(this, 'user-phone-number')
        }

        if (x) {
            this.phoneNumberInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }

    address2Error() {

        if (this.serviceAddress2Input?.value != '' && !REGEX.sendAddress2.test(this.serviceAddress2Input?.value)) {

            const fieldError: any = document.getElementById(`user-address-2-error-msg`);
            if(fieldError){
                fieldError.classList?.remove('hidden')
                fieldError.innerHTML = 'Invalid address2 format.'
            }
        }
        else {
            this.hideError('user-address-2')
        }
    }

    private handleSubmit(event: Event) {
        event.preventDefault();
        
        if (this.validateForm()) {
            const currentSafeZip = document.getElementById('zip-code') as HTMLInputElement
            sessionStorage.setItem('sAddressParam',currentSafeZip.value);
            sessionStorage.setItem('currentZip',currentSafeZip.value);
            this.zipCodeInput.value = currentSafeZip.value

            const addrFlag = sessionStorage.getItem("suggestion");
            if (this.serviceAddressInput.value !== suggestedAddress?.address?.label) {
                flagShowPopup = true;
            }
            if ((addrFlag === "false") && suggestedAddress?.address?.label && flagShowPopup) {
                this.openValidationModal();
    
            } else {
                startLoader()
                const zipCode: any = (document.getElementById('zip-code') as HTMLInputElement).value;
                this.franchiseLookupAddressApi(zipCode);
            }
        }

    }

    openValidationModal(){
        const callPopupModal = document.getElementById('address-modal')
        const addressData = suggestedAddress?.address?.label;
        callPopupModal?.click();
        const enteredaddres = document.querySelector('[for=entered-address-radio]') as HTMLElement;
        if(enteredaddres) 
            enteredaddres.innerHTML = this.serviceAddressInput.value;
        const suggestedaddress = document.querySelector('[for=suggested-address-radio]') as HTMLElement;
        if(suggestedaddress)
            suggestedaddress.innerHTML = addressData;
        const saveBtn: any = document.querySelector('.property-address-modal a.btn');
        const suggestAddr = this.serviceAddressInput?.getAttribute("data-suggestion") as string;
        const suggestJSON = JSON.parse(suggestAddr);
        saveBtn?.addEventListener('click', () => {
            flagShowPopup = false
            const btn: any = document.getElementById('suggested-address-radio')
            const entAddRadio:any = document.getElementById('entered-address-radio');
            if(entAddRadio?.checked) {
                sessionStorage.setItem("suggestion","false");
                sessionStorage.setItem("manualAddress","true");
            }
            if (btn?.checked && suggestJSON.address.label) {
                this.serviceAddressInput.value = suggestJSON.address.label;
                const suggestedAddress = getAddressDetails(suggestJSON.address);
                sessionStorage.setItem('suggestedAddress',JSON.stringify(suggestedAddress));
                sessionStorage.setItem("suggestion","true");
                sessionStorage.setItem("manualAddress","false");
            }
            if(this.form.length > 0) {
                this.form[0].click();
            }
        })
    }
    setGTMTags(data:any){
        try {
            const leadObj = {
                event:'custEv_LocalDataLayer',
                locationID: data.franchiseWebLocationId,
                locationDBA:data.doingBusinessAs,
                locationZipcode:data.zipCode,
                licenseNumber:data.licenseNumber
            }
            gtmCustomEventLeadFlow(leadObj);

        } catch (error) {
            console.log("An error occurred in gtmCustomEventLeadFlow: ", error);
        }
    }

    callBookingWithoutLocalization(isHMSFlow: boolean, zipCode:any){
        /********* Triggering GTM event ********/
        this.setGTMTags({
            locationID: localStorage.getItem('franchiseWebLocationId')??"",
            locationDBA: localStorage.getItem('doingBusinessAs')??"",
            locationZipcode: zipCode,
            licenseNumber: sessionStorage.getItem('franchiseWebLocationMarketingId')??""
        });
        const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
        sessionStorage.setItem("manualEnteredAddress", addressEntered+", "+zipCode);
        if(isHMSFlow)
            this.contactUsApiCall()
        else{
            try {
                new AppointmentForm(this.onlineScheduling);
                new FormHandlerStep2(this.onlineScheduling);
                new DateSlider();
            } catch (error) {
                console.log(error)
            }
            
        }
    }

    settingLocalizaionInStorage(result:any, zipCode:any){
        const { franchiseWebLocationId, doingBusinessAs, franchiseId } = result;
        localStorage.setItem("franchiseWebLocationId", franchiseWebLocationId);
        sessionStorage.setItem('franchiseId', franchiseId);
        localStorage.setItem('doingBusinessAs', doingBusinessAs);
        localStorage.setItem('weblocationId', franchiseWebLocationId);

        const locationUrl =  result.locationWebsiteUrl?.replace(/\/$/, '') ?? '';
        const splitUrlArr = locationUrl.toLowerCase().split("/");
        const pathname = splitUrlArr[splitUrlArr.length - 1];
        if(pathname){
            localStorage.setItem('dbaName', pathname);
        }
        
        this.updateInputValue("local_weblocationId", franchiseWebLocationId);
        this.updateInputValue("weblocationId", franchiseWebLocationId);
        this.updateInputValue("full_dbaName", pathname);
        this.updateInputValue("dbaName", pathname);

        const address = document.querySelector('.header-wrapper .address')
        const desc = document.querySelector('.header-wrapper .description')
        
        if(pathname && doingBusinessAs){
            updateHeaderContent(doingBusinessAs);                   
        }

        if (address && desc) {
            address.innerHTML = doingBusinessAs
            desc.innerHTML = 'Locally Owned and Operated'
        }
        const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
        sessionStorage.setItem("manualEnteredAddress", addressEntered+", "+zipCode);
        sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
        sessionStorage.setItem("RL_DBAName",doingBusinessAs);
       
    }

    callBookingAfterLocalization(result:any, zipCode:any, brandData:any){
        if (result.length > 0) {
            const { franchiseWebLocationId, doingBusinessAs, licenseNumber } = result[0];
            (async()=>{
            await this.getDetails(franchiseWebLocationId);
            
            this.settingLocalizaionInStorage(result[0], zipCode)
            /********* Triggering GTM event ********/
            this.setGTMTags({
                locationID: franchiseWebLocationId,
                locationDBA: doingBusinessAs,
                locationZipcode: zipCode,
                licenseNumber: licenseNumber
            });
            
            if(this.HMSLeadFlow){
                this.contactUsApiCall()
            }
            else{
                try {
                    
                    new AppointmentForm(this.onlineScheduling);
                    new FormHandlerStep2(this.onlineScheduling);
                    new DateSlider();
                    const ft_footer = new Footer();
                } catch (error) {
                    
                }
            }

        })();
            setTimeout(() => {
                stopLoader();
            }, 3000)
        }
        else {

            stopLoader()
            if(!brandData.lead_contactapi){
                const btnClick = document.getElementById('error-modal-id')
                btnClick?.click()
                const zipMessage = document.getElementById('modal-zipcode-error')
                if (zipMessage){
                    document.getElementById('modal-appoinment-slot')?.classList.add('hidden')
                    zipMessage.innerHTML = `We're sorry. We don't currently provide service to ${zipCode}`
                    zipMessage?.classList.remove('hidden');
                }
            }else{
                this.contactUsApiCall()
            }
        }
    }

    private async franchiseLookupAddressApi(zipCode: any) {
        const request = {
            url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode))
        };
    
        try {
            const result = await getCachedAPIResponse(request.url, apiCaching.LocateLocationApiWithRoundRobinTrue);
            if (result && result?.length) {
                await this.handleLocalization(result, zipCode);
            }
            else{
                await this.handleError({error: 'No result found'});
            }
        } catch (error: any) {
            await this.handleError(error);
        }
    }
    
    private async handleLocalization(result: any, zipCode: any) {
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
        const changeLocalization = this.getZipCodeToggeleFlag(brandData);
    
        if (!changeLocalization) {
            this.callBookingAfterLocalization(result, zipCode, brandData);
        } else {
            this.callBookingWithoutLocalization(this.HMSLeadFlow, zipCode);
        }
    }
    
    private async handleError(error: any) {
        stopLoader();
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
    
        if (error?.message) {
            let errorMessage = JSON.parse(error?.message);
            if (errorMessage.message.toLowerCase() === 'full address is needed to narrow down results') {
                const zipCode: any = (document.getElementById('zip-code') as HTMLInputElement).value;
                const address: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
                const completeAddress = `${address}, ${zipCode}`;
                this.franchiseLookupAddressApi(completeAddress);
            }
        } else {
            this.handleGenericError(brandData);
        }
    }
    
    private handleGenericError(brandData: any) {
        stopLoader();
        if (!brandData.lead_contactapi) {
            const btnClick = document.getElementById('error-modal-id');
            btnClick?.click();
            const zipMessage = document.getElementById('modal-zipcode-error');
            if (zipMessage) {
                zipMessage.innerHTML = `We're sorry. Something went wrong. Please try again later.`;
            }
        } else {
            this.contactUsApiCall();
        }
    }

    getZipCodeToggeleFlag(brandData:any):boolean{
        return brandData.send_shortform_lead_bookingapi??false;
    }

    async contactUsApiCall() {
        const step3Instance = new AppointmentForm(false);
        const formData = step3Instance.getValues();
        const city = sessionStorage.getItem('manualCity');
        const state = sessionStorage.getItem('manualState');
        const country = sessionStorage.getItem('countryName');
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const conceptId = document.getElementById('conceptId') as HTMLInputElement;
        const zipCodeText = (document.querySelector('label[for="zip-code"]') as HTMLElement)?.innerText.trim();
        const lastName = formData.lastName ?? '';
    
        let vendorId;
        let vendorName;
    
        try {
            const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
            if (brandData) {
                vendorId = brandData.vendorId;
                vendorName = brandData.vendorName;
            }
    
            let requestBody: any = this.contactCreateRequestBody(formData, city, state, country, conceptId, vendorId, vendorName, zipCodeText, lastName);           
            const modalVisible = false;
            const form = document.querySelectorAll('#form-section .contact-us-section')[0];
            const parentWithFormId = form.closest('[id*="form"]');
            const domain = DomainIdentifier();
            const localisation = LocalizationIdentifier();
            // Default value to pass if checkbox is not found
            let checkboxId = '';
            const countryZip = !REGEX.sendCaPostalCode.test(zipCodeText?.trim()) ? 'us' : 'ca';

            if (((domain === "us" && !localisation) || (domain === "us" && localisation=="us")) && countryZip === 'us') {
                checkboxId = 'lead-flow-service-reminder-us';
            } else {
                checkboxId = 'lead-flow-service-reminder-ca';
            }
            if (parentWithFormId) {
                const noteWithRequestBody: any = disclaimerHandleCheck(modalVisible, parentWithFormId as HTMLElement, requestBody, zipCodeText, checkboxId);
                if (noteWithRequestBody?.comments) {
                    requestBody.comments = noteWithRequestBody.comments;
                }
                requestBody.optIn = noteWithRequestBody.optIn;
                requestBody.emailOptOut = noteWithRequestBody.emailOptOut;
            }

            if (brandData.lead_contactapi) {
                await this.contactCallContactUsEndpoint(requestBody);
            } else if (this.HMSLeadFlow) {
                await this.HMSCallBookingEndpoint(requestBody);
            }
        } catch (error) {
            console.error(`Error in Step1.ts file in the contactUsApiCall function while fetching the Brand JSON. Error message: ${error}`);
        }
    }
    
    private contactCreateRequestBody(formData: any, city: any, state: any, country: any, conceptId: any, vendorId: any, vendorName: any, zipCodeText: any, lastName: any) {
        const requestBody: ContactUsPayloadModel = {
            "firstName": formData.name.split(' ')[0] ? formData.name.split(' ')[0] : '',
            "lastName": formData.name?.split(' ')[1] ? formData.name?.split(' ')[1] : ' ',
            "zipCode": formData.zip.trim(),
            "phone": formData.phone,
            "email": formData.email,
            "city": city,
            "state": state,
            "country": country ?? '',
            "address": formData.address || '',
            "address2": formData.address2 || '',
            "comments": zipCodeText,
            "signUpForUpdates": false,
            "isLocalized": conceptId?.value ==="25" ? true : false,
            "isTest": this.contactDetermineIsTest(),
            "conceptId": Number(conceptId?.value),
            "vendorId": Number(vendorId)
        };
    
        if (lastName) {
            requestBody.lastName = lastName;
            requestBody.firstName = formData.name ?? '';
        }
    
        return requestBody;
    }
    
    private contactDetermineIsTest() {
        const host = window.location.hostname.toLowerCase();
        return host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost');
    }
    
    private async contactCallContactUsEndpoint(requestBody: any) {
        try {
            await contactUsPostCallWrapper(apiConfig.CONTACT_US_SEND, requestBody);
            this.setLocalStorageAndRedirectToConfirmation(requestBody, true);
        } catch (error) {
            console.error(`Error in Step1.ts file in the contactCallContactUsEndpoint function while calling the contactus endpoint: ${error}`);
        }
    }
    
    private async HMSCallBookingEndpoint(requestBody: BookingPayloadModel) {
        let brandData: any = localStorage.getItem("brandDetails");
        if (brandData) brandData = JSON.parse(brandData);
    
        requestBody.leadSource = "Web";
        requestBody.leadOrigin = "Web";
        requestBody.emailOptOut = true;
        requestBody.isLeadOnly = true;
        requestBody.leadSourceId = 0;
        requestBody.franchiseId = Number(sessionStorage.getItem("franchiseId")) || 0;
        requestBody.webLocationId =  Number(localStorage.getItem("franchiseWebLocationId")) || 0;
        requestBody.state = sessionStorage.getItem("stateCode") ?? sessionStorage.getItem("manualState") ?? "";
        requestBody.country = sessionStorage.getItem("manualCountryCode") ?? sessionStorage.getItem("countryName") ?? "";
        requestBody.city = sessionStorage.getItem("manualCity") ?? "";
        requestBody.campaignName = "N/A";
        requestBody.callType = "Inbound";
        requestBody.conceptCalledId = Number(requestBody.conceptId);
        requestBody.postalCode = requestBody.zipCode as string;
     
        let sessionID: string | undefined;
        let correlationID: string | undefined;
        const sessionAndCorelation = getSessionAndCorrelationID();
        sessionID = sessionAndCorelation.sessionID;
        correlationID = sessionAndCorelation.correlationID;
        if (correlationID) {
            requestBody.correlationId = correlationID;
        }

        const submitType = requestBody.isLeadOnly ? 'submit_and_continue' : 'full_form_submit';
        let formDesc: any = '';

        const referralMethod = document.querySelector('#how-about-us') as HTMLInputElement | null;
        formDesc = referralMethod ? referralMethod.value.replace(/ /g, "_") : '';

        const serviceType = document.querySelector('input[name="service-type"]:checked');
        if (serviceType) {
            formDesc = serviceType.nextElementSibling?.textContent;
        }

        try {
            const out = await handleMissingData(requestBody.city, requestBody.state, requestBody.zipCode);
            requestBody.city = out?.city || requestBody.city;
            requestBody.state = out?.state || requestBody.state;
            let url = '';
            if(correlationID && sessionID){
                url = `${apiConfig.BOOKING_API_URL}&correlationId=${correlationID}&sessionId=${sessionID}`
            }
            else{
                url = apiConfig.BOOKING_API_URL;
            }
           
           
            //Code to handle Fake Submit for HMS
            // if (request?.data?.firstName?.toLowerCase() === "isfakesubmit") {
            //     handleFakeSubmit(request,true);
            //     this.setLocalStorageAndRedirectToConfirmation(requestBody, false)
            //     return;
            // }
            startLoader();
            await bookingUsPostCallWrapper(url, requestBody);
            this.setLocalStorageAndRedirectToConfirmation(requestBody, false);
            requestEstimate(submitType, 'long-form', 'success', formDesc);
        } catch (error) {
            requestEstimate(submitType, 'long-form', 'fail', formDesc);
            console.error(`Error while calling the booking endpoint in HMSCallBookingEndpoint: ${error}`);
        } finally {
            stopLoader();  
        }
    }
    

    setLocalStorageAndRedirectToConfirmation(requestBody:any, isContact:boolean){
        const conceptIdElement = document.getElementById('conceptId') as HTMLInputElement;
        const conceptId: string | undefined = conceptIdElement?.value;
    
        if (conceptId === "25") {
            requestBody.isContact = isContact;
            requestBody.postalCode = requestBody.zipCode
            sessionStorage.setItem("suggestion","false");
            sessionStorage.setItem('booking', JSON.stringify(requestBody));
        }
        else{
            const contactUsObj = {       
                "FirstName": requestBody.firstName,
                "LastName": requestBody.lastName,
                "ZipCode": requestBody.zipCode,
                "Phone":requestBody.phone,
                "Email": requestBody.email,
                "isContact":isContact
            };
        
            sessionStorage.setItem('booking', JSON.stringify(contactUsObj));
        }
        window.history.replaceState({}, '', '/confirmation');
        window.location.href = '/confirmation'
        setTimeout(() =>{
            stopLoader();
        },1000);
    }

    private async getDetails(franchiseWeblocationId: any) {
        const url = `${apiConfig.GET_ATTRIBUTE_DATA}/${franchiseWeblocationId}`;
        try{
            const resp: any = await cachedAPIWrapper(url, apiCaching.FullAttributeResponse);

            let callTrackNumber: any = resp?.mainPhoneNumber;
            this.onlineScheduling = resp?.onlineScheduling;
            const offersClosetAppointments = resp?.options?.offersClosetAppointments;
            window.offersClosetAppointments = offersClosetAppointments;
            window.calendarTypeId = resp?.calendarTypeId;
            const  showFeeFlag = resp?.options?.showFees;
            sessionStorage.setItem("hideInspectionFee",showFeeFlag);
            resp?.callTrackings.forEach((type: any) => {
                if (type.callTrackingTypeDescription == "WebsiteTracking") {
                    callTrackNumber = type.callTrackingNumber;
                    localStorage.setItem('localPhoneNumber', callTrackNumber);
                }
                const phoneNO: any = document.querySelector('.header-wrapper .form-call-cta')

                if (phoneNO) {
                    phoneNO.href = `tel:${callTrackNumber}`
                    const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
                    const phoneFormat: any = String(callTrackNumber)?.replace(/\D/g, '').match(reg);
                    phoneNO.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
                }

                const mobile: any = document.querySelector('.header-cta-mobile .primary-btn')

                if (mobile) {
                    mobile.href = `tel:${callTrackNumber}`

                }
                if(this.HMSLeadFlow){
                    const progressBar: any = document.getElementById('progress')
                    progressBar.style.width = "100%"
                }
                else
                if (!resp?.onlineScheduling) {
                    const progressContainer = document.querySelector('.progressbar')
                    const progress = document.querySelectorAll('.progressbar .progress-step')
                    const lastChild: any = progress[2]
                    if (lastChild)
                        progressContainer?.removeChild(lastChild)
                    const progressBar: any = document.getElementById('progress')
                    progressBar.style.width = "100%"
                    const formNext = document.querySelectorAll('#form-section .contact-us-section')
                    const btnNext = document.querySelectorAll('.contact-us-section .btn-next')[1]
                    const form1 = formNext[0]
                    const form2 = formNext[1]
                    const progress1 = progress[0]
                    const progress2 = progress[1]
                    form1.classList.remove('form-step-active')
                    form2.classList.add('form-step-active')
                    if (btnNext)
                        btnNext.innerHTML = 'Submit'
                    progress1.classList.remove('progress-step-active')
                    progress1.classList.add('progress-step-confirmed')
                    progress2.classList.add('progress-step-active')
                    isLeadData = true
                }
                else {
                    const formNext = document.querySelectorAll('#form-section .contact-us-section')
                    const progress = document.querySelectorAll('.progressbar .progress-step')
                    const progressBar: any = document.getElementById('progress')
                    const form1 = formNext[0]
                    const form2 = formNext[1]
                    const progress1 = progress[0]
                    const progress2 = progress[1]
                    progressBar.style.width = "50%"
                    form1.classList.remove('form-step-active')
                    form2.classList.add('form-step-active')
                    progress1.classList.remove('progress-step-active')
                    progress1.classList.add('progress-step-confirmed')
                    progress2.classList.add('progress-step-active')
                    isLeadData = false
                }
            }); 
            return resp;
        }catch (error) {
            console.error("Error in getDetails:", error);
            throw error; // Propagate the error to the caller
        }
    }

    private validateForm(): boolean {
        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
        const errors: any = {};


        if (this.nameInput.required && this.nameInput.value === '') {
            errors['user-full-name'] = '';
        } else if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {
            errors['user-full-name'] = 'Invalid name format.';
        }

        if (this.lastNameInput?.required && this.lastNameInput?.value === '') {
            errors['user-last-name'] = '';
        } else if (this.lastNameInput?.value !== '' && !REGEX.sendName.test(this.lastNameInput?.value)) {
            errors['user-last-name'] = 'Invalid last name format.';
        }

        if (this.phoneNumberInput.required && this.phoneNumberInput.value === '') {
            errors['user-phone-number'] = '';
        }
        else if (this.phoneNumberInput.value != '' && this.phoneNumberInput?.value.length < 14) {

            const fieldError: any = document.getElementById(`user-phone-number-error-msg`);
            errors['user-phone-number'] = 'Invalid phone format.'


        }

        if (this.zipCodeInput?.required && this.zipCodeInput?.value === '') {
            errors['zip-code'] = '';
        } else if (this.zipCodeInput.value != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {
            errors['zip-code'] = 'Invalid zip code format.';
        }

        if (this.serviceAddressInput.required && this.serviceAddressInput.value === '') {
            errors['user-service-address'] = '';
        }
        else if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {
            errors['user-service-address'] = 'Invalid address format.'

        }

        if (this.serviceAddress2Input?.required && this.serviceAddress2Input?.value === '') {
            errors['user-address-2'] = '';
        }
        else if (this.serviceAddress2Input?.value != '' && !REGEX.sendAddress2?.test(this.serviceAddress2Input?.value)) {
            errors['user-address-2'] = 'Invalid address2 format.'
        }

        if (this.emailInput.required && this.emailInput.value === '') {
            errors['user-email'] = '';
        } else if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {
            errors['user-email'] = 'Invalid email address, please try again.';
        }

        const errorIdChekboxSpan = this.handleCheckboxChange();
        if(errorIdChekboxSpan){
            errors[errorIdChekboxSpan] = '';
        }

        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field) {
                if(field.parentNode){
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.textContent = errorMessage
                field.classList.add("invalid-field");
                if (fieldError && errorMessage) {
                    fieldError.innerHTML = errorMessage
                }
                if (fieldError)
                    if(fieldError.classList.contains('hidden'))
                        fieldError.classList.remove('hidden')
            }
            }
            if (index == 0 && field) {
                field.focus()
            }
        });

        return Object.keys(errors).length === 0;
    }

    private readonly debouncedSearch = debounce(async (zipCode: string, address: string) => {
        await discoverSuggestions(zipCode, address); 
    }, 500);

    private setAutoSuggested = async (e: any) => {

        e.preventDefault();
       
        sessionStorage.setItem("suggestion", "false");
        this.serviceAddressInput.removeAttribute("suggestion");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        this.debouncedSearch(this.zipCodeInput, this.serviceAddressInput);
    }

    setZipCode = () => {
        let isFlag = true
        flagShowPopup = false
        const input: any =  document.querySelectorAll('.input-label-wrap #zip-code')[0];
        if (input && input.id == 'zip-code') {
            isFlag = false
        }

        const address = this.serviceAddressInput.value
        const item = allAddress.filter((item: any) => item.title == address)[0]
        const val = String(item?.address?.postalCode).split('-')[0]
        if (val && isFlag)
            this.zipCodeInput.value = val

        setTimeout(() => {
            this.addreddErrorRemove.bind(this)
        }, 300)
    }

    updateInputValue(inputId: string, value: string | number | null | undefined) {
        try {
            const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;
    
            if (hiddenInput && value !== null && value !== undefined) {
                hiddenInput.value = value.toString();
            }
        } catch (error) {
            console.error(`Error in updateInputValue function: ${error}`);
        }
    }

    handleHMSLeadflow(): void {
        const conceptIdElement = document.getElementById('conceptId') as HTMLInputElement;
        const conceptId: string | undefined = conceptIdElement?.value;
        const sessionHMSZip = sessionStorage.getItem("hms_lead_ISN_zip");
    
        if (conceptId !== "25") {
            return;
        }
    
        const zipInputField = document.querySelector("#isn-form #zip-code") as HTMLInputElement;
        if (zipInputField && sessionHMSZip) {
            zipInputField.value = sessionHMSZip;
        }
        this.HMSLeadFlow = true;
        const btnNext = document.querySelectorAll('.contact-us-section .btn-next')[0]as HTMLButtonElement;
        if (btnNext){
            btnNext.innerHTML = 'Submit';
            btnNext.title = "Click to Submit Form";
        }
        const progressContainer = document.querySelector('.progressbar')
        const progress = document.querySelectorAll('.progressbar .progress-step')
        const lastChild: any = progress[2];
        if (lastChild)
            progressContainer?.removeChild(lastChild)
    }
    

}
// Address dropdown dismmised 
document?.body?.addEventListener('click', function(event) {
    const suggestionContainer = document.querySelector('.suggestion-container') as HTMLElement;
    if(suggestionContainer?.parentNode){
        suggestionContainer.parentNode.removeChild(suggestionContainer);
    }
})

// Initialize the form handler when the DOM is ready and for any brands other than HMS and MLY
document.addEventListener("DOMContentLoaded", () => {
    const conceptId: any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    if (conceptId !== "25" && conceptId !== "1") {
        new FormHandlerLead();
    }
});


