import { stopLoader } from "../util/loader";
import { setTimeAndDate } from "../util/setDateAndTime";
import { getAddressConfirmation } from "../util/discovery";

export function validEstimate(){
    const estimate = sessionStorage.getItem('mollyEstimate');
    let flag;
    if(estimate && estimate!== "" && estimate!== "null" && estimate!= undefined){
        flag = true;
    }
    else{
        flag = false;
    }
    return flag;
}

export class Confirmation{
    private calendarRecurHeading: HTMLElement;
    private calendarOTCHeading: HTMLElement;
    private leadRecurHeading: HTMLElement;
    private leadOTCHeading: HTMLElement;
    private calendarConf: HTMLElement;
    private leadConf: HTMLElement;
    private leadDetails: HTMLElement;
    private osDetails: HTMLElement;
    private appointmentDetails: HTMLElement;
    private inputData: any;
    private frequency: string;
    private type: string;
    private isLeadFlow:boolean;
    constructor(){
        this.calendarRecurHeading = document.getElementById('recurring_heading') as HTMLElement;
        this.calendarOTCHeading = document.getElementById('onetime_heading') as HTMLElement;
        this.leadRecurHeading = document.getElementById('recurring_lead_heading') as HTMLElement;
        this.leadOTCHeading = document.getElementById('onetime_lead_heading') as HTMLElement;
        this.osDetails = document.getElementById('appointment_title') as HTMLElement;
        this.leadDetails = document.getElementById('leadflow_title') as HTMLElement;
        this.appointmentDetails = document.querySelector('ul.appointment-list') as HTMLElement;
        if(sessionStorage.getItem('fakeSubmitData')){
            this.inputData = JSON.parse(sessionStorage.getItem('fakeSubmitData') as string);
        }
        else{
            this.inputData = JSON.parse(sessionStorage.getItem('rl-post-data') as string);
        }
        this.frequency = sessionStorage.getItem('JobFrequency') as string;
        this.type = sessionStorage.getItem('CustomerType') as string;
        this.calendarConf = document.getElementById('confirmation_calendar_subhead') as HTMLElement;
        this.leadConf = document.getElementById('confirmation_lead_subhead') as HTMLElement;
        this.isLeadFlow = this.inputData.isLeadOnly as boolean;
    }
    showAllHeadings(){
        if(this.isLeadFlow){
            this.leadConf.classList.remove('hidden');
            this.calendarConf.classList.add('hidden');
            this.calendarOTCHeading.classList.add('hidden');
            this.calendarRecurHeading.classList.add('hidden');
            this.leadDetails.classList.remove('hidden');
            this.osDetails.classList.add('hidden');
            this.toggleFrequencyHeading(this.leadRecurHeading, this.leadOTCHeading);
        }
        else{
            this.leadConf.classList.add('hidden');
            this.calendarConf.classList.remove('hidden');
            this.leadRecurHeading.classList.add('hidden');
            this.leadOTCHeading.classList.add('hidden');
            this.leadDetails.classList.add('hidden');
            this.osDetails.classList.remove('hidden');
            this.toggleFrequencyHeading(this.calendarRecurHeading, this.calendarOTCHeading);
        }
        
    }
    toggleFrequencyHeading(elem1:any, elem2:any){
        let isRecurring = this.frequency.toLowerCase().includes('recurring');
        if(isRecurring){
            elem1.classList.remove('hidden');
            elem2.classList.add('hidden');
        }
        else{
            elem1.classList.add('hidden');
            elem2.classList.remove('hidden');
        }
    }
    updateAppointmentDetails(){
        let flag = this.frequency.toLowerCase();
        const arrs:any = {
            "Date and Time": '/brand/_assets/images/brand-img/lead-flow/calendar.svg',
            "Estimate": '/brand/_assets/images/brand-img/lead-flow/tag.svg',
            "Service Address": '/brand/_assets/images/brand-img/lead-flow/location-marker.svg',
            "Contact Info": '/brand/_assets/images/brand-img/lead-flow/mail.svg',
        };
        let html='';
        Object.keys(arrs).forEach((a:string)=>{
            html = html.concat(this.generateLi(a, arrs[a] ,flag));
        });
        this.appointmentDetails.innerHTML = html;
    }
    generateLi(category:string, img:string, flag:string) : string{
        let text1, text2, span, mobile = '';
        let html = ``;
        if(category.includes('Estimate') && !flag.includes('recurring') && !this.type.toLowerCase().includes("commercial") && validEstimate()){
            const estimateData = JSON.parse(sessionStorage.getItem('mollyEstimate') as string);
            const oneTimeClean = this.inputData.jobFrequencyDetail?.toLowerCase().includes('not a move in or move out clean');
            const dateDay = sessionStorage.getItem('selectedDay');
            if(oneTimeClean){
                if(dateDay === 'Saturday'){
                    text1 = `$${Math.round(estimateData?.estimateOccasionalRangeLowSaturday)} - $${Math.round(estimateData?.estimateOccasionalRangeHighSaturday)}`;
                }
                else{
                    text1 = `$${Math.round(estimateData?.estimateOccasionalRangeLow)} - $${Math.round(estimateData?.estimateOccasionalRangeHigh)}`;
                }
            }
            else{
                if(dateDay === 'Saturday'){
                    text1 = `$${Math.round(estimateData?.estimateMoveRangeLowSaturday)} - $${Math.round(estimateData?.estimateMoveRangeHighSaturday)}`;
                }
                else{
                    text1 = `$${Math.round(estimateData?.estimateMoveRangeLow)} - $${Math.round(estimateData?.estimateMoveRangeHigh)}`;
                }
            }
            const lowValue = oneTimeClean ?
                (dateDay === 'Saturday' ? estimateData?.estimateOccasionalRangeLowSaturday : estimateData?.estimateOccasionalRangeLow) :
                (dateDay === 'Saturday' ? estimateData?.estimateMoveRangeLowSaturday : estimateData?.estimateMoveRangeLow);

            const highValue = oneTimeClean ?
                (dateDay === 'Saturday' ? estimateData?.estimateOccasionalRangeHighSaturday : estimateData?.estimateOccasionalRangeHigh) :
                (dateDay === 'Saturday' ? estimateData?.estimateMoveRangeHighSaturday : estimateData?.estimateMoveRangeHigh);
            if (text1 == null || text1 == undefined || (lowValue === 0 && highValue === 0)) {
                return '';
            }
            span = 'yes';
        }
        else if(category.includes('Date and Time') && !this.inputData.isLeadOnly){
            let obj = this.processString(sessionStorage.getItem('userSelectDatae') as any);
            text1 = obj.date;
            if(flag.includes('recurring')){
                text2 = obj.time; 
            }
            else{
                text2 = sessionStorage.getItem('timeOfDay');
            }
            
        }
        else if(category.includes('Service Address')){
            let obj = getAddressConfirmation(this.inputData); 
            if(obj.line1){
                text1 = obj.line1;
                text2 = obj.line2;
            }
            else{
                text1 = obj.line2;
            }
        }
        else if(category.includes('Contact Info')){
            text1 = this.inputData.firstName + " " + this.inputData.lastName;
            text2 = this.inputData.email;
            mobile = this.inputData.phone;
        }

        if(text1 && img){
            html = html.concat(`<li class="appointment-list-item"> <div class="icon-block">`);
            html = html.concat(`<img src="${img}" alt="calendar" width="24" height="24">`);
            html = html.concat(`<div class="appointment-detail-wrap"><div class="icon-block-wrap"><p class="icon-block-heading">${category}</p><p class="icon-block-text">${text1}</p>`);
        }
        if(text2){
            html = html.concat(`<p class="icon-block-text">${text2}</p>`);
        }
        if(span){
            html = html.concat(`<span class="icon-block-sub-text">plus any applicable sales taxes</span>`);
        }
        if(mobile){
            html = html.concat(`<p class="icon-block-text">${mobile}</p>`);
        }
        html = html.concat(`</div>`);
        const tag = sessionStorage.getItem("hidePreferMethod");
        if(category.includes('Contact Info') && tag==="false"){
            html = html.concat(`<div class="icon-block-wrap"><p class="icon-block-heading">Preferred Method of contact:</p><p class="icon-block-text">${sessionStorage.getItem('PreferredCommunicationText')}</p></div>`);
        }
        html = html.concat(`</div> </div> </li>`);
        return html;
    }
    processString(str: string): any {
        let arr = str.split(",");
        let dateStr = arr.slice(0,arr.length-1).join(",").trim();
        let timeStr = arr[arr.length-1].trim();
        let obj = {
            'date': `${setTimeAndDate(dateStr)}`, 
            'time': `${timeStr.toLowerCase()}`
        }
        return obj;
    }
    destroy() {
        sessionStorage.removeItem("callFromCalendar");
        sessionStorage.removeItem("isBookingDone");
        sessionStorage.removeItem('zipcode');
        sessionStorage.removeItem('noZipNote');
        sessionStorage.removeItem("noCalendarPricing");
        sessionStorage.removeItem('mollyEstimate');
        sessionStorage.removeItem("bookingLeadSent");
        sessionStorage.removeItem('zipcode');
        sessionStorage.removeItem('TA_IsEstimate');
        sessionStorage.removeItem('PriceNote');
        sessionStorage.removeItem('fakeSubmitData');
        sessionStorage.removeItem('correlationIDFr');
    }
   
}

document.addEventListener("DOMContentLoaded", () => {
    stopLoader();
    const conceptCode: any = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const divTag: any = document.getElementsByClassName('mly-confirmation-section');
    if (conceptCode === "MLY" && divTag?.length>0) {
        const c = new Confirmation();
        c.showAllHeadings();
        c.updateAppointmentDetails();
        c.destroy();
    }

});